<template>
<div>
 <vpsHeader/>
  <div class="container center_margin page-wrapper">
    <!-- <div class="center_margin site-logo">
      <img src="https://accessmgrqa1.verizonwireless.com/am/images/logo.png" class="logoImg" alt="verizon logo">
    </div> -->
    <div class="register">
      <div class="register-welcome-section">
        <h1 class="register-welcome-header">Welcome to Verizon.</h1>
        <p class="register-welcome-password">Create your password <br> and password</p>
        <p class="register-welcome-des">Time to be creative....</p>
      </div>
      <div class="register-form-section">
        <div class="register-form-label">
          <label>Email:</label><span class="register-form-value">{{vpsWholesaleVerizon.email}}</span>
        </div>
        <div class="register-form-group">
          <label class="register-form-group-label">User ID: </label>
          <input type="text" @input="validateUserName" maxlength="60"
          :class="[this.getVpsUserIdAlreadyExist ? 'vps-input-field-warning' : '' ]" 
          v-model="vpsWholesaleVerizon.userId" class="form-control form-input"/>
        </div>
        <div v-if="this.getVpsUserIdAlreadyExist">
          <p class="vps-input-errortext-style" v-html="$t('message.vps_reg_user_exist')">
          </p>
        </div>
        <p class="rules" v-if="isUserNameValid">Minimum 6 characters, can not be all numbers,<br>no spaces allowed</p>
        <p class="rules">
          Hint: You may enter your email address as a<br>
          User ID if it is available
        </p>
        <div class="register-form-label">
          <label>First Name:</label><span class="register-form-value"> {{ vpsWholesaleVerizon.firstName }}</span>
        </div>
        <div class="register-form-label">
          <label>Last Name:</label><span class="register-form-value"> {{ vpsWholesaleVerizon.lastName }}</span>
        </div>
        <div class="register-form-group">
          <label class="register-form-group-label">Password </label>
          <input type="password" v-model="vpsWholesaleVerizon.password " @input="validatePassword" placeholder="Enter Password" maxlength="60" class="form-control form-input" />
          <p class="">Password strength : <span class="error" 
            :class="[isPasswordValid ? 'error': '', 
            (!isPasswordValid && vpsWholesaleVerizon.password.length>8 && vpsWholesaleVerizon.password.length<11) ? 'medium' : '',
            !isPasswordValid && vpsWholesaleVerizon.password.length>11 ? 'strong' : ''
            ]" v-if="isPasswordValid || validMessage!=''|| this.getVpsRegPwdAlert">{{ validMessage}}</span></p>
        </div>
        <p class="rules">
          Minimum 8 characters, maximum 20 characters, &nbsp;at least 1
          letter and 1 number,&nbsp; and is case sensitive.<br />Cannot
          match User ID,&nbsp; cannot be an easily guessed password,
          &nbsp;and cannot have spaces.<br/>Avoid sequence like 1234, abcd , 1111, etc.
        </p>
        <div class="register-form-group">
          <label class="register-form-group-label">Confirm password </label>
          <input type="password" v-model="vpsWholesaleVerizon.confirmPassword" @input="validateConfirmPassword" placeholder="Re-Enter Password" maxlength="20" class="form-control form-input" />
        </div>
        <p v-if="isConfPwdValid">Confirm password must match with the password</p>
        <!-- <div class="register-form-group">
          <label class="register-form-group-label">Secret question</label>
          <select class="form-control form-input form-select" v-model="vpsWholesaleVerizon.vzwChallengeQuestion">
            <option value="0">Select a question</option>
            <option value="1" selected="selected">option1</option>
          </select>
        </div>

        <div class="register-form-group">
          <label class="register-form-group-label">answer</label>
          <input class="form-control form-input" type="password" v-model="vpsWholesaleVerizon.vzwChallengeAnswer" placeholder="Enter your secret answer" value="" />
          <p class="rules rule1">Minimum 3 characters, maximum 40 characters,
            the only special character allowed is a period.</p>
        </div><br> --><br>
        <div class="form-group">
          <!-- <router-link to="/login/vpsregistrationsuccessful"> -->
            <button class="submit-btn button" @click="handleRegistrationForm()" :disabled="vpsWholesaleVerizon.password=='' || vpsWholesaleVerizon.userId=='' || vpsWholesaleVerizon.confirmPassword==''">Continue</button>
          <!-- </router-link> -->
        </div>
      </div>
    </div>
  </div>
  <vpsFooter/>
  </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
import commonMixin from '@/common/mixin/commonMixins'
import vpsHeader from '@/vbgauth/components/vpsHeader'
import vpsFooter from '@/vbgauth/components/vpsFooter'
export default {
  name: "VPSRegistrationPage",
  components: {
    vpsHeader,
    vpsFooter
  },
  data() {
    return {
      componentName: "/",
      isPasswordValid: false,
      isConfPwdValid: false,
      isUserNameValid: false,
      validMessage: "",
      vpsWholesaleVerizon: {
        userId: "",
        password: "",
        confirmPassword: "",
        state: "",
        vpsGuid: "",
        firstName: "",
        lastName: "",
        email:"",
        guidId:""
      },
      invalidCharRegex: /[@()_~`!#$%\^&*+=\-\[\]\\';,/{}|\\": <>\?]/,
    };
  },
  methods: {
    ...mapActions('login', [
      'submitRegisterForm',
      'resetState',
      'updateVpsUserIdAlreadyExist',
    ]),
    handleRegistrationForm(){
      this.submitRegisterForm(this.vpsWholesaleVerizon);
    },
    validateUserName() {
      const regex = /^([A-z])*[^\s]\1*$/;
      if (regex.exec(this.vpsWholesaleVerizon.userId) == null) {
        this.isUserNameValid = true;
        this.updateVpsUserIdAlreadyExist(false)
      }
      else {
        this.isUserNameValid = false;
      }
    },
    validatePassword() {
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
      if (regex.exec(this.vpsWholesaleVerizon.password) == null) {
        this.validMessage = "Not Valid"
        this.isPasswordValid = true;
      }
      else {
        this.isPasswordValid = false;
      }
      if(!this.isPasswordValid && this.vpsWholesaleVerizon.password.length>8 && this.vpsWholesaleVerizon.password.length<11) {
        this.validMessage = "Medium";
      }
      if(!this.isPasswordValid && this.vpsWholesaleVerizon.password.length>11) {
        this.validMessage = "Strong";
      }
      this.checkSequence(this.vpsWholesaleVerizon.password);
    },
    validateConfirmPassword() {
      this.vpsWholesaleVerizon.password === this.vpsWholesaleVerizon.confirmPassword ? this.isConfPwdValid = false : this.isConfPwdValid = true;
    },
    checkSequence: function (password) {
      let sequenceMatched = false;
      let convertToLowerCase = password.toLowerCase();
      let checkString = convertToLowerCase.split("");
      let dupString = password.split('');
      let sequenceVal = ''
      for(let i = 3; i < checkString.length; i++) {
        if (!(this.invalidCharRegex.test(checkString[i]))) {
          if((checkString[i].charCodeAt() - checkString[i - 1].charCodeAt() == 1) && (checkString[i - 1].charCodeAt() - checkString[i - 2].charCodeAt() == 1) && (checkString[i - 2].charCodeAt() - checkString[i - 3].charCodeAt() == 1)) {
            sequenceMatched = true;
            sequenceVal = dupString[i - 3] + dupString[i - 2] + dupString[i - 1] + dupString[i]
            break;
          }
          if((checkString[i].charCodeAt() - checkString[i - 1].charCodeAt() == 0) && (checkString[i - 1].charCodeAt() - checkString[i - 2].charCodeAt() == 0) && (checkString[i - 2].charCodeAt() - checkString[i - 3].charCodeAt() == 0)) {
            sequenceMatched = true;
            sequenceVal = dupString[i - 3] + dupString[i - 2] + dupString[i - 1] + dupString[i];
            break;
          }
          if((checkString[i].charCodeAt() - checkString[i - 1].charCodeAt() == -1) && (checkString[i - 1].charCodeAt() - checkString[i - 2].charCodeAt() == -1) && (checkString[i - 2].charCodeAt() - checkString[i - 3].charCodeAt() == -1)) {
            sequenceMatched = true;
            sequenceVal = dupString[i - 3] + dupString[i - 2] + dupString[i - 1] + dupString[i];
            break;
          }
        }
      }
      if (sequenceMatched) {
        this.isPasswordValid = true
        this.validMessage = "Not valid"
      }
    },
  },
  mounted() {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('registrationToken')){
      let queryParamToken = atob(
        decodeURIComponent(searchParams.get('registrationToken'))
      );
      this.vpsWholesaleVerizon.vpsGuid = queryParamToken.split("|")[7];
      this.vpsWholesaleVerizon.firstName = queryParamToken.split("|")[5];
      this.vpsWholesaleVerizon.lastName = queryParamToken.split("|")[6];
      this.vpsWholesaleVerizon.email = queryParamToken.split("|")[4];
      this.vpsWholesaleVerizon.state = queryParamToken.split("|")[8];
      this.vpsWholesaleVerizon.guidId = queryParamToken.split("|")[2];
    }
  },
  computed : {
    ...mapGetters('login', [
      'getVpsUserIdAlreadyExist',
      'getVpsRegPwdAlert',
      'updateVpsUserIdAlreadyExist'
    ]),
  },
  beforeDestroy () {
    this.resetState();
  },
  watch: {
    getVpsRegPwdAlert: function (newVal) {
      if (newVal === true) {
        this.validMessage = "Avoid sequence like 1234, abcd, 1111."
        this.isPasswordValid = true
      }
    },
  },
};
</script>
<style scoped>
.page-wrapper {
  max-width: 960px;
  padding: 0;
  border: 2px solid #ebecec;
}

.register {
  display: flex;
  gap: 10px;
  padding: 15px;
}

.register-welcome-header {
  font-size: 32px;
  color: #000;
  margin: 0;
  font-family: "NeueHaasGroteskDisplay";
  line-height: 1;
}

.register-welcome-password {
  font-family: "NeueHaasGroteskDisplay";
  font-size: 16px;
  margin: 11px 10px 0px 0;
  font-weight: bold;
}

.register-welcome-des {
  font-weight: normal;
  margin-top: 3px;
  font-size: 14px;
}

.register-welcome-section {
  flex: 1;
}

.register-form-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.register-form-value {
  font-weight: bold;
  font-family: "NeueHaasGroteskDisplay";
}

.register-form-group-label {
  font-size: 14px;
  color: #747676;
  padding-bottom: 4px;
  display: block;
}

.form-input {
  max-width: 310px;
  border-color: #D8DADA #D8DADA #000000 #D8DADA;
  border-radius: 0px;
  color: #000000;
}

.rules {
  font-size: 12px;
}

.form-select {
  appearance: auto;
  -moz-appearance: auto;
  -webkit-appearance: auto;
}

.rule1 {
  margin-top: 10px;
}
.error {
  font-weight: 700;
  font-family: "NeueHaasGroteskDisplay";
  font-weight: bold;
  color: rgb(255, 188, 61);
}
.medium {
  color: rgb(237, 112, 0);
  font-family: "NeueHaasGroteskDisplay";
  font-weight: bold;
}
.strong {
  color: rgb(0, 172, 62);
  font-family: "NeueHaasGroteskDisplay";
  font-weight: bold;
}

.submit-btn {
  padding: .6rem 1.94rem;
  color: #f9f9f9;
  background-color: #010101;
  font-size: 14px;
  font-weight: 300;
  border-radius: 12rem;
  border: .2rem solid #010101;
  cursor: pointer;
  font-family: "NeueHaasGroteskDisplay";
  min-width: 120px;
}
.submit-btn:disabled {
  color:#fff;
  border-color: #a0a0a0;
  background-color: #a0a0a0;
  cursor:not-allowed;
}

.site-logo {
  border-bottom: 1px solid #ebecec;
  padding: 20px 20px 30px 20px;
  margin-bottom: 20px;
}

.vps-input-field-warning {
    border: 1px solid #ED7000 !important;
    border-bottom: 4px solid #ED7000 !important;
  }

.vps-input-errortext-style {
    height: 32px;
    width: 301px;
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    font-weight: bold;
  }

@media only screen and (min-width: 320px) and (max-width: 660px) {
  .register {
    flex-direction: column;
  }
  .form-input {
    width: 100%;
    max-width: 100%;
  }
}
</style>