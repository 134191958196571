import { amrest } from '@/vbgauth/util/amrest-axios.js'
import { ifgrest } from '@/vbgauth/util/ifgrest-axios.js'
import { getDeviceFingerprintInfoWithoutGeolocation } from '../../../../public/vbgIamDeviceFP.js'
import { setMaskedEmail, getCookie } from '@/vbgauth/util/helper.js'
import { playrest, iamPostAuthPlayrest } from '@/vbgauth/util/playrest-axios.js'
// import {logger} from '../../../../public/logger.js'
import translations from '@/common/assets/json/messages1.json'
import { iamvar_serverUrl, iamvar_enableJCaptcha, iamvar_secProfileHeaderName, iamvar_otpAlwaysOnHeaderName, iamvar_amSessionCookiePath, iamvar_amSessionCookieExpiryInMins, iamvar_amSessionCookieSecure, iamvar_vbgcRemCookieSecure, iamvar_vbgcRemCookiePath, iamvar_vbgcRemCookieExpiryInYears, iamvar_vbgcRemCookieName, iamvar_authRemEncrHeaderName, iamvar_authErrorHeaderName, iamvar_secProfileUrl, iamvar_enableCaptcha, iamvar_vbgcCookieName, iamvar_vbgcCookieHeaderName, iamvar_vbgcCookieExpiryInMins, iamvar_vbgcCookiePath, iamvar_vbgcCookieSecure, iamvar_makeDefaultMethodName, iamvar_csqaAlwaysOnHeaderName, iamvar_vcgSecProfileHeaderName, iamvar_vcgRegIncompleteHeaderName, iamvar_vcgMigrationLoginToken, iamvar_vcgMultipleAccount, iamvar_vcgRegIncompleteNoMtnHeaderName, iamvar_Vcg_Throttled_On } from '../../../../public/const.js'
import { errorSet, eventTracking } from '../../../../public/adobeTagging.js';
import { combinenowEvents, unifiedLoginEvents } from '@/common/assets/json/adobe-analytics.json';
import router from '@/vbgauth/router'
import mixin from '@/common/mixin/commonMixins.js'
const myBizzForgot = localStorage.getItem('mybizzFlag') === "true" ? true : false

let docHTML = document.getElementsByTagName('html');

const getParameterByName = (name) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(window.location.href);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
const isVPSFlowEnabled = iamvar_vpsFlowToggle;
export default {
    namespaced: true,
    state: {
        callbacks: [
            {
                type: "NameCallback",
                output: [{
                    name: "prompt",
                    value: "User Name"
                }],
                input: [{
                    name: "IDToken1",
                    value: ""
                }]
            },
            {
                type: "PasswordCallback",
                output: [{
                    name: "prompt",
                    value: "Password"
                }],
                input: [{
                    name: "IDToken2",
                    value: ""
                }]
            }
        ],
        pwdAuthOptions: [
            {
                available: true,
                disable: false,
                title: "Send me a push notification",
                subTitle: "We'll send a secure push notification to your business app so you can log in.",
                pagePath: "PwdLessPush"
            },
            {
                available: true,
                disable: false,
                title: "Verify with phone or email",
                subTitle: "Use your phone number or email address to confirm it's you.",
                pagePath: "PwdLessVerify"
            },
            {
                available: true,
                disable: false,
                title: "Log in with my password",
                subTitle: "Enter your password to access your account.",
                pagePath: "PwdLessPassword"
            }
        ],
        options: {
            headers: {
                'Accept-API-Version': 'resource=2.0',
                'protocol': '1.0'
            }
        },
        akkaHeaders: {
            headers: {
                'content-type': 'application/json'
            }
        },
        citizenHeaders: {
            headers: {
                'Cache-Control': 'no-cache',
                'Accept-API-Version': 'resource=3.1, protocol=1.0'
            }
        },
        submitLoader: false,
        authError: false,
        vsbdrAuthError: false,
        vsbdrMailId: '',
        vsbdrMaskedMailId: '',
        gotoUrl: '',
        encodedGotoUrl: '',
        redirectToStepupPage: null,
        redirectToSecProfilePage: null,
        redirectToOptionsPage: null,
        redirectToPasswordPage: null,
        userAuthenticated: false,
        urlOnMount: null,
        vbgc: null,
        authId: null,
        userType: null,
        captchaToken: null,
        mvbContent: {},
        federalPortal: false,
        alertType: 'error',
        idmRedirectUrl: '',
        sanitizedAuthenticationPayload: {},
        popupValue: null,
        mybizzFlag: myBizzForgot,
        unifiedloginAlerts: [],
        emptyUnifiedloginAlerts: [],
        vsbdrForgotAlerts: [],
        vsbdrLocale: 'en',
        passwordSuccess: [],
        interstitialPage: false,
        userSelectedAccount: null,
        unifiedErrorCode: null,
        rememberMeTrue: false,
        makeDefaultMethodTrue: false,
        combinenowValue: 'combinenow',
        initialUsername: null,
        saveFingerPrintResponse: null,
        combinenowAlerts: [],
        combinenowErrorCode: null,
        uuId: null,
        rToken: null,
        preferredPortal: null,
        comingFromProfilePage: false,
        // invalidCount: 0,
        // toBeMergeUserID: null,
        userAgent: null,
        remembermeUserName: null,
        pwdUsername: null,
        loginAnotherWayEnable: false,
        pwdPassWord: null,
        lockedUserName: null,
        tokenExpired: false,
        optionMethod: '',
        pushOState: '',
        pwdInlineError: '',
        unlockMyAccountAlerts: [],
        resetSecurityProfile: false,
        clearFormFields: false,
        displayPopupClass:false,
        lineOfBusiness: 0,
        vcgProfileView: '',
        vcgMtn:"",
        vcgRedirectToCsqaStepup: '',
        vcgSubmitLoader: '',
        vcgThrotteledOn: false,
        isVpsUserIdAlreadyExist : false,
        vpsUserRegPwdAlert : false,
    },
    mutations: {
        setFormData(state, payload) {
            state.callbacks = payload
        },
        setPwdAuthOptions(state, payload) {
            state.pwdAuthOptions = payload
        },
        setLoader(state, payload) {
            state.submitLoader = payload
        },
        setAuthError(state, payload) {
            state.authError = payload
        },
        setGotoUrl(state, payload) {
            state.gotoUrl = payload
        },
        setEncodedGotoUrl(state, payload) {
            state.encodedGotoUrl = payload
        },
        setRedirectToStepupPage(state, payload) {
            state.redirectToStepupPage = payload
        },
        setRedirectToOptionsPage(state, payload) {
            state.redirectToOptionsPage = payload
        },
        setRedirectToPasswordPage(state, payload) {
            state.redirectToPasswordPage = payload
        },
        setRedirectToSecurityProfilePage(state, payload) {
            state.redirectToSecProfilePage = payload
        },
        setUserAuthenticated(state, payload) {
            state.userAuthenticated = payload
        },
        setUrlOnMount(state, payload) {
            state.urlOnMount = payload
        },
        setVBGCCookie(state, payload) {
            state.vbgc = payload
        },
        setAMToken(state, payload) {
            state.authId = payload
        },
        setUserType(state, payload) {
            state.userType = payload
        },
        setCaptchaToken(state, payload) {
            state.captchaToken = payload
        },
        setMvbContent(state, payload) {
            state.mvbContent = payload
        },
        setFederalPortal(state, payload) {
            state.federalPortal = payload
        },
        setAlertType(state, payload) {
            state.alertType = payload
        },
        setIdmRedirectUrl(state, payload) {
            state.idmRedirectUrl = payload
        },
        setSanitizedAuthenticationPayload(state, payload) {
            state.sanitizedAuthenticationPayload = payload
        },
        setMybizz(state, payload) {
            state.mybizzFlag = payload
            localStorage.setItem('mybizzFlag', payload)
        },
        setPopupValue(state, payload) {
            state.popupValue = payload
        },
        setUnifiedLoginAlerts(state, payload) {
            state.unifiedloginAlerts = payload
        },
        setEmptyUnifiedLoginAlerts(state, payload) {
            state.emptyUnifiedloginAlerts = payload
        },
        setVsbdrForgotAlerts(state, payload) {
            state.vsbdrForgotAlerts = payload
        },
        setVsbdrLocale(state, payload) {
            state.vsbdrLocale = payload
        },
        setPasswordSuccess(state, payload) {
            state.passwordSuccess = payload
        },
        setInterstitialPage(state, payload) {
            state.interstitialPage = payload
        },
        setUserSelectedAccount(state, payload) {
            state.userSelectedAccount = payload
        },
        setUnifiedErrorCode(state, payload) {
            state.unifiedErrorCode = payload
        },
        setRememberMe(state, payload) {
            state.rememberMeTrue = payload
        },
        setDefaultMethod(state, payload) {
            state.makeDefaultMethodTrue = payload
        },
        setCombinenowValue(state, payload) {
            state.combinenowValue = payload
        },
        setCombinenowAlerts(state, payload) {
            state.combinenowAlerts = payload
        },
        setCombinenowErrorCode(state, payload) {
            state.combinenowErrorCode = payload
        },
        setInitialUsername(state, payload) {
            state.initialUsername = payload
        },
        setSaveFingerPrintResponse(state, payload) {
            state.saveFingerPrintResponse = payload
        },
        setUserId(state, payload) {
            state.uuId = payload
        },
        setResponseToken(state, payload) {
            state.rToken = payload
        },
        setPreferredPortal(state, payload) {
            state.preferredPortal = payload
        },
        setComingFromProfilePage(state, payload) {
            state.comingFromProfilePage = payload
        },
        // setInvalidCount(state, payload) {
        //     state.invalidCount = payload
        // },
        // setToBeMergeUserID(state, payload) {
        //     state.toBeMergeUserID = payload
        // },
        setUserAgent(state, payload) {
            state.userAgent = payload
        },
        setuserName(state, payload) {
            state.remembermeUserName = payload
        },
        setPwdUsername(state, payload) {
            state.pwdUsername = payload
        },
        setLoginAnotherWayEnable(state, payload) {
            state.loginAnotherWayEnable = payload
        },
        setPwdInlineError(state, payload) {
            state.pwdInlineError = payload
        },
        setPwdPassWord(state, payload) {
            state.pwdPassWord = payload
        },
        setVsbdrAuthError(state, payload) {
            state.vsbdrAuthError = payload
        },
        setVsbdrMail(state, payload) {
            state.vsbdrMailId = payload
        },
        setVsbdrMaskedMail(state, payload) {
            state.vsbdrMaskedMailId = payload
        },
        setLockedUserName(state, payload) {
            state.lockedUserName = payload
        },
        setTokenExpired(state, payload) {
            state.tokenExpired = payload
        },
        setUnlockMyAccountAlerts(state, payload) {
            state.unlockMyAccountAlerts = payload
        },
        setResetSecurityProfile(state, payload) {
            state.resetSecurityProfile = payload
        },
        setClearFormFields(state, payload) {
            state.clearFormFields = payload
        },
        setDisplayPopupClass(state,payload){
            state.displayPopupClass = payload
        },    
        setLineOfBusiness(state, payload) {
            state.lineOfBusiness = payload
        },
        setVcgProfileView(state, payload) {
            state.vcgProfileView = payload
        },
        setVcgMtn(state, payload){
            state.vcgMtn = payload
        },
        setVcgRedirectToCsqaStepup(state, payload) {
            state.vcgRedirectToCsqaStepup = payload
        },
        setVcgSubmitLoader(state, payload) {
            state.vcgSubmitLoader = payload
        },
        setVcgThrotteledOn(state, payload) {
            state.vcgThrotteledOn = payload
        },
        setVpsUserIdAlreadyExist(state, payload) {
            state.isVpsUserIdAlreadyExist = payload
        },
        setVpsUserRegPasswordAlert(state,payload) {
            state.vpsUserRegPwdAlert = payload
        },
    },
    actions: {
        encryptDefaultMethod({ state }, payload) {
            //delete old vbgremc cookie before you set new cookie value
            for (var i = 0; i < iamvar_vbgcRemCookieDomain.length; i++) {
                document.cookie = iamvar_makeDefaultMethodName + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=' + iamvar_vbgcRemCookieDomain[i] + ';path=' + iamvar_vbgcRemCookiePath + ';secure=' + iamvar_vbgcRemCookieSecure + ';comment=deleting from ui'
            }
            //document.cookie = iamvar_makeDefaultMethodName +'=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            var newPayload = {
                'username': payload
            }
            return playrest.post(iamvar_remembermeServ + '/user/encryptuser', newPayload, state.akkaHeaders).then(function (resp) {
                var encrUserNameVal = resp.data[iamvar_authRemEncrHeaderName]
                var expiration_date = new Date()
                expiration_date.setFullYear(expiration_date.getFullYear() + iamvar_vbgcRemCookieExpiryInYears)
                for (var i = 0; i < iamvar_vbgcRemCookieDomain.length; i++) {
                    document.cookie = iamvar_makeDefaultMethodName + '=' + encrUserNameVal + ';expires=' + expiration_date.toUTCString() + ';domain=' + iamvar_vbgcRemCookieDomain[i] + ';path=' + iamvar_vbgcRemCookiePath + ';secure=' + iamvar_vbgcRemCookieSecure + ';comment=setting in ui'
                }
            }).catch((error) => {
                //console.log(error)
            })
        },
        submitUserId({ dispatch, commit }, payload) {
            vbgPushLogin(payload.userName, '')

        },
        submitPwdLessPushLogin({ dispatch, commit }, payload) {

            dispatch('pwdLessPushLogin', payload)
            //pwdLessPushLogin(payload.userName,'')
        },
        pwdLessPushLogin({ commit, dispatch, state }, payload) {
            commit('setLoader', true)
            clearInterval(interval);
            // event.preventDefault();
            let option = state.options
            option.headers['X-VZ-FLOWTYPE'] = 'npush'
            //console.log(usrn)
            return amrest.post('json/realms/root/realms/vzwmb/authenticate?authIndexType=service&authIndexValue=VBGPushService', {}, option)
                .then(function (res) {
                    let data = res.data
                    data.callbacks[0].input[0].value = payload.userName;
                    let newUserPayload = {
                        data: data,
                    };
                    commit('setLoader', false)
                    dispatch('authenticatePwdLessUserPush', newUserPayload)
                    //authenticatePwdLessUserPush(newUserPayload);
                })
                .catch(function (err) {
                    commit('setLoader', false)
                    if (document.getElementById('notificationWait')) {
                        document.getElementById('notificationWait').setAttribute('style', 'display:none;');
                    }
                    if (document.getElementById('IAMerrorMessage')) {
                        document.getElementById('errorIAMPush').setAttribute('style', 'display:flex');
                        document.getElementById('pushContainer').setAttribute('style', 'height:800px');
                        document.getElementById("IAMerrorMessage").innerHTML = "Hmm... We may have a system error. Please try again.";
                    }
                    if (document.getElementById("IAMinfoMessage")) {
                        document.getElementById('infoIAMPush').setAttribute('style', 'display:none');
                    }
                    throw err;
                });
        },
        authenticatePwdLessUserPush({ commit, dispatch, state }, payload) {
            commit('setLoader', true)
            let option = state.options
            option.headers['X-VZ-FLOWTYPE'] = 'npush'
            let reqUrl = 'json/realms/root/realms/vzwmb/authenticate?authIndexType=service&authIndexValue=VBGPushService'
            if (document.getElementById('errorIAMPush')) {
                document.getElementById('errorIAMPush').style = 'display: none;'
            }
            return amrest.post(reqUrl, payload.data, option).then(function (res) {
                let authResponse = res.data;
                //console.log(authResponse.tokenId)
                var callbacks = JSON.parse(JSON.stringify(res.data))
                if ('tokenId' in callbacks) {
                    //console.log("received tokenId in first call")
                    //console.log('tokenId is present')
                    defLpUrl = res.headers['x-vz-userpreferences'];
                    userRole = res.headers['x-vz-vzwroles'];
                    constructSession(res)
                }

                let lockState = res.headers['x-vz-auth_ostate']
                if (lockState != undefined) {
                    /*if (lockState == "ACCOUNT_PUSH_LOCKED") {
                        if(document.getElementById("IAMerrorMessage")) {
                            document.getElementById('errorIAMPush').setAttribute('style', 'display:flex');
                            document.getElementById("IAMerrorMessage").innerHTML = "<b>You have no remaining verification attempts</b><p>Please wait 24 hours to access your account. Use another way to login.";
                        } 
                        if(document.getElementById("pushResend")) {
                            document.getElementById("pushResend").disabled = true;
                        }
                        state.pwdAuthOptions[0].disable = true
                    }
                    else if (lockState == "ACCOUNT_PUSH_PRE_LOCK") {
                        if(document.getElementById("IAMerrorMessage")) {
                            document.getElementById('errorIAMPush').setAttribute('style', 'display:flex');
                            document.getElementById("IAMerrorMessage").innerHTML = "<b>This is your final login attempt</b><p>If this doesn't work, you'll have to reset your password.";
                        }        
                    }*/
                    state.pushOState = lockState
                }

                if (authResponse.callbacks[0].output[0].value == "INVALID_CREDENTIALS") {
                    commit('setLoader', false)
                    dispatch('pwdLessUserIdInvalid', authResponse)
                    //pwdLessUserIdInvalid(authResponse)
                } else if (authResponse.callbacks[0].output[0].value == "MVB_NOT_REGISTERED") {
                    commit('setLoader', false)
                    if (getParameterByName("isPushRedirected") === 'true' || getParameterByName("isPushRedirected") === true) {
                        if (document.getElementById('notificationWait')) {
                            document.getElementById('notificationWait').setAttribute('style', 'display:none;');
                        }
                        if (document.getElementById("IAMinfoMessage")) {
                            document.getElementById('infoIAMPush').setAttribute('style', 'display:flex');
                            // document.getElementById("IAMinfoMessage").innerHTML = "<b>You must be logged in to the My Verizon For Business app</b><p>Log in to the app and select <a class='underline' id='resendN'> Resend notification.</a>";
                            document.getElementById("IAMinfoMessage").innerHTML = "<b>You must be logged in to the My Verizon For Business app</b><p>Log in to the app and select <a class='underline' id='resendN'> Resend notification.</a>";

                        }
                        if (document.getElementById("IAMerrorMessage")) {
                            document.getElementById('errorIAMPush').style = 'display: none;'
                        }
                    } else {
                        router.push({ name: 'PwdLessPushNotRegistered' })
                        if (document.getElementById("IAMerrorMessage")) {
                            document.getElementById('errorIAMPush').style = 'display: flex;'
                            document.getElementById("IAMerrorMessage").innerHTML = "User Id not found. Please download the app and register."
                        }
                        if (document.getElementById("IAMinfoMessage")) {
                            document.getElementById('infoIAMPush').setAttribute('style', 'display:none');
                        }
                    }

                    //dispatch('pwdLessUserIdInvalid', authResponse)
                    //pwdLessUserIdInvalid(authResponse)
                } else if (authResponse.callbacks[0].output[0].value == "MVB_NOT_DELIVERED") {
                    commit('setLoader', false)
                    if (state.pushOState == "ACCOUNT_PUSH_LOCKED") {
                        if (document.getElementById('notificationWait')) {
                            document.getElementById('notificationWait').setAttribute('style', 'display:none;');
                        }
                        if (document.getElementById("IAMerrorMessage")) {
                            document.getElementById('errorIAMPush').setAttribute('style', 'display:flex');
                            document.getElementById("IAMerrorMessage").innerHTML = "<b>You have no remaining verification attempts</b><p>Please wait 24 hours to access your account. Use another way to login.";
                        }
                        if (document.getElementById("IAMinfoMessage")) {
                            document.getElementById('infoIAMPush').setAttribute('style', 'display:none');
                        }
                        if (document.getElementById("pushResend")) {
                            //document.getElementById("pushResend").disabled = true;
                            document.getElementById("pushResend").style.pointerEvents = "none";
                            document.getElementById("pushResend").style.cursor = "default";
                            document.getElementById("pushResendLink").style.color = "lightgrey";
                        }
                        state.pwdAuthOptions[0].disable = true
                    }
                    else if (state.pushOState == "ACCOUNT_PUSH_PRE_LOCK") {
                        if (document.getElementById('notificationWait')) {
                            document.getElementById('notificationWait').setAttribute('style', 'display:none;');
                        }
                        if (document.getElementById("IAMerrorMessage")) {
                            document.getElementById('errorIAMPush').setAttribute('style', 'display:flex');
                            document.getElementById("IAMerrorMessage").innerHTML = "<b>This is your final login attempt</b><p>If this doesn't work, you'll have to reset your password.";
                        }
                        if (document.getElementById("IAMinfoMessage")) {
                            document.getElementById('infoIAMPush').setAttribute('style', 'display:none');
                        }
                    } else if (state.pushOState == "ACCOUNT_LOGIN_LOCKED" || state.pushOState == "OTP_COUNTER_AND_PROFILE_INVALID_ATTEMPTS_COUNTE_MISMATCH") {
                        commit('setLockedUserName', state.callbacks[0].input[0].value)
                        router.push({ name: 'ResetPassword', params: { lockType: 'resetPassword' } })
                    } else if (state.pushOState == "ACCOUNT_LOGIN_ABANDONMENT") {
                        commit('setLockedUserName', state.callbacks[0].input[0].value)
                        router.push({ name: 'ResetPassword', params: { lockType: 'resetPassword' } })
                    } else if(state.pushOState == "ACCOUNT_COMPROMIZED") {
                        router.push({ name: 'ResetPassword', params: { lockType: 'resetSecretAnswer' } })
                    } else if (document.getElementById("IAMerrorMessage")) {
                        document.getElementById('errorIAMPush').style = 'display: flex;'
                        //document.getElementById('pushContainer').setAttribute('style', 'height:800px');
                        document.getElementById("IAMerrorMessage").innerHTML = "<b>You must be logged in to the My Verizon For Business app</b><p>Log in to the app and select <a class='underline' id='resendN'> Resend notification.</a>"
                        if (document.getElementById('notificationWait')) {
                            document.getElementById('notificationWait').setAttribute('style', 'display:none;');
                        }
                        if (document.getElementById("IAMinfoMessage")) {
                            document.getElementById('infoIAMPush').setAttribute('style', 'display:none');
                        }
                    }
                } else {
                    commit('setLoader', false)
                    dispatch('pwdLessPollingCallbacks', authResponse)
                    //pwdLessPollingCallbacks(authResponse)
                    router.push({ name: 'PwdLessPushRegistered' })
                    //console.log(authResponse)
                }
                commit('setLoader', false)
            })
                .catch(function (err) {
                    commit('setLoader', false)
                    if (document.getElementById('notificationWait')) {
                        document.getElementById('notificationWait').setAttribute('style', 'display:none;');
                    }
                    if (document.getElementById('IAMerrorMessage')) {
                        document.getElementById('pushContainer').setAttribute('style', 'height:800px');
                        document.getElementById('errorIAMPush').setAttribute('style', 'display:flex');
                        document.getElementById("IAMerrorMessage").innerHTML = "Hmm... We may have a system error. Please try again.<a id='resendN'>resend</a>";
                    }
                    if (document.getElementById("IAMinfoMessage")) {
                        document.getElementById('infoIAMPush').setAttribute('style', 'display:none');
                    }
                    //console.log('error with pwdLessPushLogin')
                    throw err;
                });
        },
        pwdLessUserIdInvalid({ commit, dispatch, state }, payload) {
            commit('setLoader', true)
            let option = state.options
            option.headers['X-VZ-FLOWTYPE'] = 'npush'
            let reqUrl = 'json/realms/root/realms/vzwmb/authenticate?authIndexType=service&authIndexValue=VBGPushService'
            return amrest.post(reqUrl, payload, option).then(function (res) {
                //console.log(res.data)
                commit('setLoader', false)
            }).catch(function (err) {
                commit('setLoader', false)
                if (document.getElementById('IAMerrorMessage')) {
                    document.getElementById('pushContainer').setAttribute('style', 'height:800px');
                    document.getElementById('errorIAMPush').setAttribute('style', 'display:flex');
                    document.getElementById("IAMerrorMessage").innerHTML = "User Id not found. Please download the app and register.";
                }
                if (document.getElementById("IAMinfoMessage")) {
                    document.getElementById('infoIAMPush').setAttribute('style', 'display:none');
                }
                // window.location.href=sanitizeUrl(window.location.href + "?Login_Push_Message=invalidUserIdPush")
                throw err;
            });
        },
        pwdLessPollingCallbacks({ commit, dispatch, state }, payload) {
            // timer message display for 2 minutes when polling calls start
            commit('setLoader', true)
            if (document.getElementById('notificationWait')) {
                document.getElementById('notificationWait').setAttribute('style', 'display:flex;');
            }
            let option = state.options
            option.headers['X-VZ-FLOWTYPE'] = 'npush'
            let secondsRemaining = 120
            let minutes; let seconds;
            interval = setInterval(function () {
                minutes = parseInt(secondsRemaining / 60, 10);
                seconds = parseInt(secondsRemaining % 60, 10);
                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;
                if (document.getElementById('expiretime')) {
                    document.getElementById('expiretime').innerHTML = minutes + ":" + seconds;
                }
                // time is up
                if (secondsRemaining === 0 || secondsRemaining < 0) {
                    clearInterval(interval);
                    clearInterval(intervalsetcall);
                    if (document.getElementById('notificationWait')) {
                        document.getElementById('notificationWait').setAttribute('style', 'display:none;');
                    }
                    setTimeout(function () {
                        if (state.pushOState != undefined) {
                            if (state.pushOState == "ACCOUNT_PUSH_LOCKED") {
                                if (document.getElementById('notificationWait')) {
                                    document.getElementById('notificationWait').setAttribute('style', 'display:none;');
                                }
                                if (document.getElementById("IAMerrorMessage")) {
                                    document.getElementById('errorIAMPush').setAttribute('style', 'display:flex');
                                    document.getElementById("IAMerrorMessage").innerHTML = "<b>You have no remaining verification attempts</b><p>Please wait 24 hours to access your account. Use another way to login.";
                                }
                                if (document.getElementById("IAMinfoMessage")) {
                                    document.getElementById('infoIAMPush').setAttribute('style', 'display:none');
                                }
                                if (document.getElementById("pushResend")) {
                                    //document.getElementById("pushResend").disabled = true;
                                    document.getElementById("pushResend").style.pointerEvents = "none";
                                    document.getElementById("pushResend").style.cursor = "default";
                                    document.getElementById("pushResendLink").style.color = "lightgrey";
                                }
                                state.pwdAuthOptions[0].disable = true
                            }
                            else if (state.pushOState == "ACCOUNT_PUSH_PRE_LOCK") {
                                if (document.getElementById('notificationWait')) {
                                    document.getElementById('notificationWait').setAttribute('style', 'display:none;');
                                }
                                if (document.getElementById("IAMerrorMessage")) {
                                    document.getElementById('errorIAMPush').setAttribute('style', 'display:flex');
                                    document.getElementById("IAMerrorMessage").innerHTML = "<b>This is your final login attempt</b><p>If this doesn't work, you'll have to reset your password.";
                                }
                                if (document.getElementById("IAMinfoMessage")) {
                                    document.getElementById('infoIAMPush').setAttribute('style', 'display:none');
                                }
                            } else if (state.pushOState == "ACCOUNT_LOGIN_LOCKED" || state.pushOState == "OTP_COUNTER_AND_PROFILE_INVALID_ATTEMPTS_COUNTE_MISMATCH") {
                                commit('setLockedUserName', state.callbacks[0].input[0].value)
                                router.push({ name: 'ResetPassword', params: { lockType: 'resetPassword' } })
                            } else if (state.pushOState == "ACCOUNT_LOGIN_ABANDONMENT") {
                                commit('setLockedUserName', state.callbacks[0].input[0].value)
                                router.push({ name: 'ResetPassword', params: { lockType: 'resetPassword' } })
                            } else if (state.pushOState == "ACCOUNT_COMPROMIZED") {
                                router.push({ name: 'ResetPassword', params: { lockType: 'resetSecretAnswer' } })
                            } else {
                                dispatch('pwdLogin_pushExpired', '')
                            }
                        } else {
                            dispatch('pwdLogin_pushExpired', '')
                        }
                        //pwdLogin_pushExpired();
                    }, 3000)
                }
                secondsRemaining--;
            }, 1000);
            // polling api call
            let reqUrl = 'json/realms/root/realms/vzwmb/authenticate?authIndexType=service&authIndexValue=VBGPushService'
            intervalsetcall = setInterval(function () {
                return amrest.post(reqUrl, payload, option).then(function (res) {
                    var callbacks = JSON.parse(JSON.stringify(res.data))
                    let lockState = res.headers['x-vz-auth_ostate']
                    if (lockState != undefined) {
                        state.pushOState = lockState
                    }
                    //push notification is accepted, tokenId present in callbacks
                    if ('tokenId' in callbacks) {
                        clearInterval(intervalsetcall);
                        //console.log("push is accepted redirect to construct session")
                        defLpUrl = res.headers['x-vz-userpreferences'];
                        userRole = res.headers['x-vz-vzwroles'];
                        constructSession(res)

                    }
                    if (res.data.callbacks[0].type == "TextOutputCallback") {


                        clearInterval(intervalsetcall);

                        if (res.data.callbacks[0].output[0].value == "PUSH_NOTIFICATION_EXPIRED") {
                            commit('setLoader', false)
                            //let lockState = res.headers['x-vz-auth_ostate']            
                            if (state.pushOState != undefined) {
                                if (state.pushOState == "ACCOUNT_PUSH_LOCKED") {
                                    if (document.getElementById("IAMerrorMessage")) {
                                        document.getElementById('errorIAMPush').setAttribute('style', 'display:flex');
                                        document.getElementById("IAMerrorMessage").innerHTML = "<b>You have no remaining verification attempts</b><p>Please wait 24 hours to access your account. Use another way to login.";
                                    }
                                    if (document.getElementById("pushResend")) {
                                        //document.getElementById("pushResend").disabled = true;
                                        document.getElementById("pushResend").style.pointerEvents = "none";
                                        document.getElementById("pushResend").style.cursor = "default";
                                        document.getElementById("pushResendLink").style.color = "lightgrey";
                                    }
                                    if (document.getElementById("IAMinfoMessage")) {
                                        document.getElementById('infoIAMPush').setAttribute('style', 'display:none');
                                    }
                                    state.pwdAuthOptions[0].disable = true
                                } else if (state.pushOState == "ACCOUNT_PUSH_PRE_LOCK") {
                                    if (document.getElementById("IAMerrorMessage")) {
                                        document.getElementById('errorIAMPush').setAttribute('style', 'display:flex');
                                        document.getElementById("IAMerrorMessage").innerHTML = "<b>This is your final login attempt</b><p>If this doesn't work, you'll have to reset your password.";
                                    }
                                    if (document.getElementById("IAMinfoMessage")) {
                                        document.getElementById('infoIAMPush').setAttribute('style', 'display:none');
                                    }
                                } else if (state.pushOState == "ACCOUNT_LOGIN_LOCKED" || state.pushOState == "OTP_COUNTER_AND_PROFILE_INVALID_ATTEMPTS_COUNTE_MISMATCH") {
                                    commit('setLockedUserName', state.callbacks[0].input[0].value)
                                    router.push({ name: 'ResetPassword', params: { lockType: 'resetPassword' } })
                                } else if (state.pushOState == "ACCOUNT_COMPROMIZED") {
                                    router.push({ name: 'ResetPassword', params: { lockType: 'resetSecretAnswer' } })
                                } else if (state.pushOState == "ACCOUNT_LOGIN_ABANDONMENT") {
                                    commit('setLockedUserName', state.callbacks[0].input[0].value)
                                    router.push({ name: 'ResetPassword', params: { lockType: 'resetPassword' } })
                                } else {
                                    dispatch('pwdLessLogin_pushExpired', res.data)
                                }
                            } else {
                                dispatch('pwdLessLogin_pushExpired', res.data)
                            }
                            //dispatch('pwdLessLogin_pushExpired', res.data)
                            //pwdLessLogin_pushExpired(res.data)
                            if (document.getElementById('notificationWait')) {
                                document.getElementById('notificationWait').setAttribute('style', 'display:none;');
                            }
                        } else if (res.data.callbacks[0].output[0].value == "PUSH_NOTIFICATION_DENIED") {
                            commit('setLoader', false)
                            //let lockState = res.headers['x-vz-auth_ostate']            
                            if (state.pushOState != undefined) {
                                if (state.pushOState == "ACCOUNT_PUSH_LOCKED") {
                                    if (document.getElementById("IAMerrorMessage")) {
                                        document.getElementById('errorIAMPush').setAttribute('style', 'display:flex');
                                        document.getElementById("IAMerrorMessage").innerHTML = "<b>Access denied, you have no remaining verification attempts</b><p>Please wait 24 hours to access your account. Use another way to login.";
                                    }
                                    if (document.getElementById("IAMinfoMessage")) {
                                        document.getElementById('infoIAMPush').setAttribute('style', 'display:none');
                                    }
                                    if (document.getElementById("pushResend")) {
                                        //document.getElementById("pushResend").disabled = true;
                                        document.getElementById("pushResend").style.pointerEvents = "none";
                                        document.getElementById("pushResend").style.cursor = "default";
                                        document.getElementById("pushResendLink").style.color = "lightgrey";
                                    }
                                    state.pwdAuthOptions[0].disable = true
                                } else if (state.pushOState == "ACCOUNT_PUSH_PRE_LOCK") {
                                    if (document.getElementById("IAMerrorMessage")) {
                                        document.getElementById('errorIAMPush').setAttribute('style', 'display:flex');
                                        document.getElementById("IAMerrorMessage").innerHTML = "<b>Access denied, this is your final login attempt</b><p>If this doesn't work, you'll have to reset your password.";
                                    }
                                    if (document.getElementById("IAMinfoMessage")) {
                                        document.getElementById('infoIAMPush').setAttribute('style', 'display:none');
                                    }
                                } else if (state.pushOState == "ACCOUNT_LOGIN_LOCKED" || state.pushOState == "OTP_COUNTER_AND_PROFILE_INVALID_ATTEMPTS_COUNTE_MISMATCH") {
                                    commit('setLockedUserName', state.callbacks[0].input[0].value)
                                    router.push({ name: 'ResetPassword', params: { lockType: 'resetPassword' } })
                                } else if (state.pushOState == "ACCOUNT_COMPROMIZED") {
                                    router.push({ name: 'ResetPassword', params: { lockType: 'resetSecretAnswer' } })
                                } else if (state.pushOState == "ACCOUNT_LOGIN_ABANDONMENT") {
                                    commit('setLockedUserName', state.callbacks[0].input[0].value)
                                    router.push({ name: 'ResetPassword', params: { lockType: 'resetPassword' } })
                                } else {
                                    dispatch('pwdLessLogin_pushDenied', res.data)
                                }
                            } else {
                                dispatch('pwdLessLogin_pushDenied', res.data)
                            }
                            //dispatch('pwdLessLogin_pushDenied', res.data)
                            //pwdLessLogin_pushDenied(res.data)
                            if (document.getElementById('notificationWait')) {
                                document.getElementById('notificationWait').setAttribute('style', 'display:none;');
                            }
                        }


                    }

                    /*let lockState = res.headers['x-vz-auth_ostate']            
                    if (lockState != undefined) {
                        if (lockState == "ACCOUNT_PUSH_LOCKED") {
                            if(document.getElementById("IAMerrorMessage")) {
                                document.getElementById('errorIAMPush').setAttribute('style', 'display:flex');
                                document.getElementById("IAMerrorMessage").innerHTML = "<b>You have no remaining verification attempts</b><p>Please wait 24 hours to access your account. Use another way to login.";
                            }
                            if(document.getElementById("pushResend")) {
                                document.getElementById("pushResend").disabled = true;
                            } 
                            state.pwdAuthOptions[0].disable = true
                        }
                        else if (lockState == "ACCOUNT_PUSH_PRE_LOCK") {
                            if(document.getElementById("IAMerrorMessage")) {
                                document.getElementById('errorIAMPush').setAttribute('style', 'display:flex');
                                document.getElementById("IAMerrorMessage").innerHTML = "<b>This is your final login attempt</b><p>If this doesn't work, you'll have to reset your password.";
                            }
                        }        
                    }*/

                }).catch(function (err) {
                    commit('setLoader', false)
                    //console.log('error with pwdLessPushLogin')
                    let errorData = err.response;
                    if (errorData.status == 401) {
                        pushResponse = errorData.headers['x-vz-auth-error'];
                        if (pushResponse == "PUSH_NOTIFICATION_DENIED") {
                            clearInterval(intervalsetcall);
                            if (state.pushOState != undefined) {
                                if (state.pushOState == "ACCOUNT_PUSH_LOCKED") {
                                    if (document.getElementById('notificationWait')) {
                                        document.getElementById('notificationWait').setAttribute('style', 'display:none;');
                                    }
                                    if (document.getElementById("IAMerrorMessage")) {
                                        document.getElementById('errorIAMPush').setAttribute('style', 'display:flex');
                                        document.getElementById("IAMerrorMessage").innerHTML = "<b>Access denied, you have no remaining verification attempts</b><p>Please wait 24 hours to access your account. Use another way to login.";
                                    }
                                    if (document.getElementById("IAMinfoMessage")) {
                                        document.getElementById('infoIAMPush').setAttribute('style', 'display:none');
                                    }
                                    if (document.getElementById("pushResend")) {
                                        //document.getElementById("pushResend").disabled = true;
                                        document.getElementById("pushResend").style.pointerEvents = "none";
                                        document.getElementById("pushResend").style.cursor = "default";
                                        document.getElementById("pushResendLink").style.color = "lightgrey";
                                    }
                                    state.pwdAuthOptions[0].disable = true
                                }
                                else if (state.pushOState == "ACCOUNT_PUSH_PRE_LOCK") {
                                    if (document.getElementById('notificationWait')) {
                                        document.getElementById('notificationWait').setAttribute('style', 'display:none;');
                                    }
                                    if (document.getElementById("IAMerrorMessage")) {
                                        document.getElementById('errorIAMPush').setAttribute('style', 'display:flex');
                                        document.getElementById("IAMerrorMessage").innerHTML = "<b>Access denied, this is your final login attempt</b><p>If this doesn't work, you'll have to reset your password.";
                                    }
                                    if (document.getElementById("IAMinfoMessage")) {
                                        document.getElementById('infoIAMPush').setAttribute('style', 'display:none');
                                    }
                                } else if (state.pushOState == "ACCOUNT_LOGIN_LOCKED" || state.pushOState == "OTP_COUNTER_AND_PROFILE_INVALID_ATTEMPTS_COUNTE_MISMATCH") {
                                    commit('setLockedUserName', state.callbacks[0].input[0].value)
                                    router.push({ name: 'ResetPassword', params: { lockType: 'resetPassword' } })
                                } else if (state.pushOState == "ACCOUNT_COMPROMIZED") {
                                   router.push({ name: 'ResetPassword', params: { lockType: 'resetSecretAnswer' } })
                                } else if (state.pushOState == "ACCOUNT_LOGIN_ABANDONMENT") {
                                    commit('setLockedUserName', state.callbacks[0].input[0].value)
                                    router.push({ name: 'ResetPassword', params: { lockType: 'resetPassword' } })
                                } else {
                                    dispatch('pwdLessLogin_pushDenied', errorData.data)
                                    //pwdLessLogin_pushDenied(errorData.data)
                                }
                            } else {
                                dispatch('pwdLessLogin_pushDenied', errorData.data)
                                //pwdLessLogin_pushDenied(errorData.data)
                            }
                        }
                    }
                    throw err;
                });
            }, 2000); // milliseconds
        },
        pwdLessLogin_pushExpired({ commit, dispatch, state }, payload) {
            commit('setLoader', true)
            let option = state.options
            option.headers['X-VZ-FLOWTYPE'] = 'npush'
            let reqUrl = 'json/realms/root/realms/vzwmb/authenticate?authIndexType=service&authIndexValue=VBGPushService'
            return amrest.post(reqUrl, payload, option).then(function (res) {
                commit('setLoader', false)
            }).catch(function (err) {
                commit('setLoader', false)
                if (document.getElementById('notificationWait')) {
                    document.getElementById('notificationWait').setAttribute('style', 'display:none;');
                }
                if (document.getElementById('IAMerrorMessage')) {
                    document.getElementById('pushContainer').setAttribute('style', 'height:800px');
                    document.getElementById('errorIAMPush').setAttribute('style', 'display:flex');
                    document.getElementById("IAMerrorMessage").innerHTML = "The notification expired, you can request it again.";
                }
                if (document.getElementById("IAMinfoMessage")) {
                    document.getElementById('infoIAMPush').setAttribute('style', 'display:none');
                }
                throw err;
            });
        },
        pwdLessLogin_pushDenied({ commit, dispatch, state }, payload) {
            if (document.getElementById('notificationWait')) {
                document.getElementById('notificationWait').setAttribute('style', 'display:none;');
            }
            if (document.getElementById('IAMerrorMessage')) {
                document.getElementById('pushContainer').setAttribute('style', 'height:800px');
                document.getElementById('errorIAMPush').setAttribute('style', 'display:flex');
                document.getElementById("IAMerrorMessage").innerHTML = "<b>You denied the push notification.</b><p>Please try logging in another way.</p>";
            }
            if (document.getElementById("IAMinfoMessage")) {
                document.getElementById('infoIAMPush').setAttribute('style', 'display:none');
            }
        },
        pwdLogin_pushExpired({ commit, dispatch, state }, payload) {
            if (document.getElementById('notificationWait')) {
                document.getElementById('notificationWait').setAttribute('style', 'display:none;');
            }
            if (document.getElementById('IAMerrorMessage')) {
                // document.getElementById('pushContainer').setAttribute('style', 'height:800px');
                document.getElementById('errorIAMPush').setAttribute('style', 'display:flex');
                document.getElementById("IAMerrorMessage").setAttribute('style', 'padding-top: 2px');
                document.getElementById("IAMerrorMessage").innerHTML = "<b>The notification expired, you can request it again.</b>";
            }
            if (document.getElementById("IAMinfoMessage")) {
                document.getElementById('infoIAMPush').setAttribute('style', 'display:none');
            }
        },
        getHostName(url) {
            var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
            if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
                return match[2];
            } else {
                return null;
            }
        },
        getDomain(url) {
            var hostName = getHostName(url);
            var domain = hostName;

            if (hostName != null) {
                var parts = hostName.split('.').reverse();

                if (parts != null && parts.length > 1) {
                    domain = parts[1] + '.' + parts[0];

                    // if (hostName.toLowerCase().indexOf('.co.uk') != -1 && parts.length > 2) {
                    //   domain = parts[2] + '.' + domain;
                    // }
                }
            }

            return domain;
        },
        getAllowedCharacters(url) {
            let alphanum = /^[ A-Za-z0-9]+$/;
            let specialChar = ['$', '&', '+', ',', '/', ':', ';', '=', '?', '@', '-', '.', '_', '~', '#', '%', '!'];
            let invalidChar = false;
            for (let i = 0; i < url.length; i++) {
                if (!((url.charAt(i).match(alphanum)) || Includes(specialChar, url.charAt(i)))) {
                    invalidChar = true;
                    break;
                }
            }
            if (!invalidChar) {
                return url;
            } else {
                return null;
            }

        },
        sanitizeUrl(url) {
            var approvedUrls = iamvar_vzApprovedUrls;
            if (!url) {
                return null;
            }
            let approvedUrlFound = false;
            // allowing only alphanumeric and some special characters in a url
            let sanitizedUrl = getAllowedCharacters(url);
            if (sanitizedUrl) {
                // if the coming url begins with /, it means it is a redirection within the same domain
                if (sanitizedUrl.charAt(0) == "/") {
                    approvedUrlFound = true;
                } else {
                    let mainDomain = getDomain(sanitizedUrl);
                    for (let i = 0; i < approvedUrls.length; i++) {
                        if (mainDomain == approvedUrls[i]) {
                            approvedUrlFound = true;
                            break;
                        }
                    }
                }
                if (approvedUrlFound) {
                    return sanitizedUrl;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        },
        updateUrlOnMount({ dispatch, commit }, payload) {
            commit('setUrlOnMount', payload)
            dispatch('cvsstepup/updateUrlOnMount', payload, { root: true })
        },
        updateUserAgent({ dispatch, commit, state }, payload) {
            commit('setUserAgent', payload)
            dispatch('cvsstepup/updateUserAgent', payload, { root: true })
        },
        updatePwdAuthOptions({ dispatch, commit, state }, payload) {
            commit('setPwdAuthOptions', payload)
        },
        resetState({ state, dispatch, commit }) {
            state.authError = false
            state.submitLoader = false
            commit('setRedirectToStepupPage', null)
            commit('setRedirectToSecurityProfilePage', null)
            commit('setRedirectToOptionsPage', null)
            commit('setRedirectToPasswordPage', null)
            dispatch('cvsstepup/resetState', {}, { root: true })
            dispatch('forgotflow/resetState', {}, { root: true })
            commit('setClearFormFields', false)
            commit('setVpsUserIdAlreadyExist', false)
            commit('setVpsUserRegPasswordAlert', false)
        },
        removeExistingVBGCCookie() {
            for (var i = 0; i < iamvar_vbgcCookieDomain.length; i++) {
                //console.log(iamvar_vbgcCookieName +'=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain='+ iamvar_vbgcCookieDomain[i] + ';path=' + iamvar_vbgcCookiePath+ ';secure=' + iamvar_vbgcCookieSecure+';comment=deleting from ui '+iamvar_vbgcCookieDomain[i])
                document.cookie = iamvar_vbgcCookieName + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=' + iamvar_vbgcCookieDomain[i] + ';path=' + iamvar_vbgcCookiePath + ';secure=' + iamvar_vbgcCookieSecure + ';comment=deleting from ui'
            }
        },
        setCookieForBlueBanner({ commit }) {
            if (getCookie('unifiedBluebanner')) {
                commit('setUnifiedLoginAlerts', [{ type: 'information', msg: translations.en.message["new_business_login_info"] }])
            } else {
                let expirationDate = new Date('10/20/2022'); //Release date here
                expirationDate.setDate(expirationDate.getDate() + 30); // Set now + 30 days as the new date
                document.cookie = 'unifiedBluebanner=true;expires=' + expirationDate.toUTCString() + ';domain=' + location.hostname + ';path=' + iamvar_vbgcCookiePath + ';secure=' + iamvar_vbgcCookieSecure + ';comment=deleting from ui ' + location.hostname
                if (getCookie('unifiedBluebanner')) {
                    commit('setUnifiedLoginAlerts', [{ type: 'information', msg: translations.en.message["new_business_login_info"] }])
                }
            }
        },
        /* removeExistingAMSessionCookie({state, dispatch, commit}) {
            var cookieVal = getCookie(iamvar_amSessionCookieName)
            //console.log(cookieVal)
            if(cookieVal != "") {
                dispatch('getSessionInfo', cookieVal)  
            }  
        }, */
        fetchUserId({ state, commit }) {
            var amHeaders = state.options
            amHeaders.headers['Content-Type'] = 'application/json'
            if (state.initialUsername == null) {
                commit('setComingFromProfilePage', true);
                return amrest.post('sessionProperties', {}, amHeaders).then(function (response) {
                    commit('setInitialUsername', response.data.userName)
                    commit('setUserType', response.data.userType)
                }).catch((error) => {
                })
            } else {
                commit('setComingFromProfilePage', false);
            }
        },
        getSessionInfo({ state }) {
            var amHeaders = state.options
            amHeaders.headers['Content-Type'] = 'application/json'
            return amrest.post('sessionProperties', {}, amHeaders).then(function (response) {
                //console.log(response)
                // redirect the user to protected/default app
                let redirectUrl = ''
                let userType = response.data.userType

                // if(state.gotoUrl != null)
                //     window.location.href = state.gotoUrl
                // else if(userType != null) {
                //     if(userType === 'VZB')
                //         redirectUrl = iamvar_vecDefaultGotoUrl
                //     else if(userType === 'VZW')
                //         redirectUrl = iamvar_myBizDefaultGotoUrl
                //     else
                //         redirectUrl = iamvar_hybridDefaultGotoUrl

                //     window.location.href = redirectUrl
                // }
                if (state.gotoUrl === undefined || state.gotoUrl == "undefined" || state.gotoUrl === null || state.gotoUrl === "null" || state.gotoUrl === "") {
                    if (userType === 'VZB')
                        redirectUrl = iamvar_vecDefaultGotoUrl;
                    else if (userType === 'VZW')
                        redirectUrl = iamvar_myBizDefaultGotoUrl;
                    else
                        redirectUrl = iamvar_hybridDefaultGotoUrl;

                    // window.location.href = sanitizeUrl(redirectUrl);
                } else {
                    // window.location.href = sanitizeUrl(gotoUrl);
                    redirectUrl = state.gotoUrl;
                }
                window.location.href = mixin.methods.sanitizeUrl(redirectUrl)
            }).catch((error) => {
                // delete cookie
                for (var i = 0; i < iamvar_amSessionCookieDomain.length; i++) {
                    document.cookie = iamvar_amSessionCookieName + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=' + iamvar_amSessionCookieDomain[i] + ';path=' + iamvar_amSessionCookiePath
                }
            })
        },
        callRememberMethod({ state, commit }) {
            if (document.cookie.indexOf(iamvar_makeDefaultMethodName) !== -1) {
                let encryptedMethodVal = getCookie(iamvar_makeDefaultMethodName);
                if (encryptedMethodVal != "undefined") {
                    var payload = {
                        'encryptedUsername': encryptedMethodVal
                    };
                    return playrest.post(iamvar_remembermeServ + '/user/decryptuser', payload, akkaHeaders).then(function (response) {
                        var retrivedVal = response.data.username;
                        var frags = retrivedVal.split('|')
                        if (state.pwdUsername == frags[0]) {
                            state.optionMethod = frags[1];
                        }
                    }).catch(function (error) {
                        throw error;
                    })

                }
            }
        },
        callRememberMe({ state, commit }) {
            // if(remember me cookie present)
            if (document.cookie.indexOf(iamvar_vbgcRemCookieName) !== -1) {
                var encryptedval = getCookie(iamvar_vbgcRemCookieName)
                if (encryptedval != "undefined") {
                    var payload = {
                        'encryptedUsername': encryptedval
                    }
                    return playrest.post(iamvar_remembermeServ + '/user/decryptuser', payload, state.akkaHeaders).then(function (response) {
                        //state.callbacks[0].input[0].value = response.data.username
                        commit('setuserName', response.data.username)
                        commit('setRememberMe', 'setToTrue');
                    }).catch((error) => {
                        throw error
                    })
                }
            }
        },
        findRememberMeCookie({ state, commit }) {
            if (document.cookie.indexOf(iamvar_vzPushUserId) !== -1) {
                let pushUserIdval = getCookie(iamvar_vzPushUserId)
                //console.log("*******FOUND - PushUserId******", pushUserIdval);
                //console.log(pushUserIdval);
                if (pushUserIdval != "undefined") {
                    // state.callbacks[0].input[0].value = pushUserIdval
                    commit('setuserName', pushUserIdval)
                }
            }
            else if (document.cookie.indexOf(iamvar_vzLRME) !== -1) {
                let decryptedval = getCookie(iamvar_vzLRME)
                //console.log("********FOUND - LegacyRME***********", decryptedval);
                //console.log(decryptedval);
                if (decryptedval != "undefined") {
                    //state.callbacks[0].input[0].value = decryptedval
                    commit('setuserName', decryptedval)
                }
                commit('setRememberMe', 'setToTrue');
                //dispatch('encryptUsername', decryptedval)
            }
            // if(remember me cookie present)
            else if (document.cookie.indexOf(iamvar_vbgcRemCookieName) !== -1) {
                let encryptedval = getCookie(iamvar_vbgcRemCookieName)
                //console.log("********FOUND - vbgremc***********", encryptedval);
                //console.log(encryptedval);
                if (encryptedval != "undefined") {
                    var payload = {
                        'encryptedUsername': encryptedval
                    }
                    return playrest.post(iamvar_remembermeServ + '/user/decryptuser', payload, state.akkaHeaders).then(function (response) {
                        //state.callbacks[0].input[0].value = response.data.username
                        commit('setuserName', response.data.username)
                        commit('setRememberMe', 'setToTrue');
                        //dispatch('encryptUsername', response.data.username)
                    }).catch((error) => {
                        throw error
                    })
                }
            }
            else {
                //console.log("****NO PushUserID, LRME, RME Cookies Present *******");
            }
        },
        findPushUserIdCookie({ state, commit }) {
            if (document.cookie.indexOf(iamvar_vzPushUserId) !== -1) {
                let decryptedval = getCookie(iamvar_vzPushUserId)
                if (decryptedval != "undefined") {
                    state.callbacks[0].input[0].value = decryptedval
                }
            }
        },
        async getQuickLinksForMobile({ state, commit }) {
            if (document.cookie.indexOf('X-VZ-C_APPVERSION') !== -1) {
                var appVersion = getCookie('X-VZ-C_APPVERSION')
            }
            if (appVersion != "undefined") {
                var payload = {
                    'X-VZ-C_APPVERSION': appVersion
                }
            }

            return axios.post(iamvar_quickLinksWebviewContent, payload).then(function (response) {
                //state.mvbContent = response.data
                commit('setMvbContent', response.data)
            }).catch((error) => {
                let errorData = error.response
                let msg = '{"actionType":"info","desc":"webview.content::' + errorData.status + '"}';
                if (window.webkit != undefined) { // iOS
                    if (window.webkit.messageHandlers.mfAppInterface != undefined) {
                        window.webkit.messageHandlers.mfAppInterface.postMessage(msg);
                    }
                }
                if (window.mfAppInterface != undefined) { // Android
                    window.mfAppInterface.postMessage(msg);
                }
                throw error
            })
        },
        async getQuickLinksForVemaMobile({ state, commit }) {
            let vemaQuickLinks = {
                "resourceId": "loginContentScreen",
                "quickLinksLbl": "Quick Links",
                "quickLinks": [
                    {
                        "actionType": "native",
                        "disable": "false",
                        "title": "Pay without logging in",
                        "pageLink": "https://www.verizon.com/expresspay/#/auth?src=b"
                    },
                    {
                        "actionType": "browser",
                        "disable": "false",
                        "title": "Create a billing inquiry",
                        "pageLink": "https://enterpriseportal.verizon.com/public/index.html#/inquiries/create"
                    },
                    {
                        "actionType": "browser",
                        "disable": "false",
                        "title": "Check billing inquiry status",
                        "pageLink": "https://enterpriseportal.verizon.com/public/index.html#/inquiries/checkstatus"
                    }
                ]
            }
            commit('setMvbContent', vemaQuickLinks)
        },
        selectAccount({ commit, dispatch, state }, payload) {
            commit('setUserSelectedAccount', payload)
        },
        async smbFormSubmit({ commit, dispatch, state }, payload) {
            var f = document.createElement("form");
            f.setAttribute('method', "post");
            f.setAttribute('action', iamvar_vbm_login_redirect + '?realm=vzw&service=WlnOneVerizonChain&fromVZT=Y');
            f.setAttribute('id', "login-form");
            f.setAttribute('name', "loginForm");

            var i1 = document.createElement("input"); //input element, text
            i1.setAttribute('type', "hidden");
            i1.setAttribute('name', "_csrf");
            i1.setAttribute('value', "");

            var i2 = document.createElement("input"); //input element, text
            i2.setAttribute('type', "hidden");
            i2.setAttribute('name', "realm");
            i2.setAttribute('value', "vzw");

            var i3 = document.createElement("input"); //input element, text
            i3.setAttribute('type', "hidden");
            i3.setAttribute('name', "remainInOverlay");
            i3.setAttribute('value', "");

            var i4 = document.createElement("input"); //input element, text
            i4.setAttribute('type', "hidden");
            i4.setAttribute('name', "goto");
            if (state.gotoUrl == undefined || state.gotoUrl == null) {
                i4.setAttribute('value', iamvar_vbm_formsso + "?target=" + iamvar_vbm_redirection);
            } else {
                i4.setAttribute('value', iamvar_vbm_formsso + "?target=" + state.gotoUrl);
            }


            var i5 = document.createElement("input"); //input element, text
            i5.setAttribute('type', "hidden");
            i5.setAttribute('name', "gotoOnFail");
            i5.setAttribute('value', "");

            var i6 = document.createElement("input"); //input element, text
            i6.setAttribute('type', "hidden");
            i6.setAttribute('name', "incid");
            i6.setAttribute('value', "");

            var i7 = document.createElement("input"); //input element, text
            i7.setAttribute('type', "hidden");
            i7.setAttribute('name', "ccpaLogin");
            i7.setAttribute('value', "");

            var i8 = document.createElement("input"); //input element, text
            i8.setAttribute('type', "hidden");
            i8.setAttribute('name', "smAuthToken");
            i8.setAttribute('value', "");

            var i9 = document.createElement("input"); //input element, text
            i9.setAttribute('type', "hidden");
            i9.setAttribute('name', "IDToken1");
            i9.setAttribute('value', payload.username);

            var i10 = document.createElement("input"); //input element, text
            i10.setAttribute('type', "hidden");
            i10.setAttribute('name', "IDToken2");
            i10.setAttribute('value', payload.password);

            var i11 = document.createElement("input"); //input element, text
            i11.setAttribute('type', "hidden");
            i11.setAttribute('name', "userNameOnly");
            i11.setAttribute('value', "false");

            var i12 = document.createElement("input"); //input element, text
            i12.setAttribute('type', "hidden");
            i12.setAttribute('name', "rememberUserNameCheckBoxExists");
            i12.setAttribute('value', "Y");

            var i13 = document.createElement("input"); //input element, text
            i13.setAttribute('type', "hidden");
            i13.setAttribute('name', "customerType");
            i13.setAttribute('value', "");

            var i14 = document.createElement("input"); //input element, text
            i14.setAttribute('type', "hidden");
            i14.setAttribute('name', "clientId");
            i14.setAttribute('value', "hybridLogin");
            // i14.setAttribute('value',"oneportal");

            var i15 = document.createElement("input"); //input element, text
            i15.setAttribute('type', "hidden");
            i15.setAttribute('name', "fromVZT");
            i15.setAttribute('value', "Y");

            var i16 = document.createElement("input"); //input element, text
            i16.setAttribute('type', "hidden");
            i16.setAttribute('name', "athM");
            i16.setAttribute('value', "");

            var i17 = document.createElement("input"); //input element, text
            i17.setAttribute('type', "hidden");
            i17.setAttribute('name', "athMStatus");
            i17.setAttribute('value', "");

            f.appendChild(i1);
            f.appendChild(i2);
            f.appendChild(i3);
            f.appendChild(i4);
            f.appendChild(i5);
            f.appendChild(i6);
            f.appendChild(i7);
            f.appendChild(i8);
            f.appendChild(i9);
            f.appendChild(i10);
            f.appendChild(i11);
            f.appendChild(i12);
            f.appendChild(i13);
            f.appendChild(i14);
            f.appendChild(i15);
            f.appendChild(i16);
            f.appendChild(i17);

            if (payload.rememberUser) {
                var i18 = document.createElement("input"); //input element, text
                i18.setAttribute('type', "hidden");
                i18.setAttribute('name', "rememberUserName");
                i18.setAttribute('value', "Y");
                f.appendChild(i18);
                await dispatch('encryptUsername', payload.username)
            }
            document.body.appendChild(f);
            //console.log("f...",f,document.getElementById("login-form"))
            document.getElementById("login-form").submit();
        },
        submitAMLoginForm({ commit, dispatch, state }, payload) {
            //console.log("payload in 1st auth..",payload)
            // logger.info('User tries to authenticate')
            // logger.http('Get username and password callback')
            const parseHttpHeaders = (httpHeaders) => {
                return httpHeaders.split("\n")
                    .map(x => x.split(/: */, 2))
                    .filter(x => x[0])
                    .reduce((ac, x) => { ac[x[0]] = x[1]; return ac; }, {});
            }
            var req = new XMLHttpRequest();
            req.open('GET', document.location, false);
            req.send(null);
            var headers = parseHttpHeaders(req.getAllResponseHeaders());
            localStorage.setItem('X-Vz-Gsa', headers["x-vz-gsa"])
            commit('setLoader', true)
            dispatch('cvsstepup/updateFlowType', 'auth', { root: true })

            // send federal_portal header if instance is related to a federal portal. Otherwise, send captcha header
            if (state.federalPortal) {
                state.options.headers.federal_portal = true;
            } else if (state.captchaToken != null) {
                state.options.headers.captcha = state.captchaToken;
            }
            // If user select MyBiz/VEC on unifiedportal, making "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
            let option = state.options
            if (payload.additionalAuth) {
                option.headers['X-VZ-AUTH-HYBRID'] = payload.additionalAuth
            } else {
                if (option.headers['X-VZ-AUTH-HYBRID']) {
                    let hybridHeader = 'X-VZ-AUTH-HYBRID'
                    // option.headers.delete('X-VZ-AUTH-HYBRID')
                    delete option.headers[hybridHeader]
                }
            }
            if(payload.additionalVcgAuth) {
                option.headers['X-VZ-SELECTED-ACCOUNT'] = payload.additionalVcgAuth
            }
            if (payload.captchaAuth) {
                option.headers['X-VZ-ILOGIN'] = payload.captchaAuth
            } else {
                if (option.headers['X-VZ-ILOGIN']) {
                    let internationalHeader = 'X-VZ-ILOGIN'
                    delete option.headers[internationalHeader]
                }
            }

            return amrest.post('json/realms/vzwmb/authenticate?authIndexType=service&authIndexValue=VBGUserService', {}, option).then(function (response) {
                var data = response.data
                var username = payload.formData[0].input[0].value.trim()
                var password = payload.formData[1].input[0].value.trim()
                payload.formData[0].input[0].value = username
                payload.formData[1].input[0].value = password
                commit('setInitialUsername', username)
                data.callbacks = payload.formData
                commit('setAMToken', data.authId)
                var newPayload = {
                    'data': data,
                    'rememberUser': payload.rememberUser
                }
                //console.log("newPayload sending iss...",newPayload)
                // If user select MyBiz/VEC on unifiedportal, passing additionalAuth parameter to second "authenticate" call.
                if (payload.additionalAuth) {
                    newPayload['additionalAuth'] = payload.additionalAuth
                }
                if (payload.captchaAuth) {
                    newPayload['captchaAuth'] = payload.captchaAuth
                }
                // dispatch('sanitizePasswordForErrorLogging', newPayload.data)
                dispatch('authenticateUser', newPayload)
            }).catch((error) => {
                let errorData = error.response

                if (errorData.status == 417) {
                    if (errorData.data) {
                        // document.write(errorData.data);
                        if (docHTML) {
                            docHTML[0].innerHTML = errorData.data;
                        }
                    }
                    else {
                        commit('setAlertType', 'error')
                        commit('setAuthError', { errorMessage: translations.en.message['bckend_unknown_err'] })
                        commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: '<b>An error occurred.</b>' }])
                        errorSet('An error occurred.');
                    }
                } else if (errorData.status == 409) {
                    //commit('setAuthError', { errorMessage: translations.en.message['internationallogin_flow_jcaptcha_invalid'] })
                    commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['internationallogin_flow_jcaptcha_invalid'] }])
                    errorSet('The characters you have entered are incorrectly formatted.');
                    commit('setClearFormFields', true);
                } else {
                    // TODO: check for other acceptable error codes
                    commit('setAlertType', 'error')
                    commit('setAuthError', { errorMessage: translations.en.message['bckend_unknown_err'] })
                    commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: '<b>An error occurred.</b>' }])
                    errorSet('An error occurred.');

                    // log unhandled error
                    let requestObject = { reqUrl: reqUrl, reqBody: state.sanitizedAuthenticationPayload, reqHeaders: state.options }
                    let explanation = "This call should return 200 in order to proceed with submitting the login form"

                    let unhandledErrObj = { request: requestObject, response: error, explanation: explanation }

                    dispatch('sendUnhandledErrorToBackend', unhandledErrObj)
                }
                // logger.error('Error in getting username and password callback. '+ error)
                commit('setLoader', false)

                throw error
            })
        },
        submitRegisterForm({commit, dispatch, state}, payload) {
            delete payload.confirmPassword;
            return ifgrest.post('ifgservices/vbg/iamservices/api/pre-auth/vps/registration', payload).then(function (response) {
                if(response.data.responseCode == '00') {
                    router.push({ name: 'VPSRegistrationSuccessful' });
                }
                commit('setLoader', false)
            }).catch((error) => {
                let errorData = error.response

                if (errorData.status == 417) {
                    if (errorData.data) {
                        // document.write(errorData.data);
                        if (docHTML) {
                            docHTML[0].innerHTML = errorData.data;
                        }
                    }
                    else {
                        commit('setAlertType', 'error')
                        commit('setAuthError', { errorMessage: translations.en.message['bckend_unknown_err'] })
                        commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: '<b>An error occurred.</b>' }])
                        errorSet('An error occurred.');
                    }
                } else if (errorData.status == 409) {
                    //commit('setAuthError', { errorMessage: translations.en.message['internationallogin_flow_jcaptcha_invalid'] })
                    commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['internationallogin_flow_jcaptcha_invalid'] }])
                    errorSet('The characters you have entered are incorrectly formatted.');
                } else if( errorData.status == 400){
                    if(errorData?.data === "USER_EXISTS") {
                        commit('setVpsUserIdAlreadyExist', true)
                    }
                    if (errorData?.data?.responseCode == 29 && errorData?.data?.responseMessage == "Invalid_Password" ) {
                        commit('setVpsUserRegPasswordAlert', true)
                    }
                }
                else {
                    // TODO: check for other acceptable error codes
                    commit('setAlertType', 'error')
                    commit('setAuthError', { errorMessage: translations.en.message['bckend_unknown_err'] })
                    commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: '<b>An error occurred.</b>' }])
                    errorSet('An error occurred.');


                }
                // logger.error('Error in getting username and password callback. '+ error)
                commit('setLoader', false)

                throw error
            })
        },
        submitPwdAMPasswordForm({ commit, dispatch, state }, payload) {
            // logger.info('User tries to authenticate')
            // logger.http('Get username and password callback')
            const parseHttpHeaders = (httpHeaders) => {
                return httpHeaders.split("\n")
                    .map(x => x.split(/: */, 2))
                    .filter(x => x[0])
                    .reduce((ac, x) => { ac[x[0]] = x[1]; return ac; }, {});
            }
            var req = new XMLHttpRequest();
            req.open('GET', document.location, false);
            req.send(null);
            var headers = parseHttpHeaders(req.getAllResponseHeaders());
            localStorage.setItem('X-Vz-Gsa', headers["x-vz-gsa"])
            let option = state.options
            let newPayload = '' 
            // this is for vcg vb migration for new registration
            if(payload && payload.encrpytedRegTok) {
                option.headers['X-VZ-AUTH-REG'] = 'Y'
                commit('setVcgSubmitLoader', true)
            } else {
                commit('setLoader', true)
                dispatch('cvsstepup/updateFlowType', 'auth', { root: true })

                // send federal_portal header if instance is related to a federal portal. Otherwise, send captcha header
                if (state.federalPortal) {
                    state.options.headers.federal_portal = true;
                } else if (state.captchaToken != null) {
                    state.options.headers.captcha = state.captchaToken;
                }
                // If user select MyBiz/VEC on unifiedportal, making "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
                if (payload.additionalAuth) {
                    option.headers['X-VZ-AUTH-HYBRID'] = payload.additionalAuth
                } else {
                    if (option.headers['X-VZ-AUTH-HYBRID']) {
                        let hybridHeader = 'X-VZ-AUTH-HYBRID'
                        // option.headers.delete('X-VZ-AUTH-HYBRID')
                        delete option.headers[hybridHeader]
                    }
                }
                if(payload.additionalVcgAuth) {
                    option.headers['X-VZ-SELECTED-ACCOUNT'] = payload.additionalVcgAuth
                }
                if (payload.captchaAuth) {
                    option.headers['X-VZ-ILOGIN'] = payload.captchaAuth
                } else {
                    if (option.headers['X-VZ-ILOGIN']) {
                        let internationalHeader = 'X-VZ-ILOGIN'
                        delete option.headers[internationalHeader]
                    }
                }
            }
            return amrest.post('json/realms/vzwmb/authenticate?authIndexType=service&authIndexValue=VBGUserService', {}, option).then(function (response) {
                var data = response.data
                // this is for vcg vb migration for new registration pass empty password and username as token in 5th auth call
                if(payload && payload.encrpytedRegTok) {
                    data.callbacks[0].input[0].value = payload.encrpytedRegTok
                    newPayload = {
                        data: data,
                        encrpytedRegTok: payload.encrpytedRegTok
                    }
                } else {
                    var username = state.pwdUsername || getCookie("pwdUserName")
                    var password = payload.formData[1].input[0].value.trim()
                    //state.callbacks = payload.formData
                    payload.formData[0].input[0].value = username
                    payload.formData[1].input[0].value = password
                    commit('setPwdPassWord', password)
                    //setCookie('pwdPassWord', password)
                    commit('setInitialUsername', username)
                    data.callbacks = payload.formData
                    commit('setAMToken', data.authId)
                    newPayload = {
                        'data': data,
                        'rememberUser': payload.rememberUser,
                        'rememberMethod': payload.rememberMethod,
                        'usernameOption': payload.usernameOption
                    }
                    //console.log("newPayload sending iss...",newPayload)
                    // If user select MyBiz/VEC on unifiedportal, passing additionalAuth parameter to second "authenticate" call.
                    if (payload.additionalAuth) {
                        newPayload['additionalAuth'] = payload.additionalAuth
                    }
                    if (payload.captchaAuth) {
                        newPayload['captchaAuth'] = payload.captchaAuth
                    }
                    // dispatch('sanitizePasswordForErrorLogging', newPayload.data)
                    commit('setLoader', false)
                }
                dispatch('authenticatePasswordUser', newPayload)
            }).catch((error) => {
                let errorData = error.response

                if (errorData.status == 417) {
                    if (errorData.data) {
                        // document.write(errorData.data);
                        if (docHTML) {
                            docHTML[0].innerHTML = errorData.data;
                        }
                    }
                    else {
                        commit('setAlertType', 'error')
                        commit('setAuthError', { errorMessage: translations.en.message['bckend_unknown_err'] })
                        commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: '<b>An error occurred.</b>' }])
                        errorSet('An error occurred.');
                    }
                } else if (errorData.status == 409) {
                    //commit('setAuthError', { errorMessage: translations.en.message['internationallogin_flow_jcaptcha_invalid'] })
                    commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['internationallogin_flow_jcaptcha_invalid'] }])
                    errorSet('The characters you have entered are incorrectly formatted.');
                } else {
                    // TODO: check for other acceptable error codes
                    commit('setAlertType', 'error')
                    commit('setAuthError', { errorMessage: translations.en.message['bckend_unknown_err'] })
                    commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: '<b>An error occurred.</b>' }])
                    errorSet('An error occurred.');


                }
                // logger.error('Error in getting username and password callback. '+ error)
                commit('setLoader', false)
                commit('setVcgSubmitLoader', false)
                throw error
            })
        },
        submitPwdAMLoginForm({ commit, dispatch, state }, payload) {

            // logger.info('User tries to authenticate')
            // logger.http('Get username and password callback')
            const parseHttpHeaders = (httpHeaders) => {
                return httpHeaders.split("\n")
                    .map(x => x.split(/: */, 2))
                    .filter(x => x[0])
                    .reduce((ac, x) => { ac[x[0]] = x[1]; return ac; }, {});
            }
            var req = new XMLHttpRequest();
            req.open('GET', document.location, false);
            req.send(null);
            var headers = parseHttpHeaders(req.getAllResponseHeaders());
            localStorage.setItem('X-Vz-Gsa', headers["x-vz-gsa"])
           // commit('setLoader', true)
            dispatch('cvsstepup/updateFlowType', 'auth', { root: true })
            commit('setLoader', true)
            // send federal_portal header if instance is related to a federal portal. Otherwise, send captcha header
            if (state.federalPortal) {
                state.options.headers.federal_portal = true;
            } else if (state.captchaToken != null) {
                state.options.headers.captcha = state.captchaToken;
            }
            // If user select MyBiz/VEC on unifiedportal, making "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
            let option = state.options
            if(payload.additionalVcgAuth) {
                option.headers['X-VZ-SELECTED-ACCOUNT'] = payload.additionalVcgAuth
            }

            return amrest.post('json/realms/root/realms/vzwmb/authenticate?authIndexType=service&client_id=pwll&authIndexValue=VBGUserValidateService', {}, option).then(function (response) {
                var data = response.data
                var username = payload.formData[0].input[0].value.trim()

                payload.formData[0].input[0].value = username
                commit('setPwdUsername', username)
                //setCookie('pwdUserName', username)
                commit('setInitialUsername', username)
                data.callbacks[0].input[0].value = username //payload.formData
                //data.callbacks = payload.formData
                commit('setAMToken', data.authId)
                dispatch('callRememberMethod')
                let newPayload;
                if(payload.isVPSFlow) {
                    newPayload = {
                        'data': data,
                        'rememberUser': payload.rememberUser,
                        'isVPSFlow': payload.isVPSFlow
                    }
                } else {
                    newPayload = {
                        'data': data,
                        'rememberUser': payload.rememberUser
                    }
                }
                // dispatch('sanitizePasswordForErrorLogging', newPayload.data)
                dispatch('authenticatePwdUser', newPayload)
            }).catch((error) => {
                commit('setLoader', false)
                let errorData = error.response
                if (errorData.status == 417) {
                    if (errorData.data) {
                        // document.write(errorData.data);
                        if (docHTML) {
                            docHTML[0].innerHTML = errorData.data;
                        }
                        commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: errorData.data }])
                    }
                    else {
                        commit('setAlertType', 'error')
                        commit('setAuthError', { errorMessage: translations.en.message['bckend_unknown_err'] })
                        commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: '<b>An error occurred.</b>' }])
                        errorSet('An error occurred.');
                    }
                } else if (errorData.status == 409) {
                    //commit('setAuthError', { errorMessage: translations.en.message['internationallogin_flow_jcaptcha_invalid'] })
                    commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['internationallogin_flow_jcaptcha_invalid'] }])
                    errorSet('The characters you have entered are incorrectly formatted.');
                } else {
                    // TODO: check for other acceptable error codes
                    commit('setAlertType', 'error')
                    commit('setAuthError', { errorMessage: translations.en.message['bckend_unknown_err'] })
                    commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: '<b>An error occurred.</b>' }])
                    errorSet('An error occurred.');


                }
                // logger.error('Error in getting username and password callback. '+ error)
                commit('setLoader', false)

                throw error
            })
        },
        submitAMMobileLoginForm({ commit, dispatch, state }, payload) {
            // logger.info('User tries to authenticate')
            // logger.http('Get username and password callback')
            commit('setLoader', true)
            dispatch('cvsstepup/updateFlowType', 'auth', { root: true })

            // send federal_portal header if instance is related to a federal portal. Otherwise, send captcha header
            if (state.federalPortal) {
                state.options.headers.federal_portal = true;
            } else if (state.captchaToken != null) {
                state.options.headers.captcha = state.captchaToken;
            }
            // If user select MyBiz/VEC on unifiedportal, making "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
            let option = state.options
            if (payload.additionalAuth) {
                option.headers['X-VZ-AUTH-HYBRID'] = payload.additionalAuth
            } else {
                if (option.headers['X-VZ-AUTH-HYBRID']) {
                    let hybridHeader = 'X-VZ-AUTH-HYBRID'
                    // option.headers.delete('X-VZ-AUTH-HYBRID')
                    delete option.headers[hybridHeader]
                }
            }
            if (payload.captchaAuth) {
                option.headers['X-VZ-ILOGIN'] = payload.captchaAuth
            } else {
                if (option.headers['X_VZ_ILOGIN']) {
                    let internationalHeader = 'X-VZ-ILOGIN'
                    delete option.headers[internationalHeader]
                }
            }

            if (document.cookie.indexOf(iamvar_vzPushUserId) !== -1) {
                let decryptedval = getCookie(iamvar_vzPushUserId)
                if (payload.formData[0].input[0].value.trim() == decryptedval) {
                    option.headers['X-VZ-PUSH_INT'] = getCookie(iamvar_vzPushInt)
                }
                else {
                    document.cookie = "X-VZ-PUSH_INT=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                }
            }

            return amrest.post('json/realms/mobile/authenticate?authIndexType=service&authIndexValue=userservice', {}, option).then(function (response) {
                var data = response.data
                var username = payload.formData[0].input[0].value.trim()
                var password = payload.formData[1].input[0].value.trim()
                payload.formData[0].input[0].value = username
                payload.formData[1].input[0].value = password
                commit('setInitialUsername', username)
                data.callbacks = payload.formData
                commit('setAMToken', data.authId)
                var newPayload = {
                    'data': data,
                    'rememberUser': payload.rememberUser
                }
                //console.log("newPayload sending iss...",newPayload)
                // If user select MyBiz/VEC on unifiedportal, passing additionalAuth parameter to second "authenticate" call.
                if (payload.additionalAuth) {
                    newPayload['additionalAuth'] = payload.additionalAuth
                }
                if (payload.captchaAuth) {
                    newPayload['captchaAuth'] = payload.captchaAuth
                }

                if (payload.rememberUser) {
                    dispatch('encryptUsername', username)
                }
                else {
                    //delete vbgremc cookie if the remember me check box is unchecked
                    document.cookie = iamvar_vbgcRemCookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                }

                // dispatch('sanitizePasswordForErrorLogging', newPayload.data)
                dispatch('authenticateMobileUser', newPayload)
                //commit('setLoader', false)
            }).catch((error) => {
                let errorData = error.response

                if (errorData.status == 417) {
                    //commit('setAuthError', { errorMessage: translations.en.message['login_error_goback_login'] })
                    //errorSet('Sorry we are not able to process your request at this time. Please Click Here to Go Back To The Login Page: Back to Login Page.'); 
                    // document.write(errorData.data);
                    if (docHTML) {
                        docHTML[0].innerHTML = errorData.data;
                    }
                } else if (errorData.status == 409) {
                    //commit('setAuthError', { errorMessage: translations.en.message['internationallogin_flow_jcaptcha_invalid'] })
                    commit('setUnifiedLoginAlerts', { errorMessage: translations.en.message['internationallogin_flow_jcaptcha_invalid'] })
                    errorSet('The characters you have entered are incorrectly formatted.');
                } else {
                    commit('setAlertType', 'error')
                    // TODO: check for other acceptable error codes
                    commit('setAuthError', { errorMessage: translations.en.message['bckend_unknown_err'] })
                    commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: '<b>An error occurred.</b>' }])
                    errorSet('An error occurred.');

                    // log unhandled error
                    let requestObject = { reqUrl: reqUrl, reqBody: state.sanitizedAuthenticationPayload, reqHeaders: state.options }
                    let explanation = "This call should return 200 in order to proceed with submitting the login form"

                    let unhandledErrObj = { request: requestObject, response: error, explanation: explanation }

                    dispatch('sendUnhandledErrorToBackend', unhandledErrObj)
                }
                // logger.error('Error in getting username and password callback. '+ error)
                commit('setLoader', false)

                throw error
            })
        },
        submitVbmsAccountLinkForm({ commit, dispatch, state }, payload) {
            //console.log("payload in 1st auth..",payload)
            // logger.info('User tries to authenticate')
            // logger.http('Get username and password callback')
            const parseHttpHeaders = (httpHeaders) => {
                return httpHeaders.split("\n")
                    .map(x => x.split(/: */, 2))
                    .filter(x => x[0])
                    .reduce((ac, x) => { ac[x[0]] = x[1]; return ac; }, {});
            }
            var req = new XMLHttpRequest();
            req.open('GET', document.location, false);
            req.send(null);
            var headers = parseHttpHeaders(req.getAllResponseHeaders());
            localStorage.setItem('X-Vz-Gsa', headers["x-vz-gsa"])
            commit('setVcgSubmitLoader', true)
            let option = state.options
            return amrest.post('json/realms/vzwmb/authenticate?authIndexType=service&authIndexValue=VCGTokenGeneratorService', {}, option).then(function (response) {
                var data = response.data
                data.callbacks[0].input[0].value = payload
              //  commit('setAMToken', data.authId)
                var newPayload = {
                    'data': data
                }
                dispatch('authenticateVbmsAccountlinkUser', newPayload)
            }).catch((error) => {
                commit('setAlertType', 'error')
                commit('setAuthError', { errorMessage: translations.en.message['bckend_unknown_err'] })
                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: '<b>An error occurred.</b>' }])
                errorSet('An error occurred.');
                commit('setVcgSubmitLoader', false)
                throw error
            })
        },
        updateFormData({ commit }, payload) {
            commit('setFormData', payload)
        },
        updateGotoUrl({ commit, dispatch }, payload) {
            commit('setGotoUrl', payload)
            dispatch('cvsstepup/updateGotoUrl', payload, { root: true })
        },
        updateCaptchaToken({ commit }, payload) {
            commit('setCaptchaToken', payload)
        },
        updateMvbContent({ commit }, payload) {
            commit('setMvbContent', payload)
        },
        updateEncodedGotoUrl({ commit }, payload) {
            if (payload != null) {
                let encodedUrl = encodeURIComponent(payload)
                commit('setEncodedGotoUrl', encodedUrl)
            }
        },
        updateFederalPortalIndicator({ commit }, payload) {
            commit('setFederalPortal', payload)
        },
        encryptUsername({ state }, payload) {
            //delete old vbgremc cookie before you set new cookie value
            document.cookie = iamvar_vbgcRemCookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            var newPayload = {
                'username': payload
            }
            return playrest.post(iamvar_remembermeServ + '/user/encryptuser', newPayload, state.akkaHeaders).then(function (resp) {
                var encrUserNameVal = resp.data[iamvar_authRemEncrHeaderName]
                var expiration_date = new Date()
                expiration_date.setFullYear(expiration_date.getFullYear() + iamvar_vbgcRemCookieExpiryInYears)
                for (var i = 0; i < iamvar_vbgcRemCookieDomain.length; i++) {
                    document.cookie = iamvar_vbgcRemCookieName + '=' + encrUserNameVal + ';expires=' + expiration_date.toUTCString() + ';domain=' + iamvar_vbgcRemCookieDomain[i] + ';path=' + iamvar_vbgcRemCookiePath + ';secure=' + iamvar_vbgcRemCookieSecure + ';comment=setting in ui'
                }
            }).catch((error) => {
                //console.log(error)
            })
        },
        authenticateUser({ commit, dispatch, state }, payload) {
            //console.log("payload in 2nd auth..",payload)
            var username = payload.data.callbacks[0].input[0].value
            var password = payload.data.callbacks[1].input[0].value
            var reqUrl = 'json/realms/vzwmb/authenticate?authIndexType=service&authIndexValue=VBGUserService&goto=' + state.gotoUrl

            dispatch('sanitizePasswordForErrorLogging', payload.data)
            // If user select MyBiz/VEC on unifiedportal, making "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
            let option = state.options
            if (payload.additionalAuth) {
                option.headers['X-VZ-AUTH-HYBRID'] = payload.additionalAuth
            }
            if (payload.captchaAuth) {
                option.headers['X-VZ-ILOGIN'] = option.captchaAuth
            }
            // encoding username and password to base64 
            // let payloadEnc = JSON.parse(JSON.stringify(payload.data));
            // payloadEnc.callbacks[0].input[0].value = btoa(payloadEnc.callbacks[0].input[0].value);
            // payloadEnc.callbacks[1].input[0].value = btoa(payloadEnc.callbacks[1].input[0].value);
            return amrest.post(reqUrl, payload.data, option).then(function (response) {
                var authResponse = response.data
                // Set remember me cookie only if the user has checked the remember me check box
                if (payload.rememberUser) {
                    dispatch('encryptUsername', username)
                }
                var deviceFingerprint = getDeviceFingerprintInfoWithoutGeolocation()
                authResponse.callbacks[0].input[0].value = deviceFingerprint.value
                dispatch('sendDeviceFingerprint', authResponse)
            }).catch((error) => {
                commit('setLoader', false)
                var errorData = error.response
                var eventPayload = {};

                if (errorData?.data?.reason == "Unauthorized") {
                    commit('setClearFormFields', true)
                }
                if (errorData.status == 417) {
                    if (errorData.data) {
                        // document.write(errorData.data);
                        if (docHTML) {
                            docHTML[0].innerHTML = errorData.data;
                        }
                    }
                    else {
                        commit('setAlertType', 'error')
                        commit('setAuthError', { errorMessage: translations.en.message['bckend_unknown_err'] })
                        commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: '<b>An error occurred.</b>' }])
                        errorSet('An error occurred.');
                    }
                }

                if (errorData) {
                    if (errorData.headers[iamvar_authErrorHeaderName]) {
                        // logger.warn('User entered incorrect credentials')
                        var errorStatus = JSON.parse(errorData.headers[iamvar_authErrorHeaderName])
                        dispatch('updateLineOfBusiness', errorData.headers['x-fram-optionss'])
                        if( (errorData.headers[iamvar_Vcg_Throttled_On])) {
                            dispatch('updateVcgThrotteledOn', true)
                        }
                        if (errorStatus.authStatus == false) {
                            /* if(errorStatus.errorCode == 100)
                                commit('setAuthError', {errorMessage: "One or more of the values you have entered is incorrect.  Please verify your login information and try again."})
                            else if (errorStatus.errorCode == 101)
                                commit('setAuthError', {errorMessage: "Your account has been locked due to multiple incorrect attempts. Please contact customer service."})
                            else if(errorStatus.errorCode === 102)
                                commit('setAuthError', {errorMessage: "An internal server error occured. Please try again."})
                            else
                                commit('setAuthError', {errorMessage: "An error has occurred"}) */

                            if (errorStatus.errorCode === "INVALID_CREDENTIALS") {
                                // INVALID_CREDENTIALS  
                                let hostUrl = window.location.href
                                if (hostUrl.includes('/account/business/odasignin')) {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_mobile_login_lock_prevention'] })
                                    errorSet('If you are having trouble logging in visit sso.verizonenterprise.com from a device that is already connected to the internet and select Forgot username or password. If you do not have a My Business login, please contact your administrator for assistance.');
                                } else {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_invalid_creds'] })
                                    commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_error_invalid_creds'] }])
                                    errorSet('One or more of the values you have entered is incorrect.Please verify your login information and try again.');
                                }
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_PRE_PREVENTION") {
                                //for 3rd invalid attempt in unified exp
                                let hostUrl = window.location.href
                                if (hostUrl.includes('/account/business/odasignin')) {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_mobile_login_lock_prevention'] })
                                    errorSet('If you are having trouble logging in visit sso.verizonenterprise.com from a device that is already connected to the internet and select Forgot username or password. If you do not have a My Business login, please contact your administrator for assistance.');
                                } else {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_invalid_creds'] })
                                    commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_error_login_lock_pre_prevention'] }])
                                    errorSet('One or more of the values you have entered is incorrect.To reset, try Forgot user ID or Forgot password.');
                                }
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_PREVENTION") {
                                //for 4th invalid attempt in unified exp
                                const hostUrl = window.location.href
                                if (hostUrl.includes('/account/business/odasignin')) {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_mobile_login_lock_prevention'] })
                                    errorSet('If you are having trouble logging in visit sso.verizonenterprise.com from a device that is already connected to the internet and select Forgot username or password. If you do not have a My Business login, please contact your administrator for assistance.');
                                } else {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_login_lock_prevention'] })
                                    commit('setUnifiedLoginAlerts', [{ type: 'newWarning', msg: translations.en.message['am_unified_error_login_lock_prevention'] }])
                                    errorSet('One or more of the values you have entered is incorrect.To reset, try Forgot user ID or Forgot password.');
                                    commit('setUnifiedErrorCode', errorStatus.errorCode);
                                }
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_INACTIVE_AUTH_STEPS_REQUIRED") {
                                commit('setAuthError', { errorMessage: "The User ID is unable to perform the necessary steps at this time. Please contact one of the following for further assistance:<ul><li> • Company's Administrator</li><li> • Verizon Customer Service at 1-800-922-0204</li><li> • WITS/ Service@once users only: [800 381 3444|tel:8003813444] option 6 </li><li> • US and Canada Wireline Customers only: 800.569.8799 (M-F 9AM-6PM ET) Live Chat (Sun 8pm- Sat 8pm ET) </li><li> • EMEA, APAC, LATAM Wireline Customers only: 00.800.4321.5432 (M-F 9AM-5PM GMT) vec-support@verizon.com </li>" })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: "<b>The User ID is unable to perform the necessary steps at this time. Please contact one of the following for further assistance:<ul><li> • Company's Administrator</li><li> • Verizon Customer Service at 1-800-922-0204</li><li> • WITS/ Service@once users only: [800 381 3444|tel:8003813444] option 6 </li><li> • US and Canada Wireline Customers only: 800.569.8799 (M-F 9AM-6PM ET) Live Chat (Sun 8pm- Sat 8pm ET) </li><li> • EMEA, APAC, LATAM Wireline Customers only: 00.800.4321.5432 (M-F 9AM-5PM GMT) vec-support@verizon.com </li></b>" }])
                                errorSet("The User ID is unable to perform the necessary steps at this time. Please contact one of the following for further assistance:<ul><li> • Company's Administrator</li><li> • Verizon Customer Service at 1-800-922-0204</li><li> • WITS/ Service@once users only: [800 381 3444|tel:8003813444] option 6 </li><li> • US and Canada Wireline Customers only: 800.569.8799 (M-F 9AM-6PM ET) Live Chat (Sun 8pm- Sat 8pm ET) </li><li> • EMEA, APAC, LATAM Wireline Customers only: 00.800.4321.5432 (M-F 9AM-5PM GMT) vec-support@verizon.com </li>")
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_LOCKED") {
                                //for 5th invalid attempt in unified exp
                                const hostUrl = window.location.href
                                if (hostUrl.includes('/account/business/odasignin')) {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_mobile_login_locked'] })
                                    errorSet('Your account is locked. Visit sso.verizonenterprise.com from a device that is already connected to the internet or contact your administrator for assistance.');
                                } else {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', false)
                                    commit('setUnifiedLoginAlerts', [])
                                    errorSet('Your account is locked.To reset, try Forgot password or try our support page.');
                                    commit('setUnifiedErrorCode', errorStatus.errorCode);
                                    commit('setLockedUserName', state.callbacks[0].input[0].value)
                                    var userTypeOptions = errorData.headers['x-fram-optionss'];
                                    dispatch('redirectAccountLockedUser', userTypeOptions)
                                }
                            }
                            else if (errorStatus.errorCode === "INVALID_CREDENTIALS_PWD_EXPIRED") { // INVALID_CREDENTIALS_PWD_EXPIRED_ERROR_CODE
                                let hostUrl = window.location.href
                                if (hostUrl.includes('/account/business/odasignin')) {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_mobile_login_locked'] })
                                    errorSet('Your account is locked. Visit sso.verizonenterprise.com from a device that is already connected to the internet or contact your administrator for assistance.');
                                } else {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', false)
                                    commit('setUnifiedLoginAlerts', [])
                                    errorSet('Inactive or Expired User ID/Password.Please select the Forgot Password link below to reactivate your User ID and reset your password.');
                                    commit('setLockedUserName', state.callbacks[0].input[0].value)
                                    var userTypeOptions = errorData.headers['x-fram-optionss'];
                                    dispatch('redirectAccountLockedUser', userTypeOptions)
                                }
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_NEWLY_LOGIN_LOCKED") // for ACCOUNT_NEWLY_LOGIN_LOCKED user should redirect to forgot password
                            {
                                let url = window.location.href
                                if (url.includes('/account/business/odasignin')) {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_mobile_login_locked'] })
                                    errorSet('Your account is locked. Visit sso.verizonenterprise.com from a device that is already connected to the internet or contact your administrator for assistance.');
                                } else {
                                    commit('setAuthError', false)
                                    commit('setUnifiedLoginAlerts', [])
                                    errorSet('Your account is locked.To reset, try Forgot password or try our support page.');
                                    commit('setUnifiedErrorCode', errorStatus.errorCode);
                                    commit('setLockedUserName', state.callbacks[0].input[0].value)
                                    var userTypeOptions = errorData.headers['x-fram-optionss'];
                                    dispatch('redirectAccountLockedUser', userTypeOptions)
                                }
                            }
                            else if (errorStatus.errorCode === "INVALID_CREDENTIALS_PWD_RESET") { // INVALID_CREDENTIALS_PWD_RESET_ERROR_CODE
                                let hostUrl = window.location.href
                                if (hostUrl.includes('/account/business/odasignin')) {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_pwd_reset_required'] })
                                    errorSet('You are required to change your password.Please click the Forgot Password link to reset your password.');
                                } else {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', false)
                                    commit('setUnifiedLoginAlerts', [])
                                    errorSet('You are required to change your password.Please click the Forgot Password link to reset your password.');
                                    commit('setLockedUserName', state.callbacks[0].input[0].value)
                                    var userTypeOptions = errorData.headers['x-fram-optionss'];
                                    dispatch('redirectAccountLockedUser', userTypeOptions)
                                }
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_LOGIN_LOCKED" || errorStatus.errorCode === "OTP_COUNTER_AND_PROFILE_INVALID_ATTEMPTS_COUNTE_MISMATCH") { // ACCOUNT_LOGIN_LOCKED_ERROR_CODE
                                let hostUrl = window.location.href
                                if (hostUrl.includes('/account/business/odasignin')) {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_mobile_login_locked'] })
                                    errorSet('Your account is locked. Visit sso.verizonenterprise.com from a device that is already connected to the internet or contact your administrator for assistance.');
                                } else {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', false)
                                    commit('setUnifiedLoginAlerts', [])
                                    errorSet('Your account is locked.To reset, try Forgot password or try our support page.');
                                    commit('setUnifiedErrorCode', errorStatus.errorCode);
                                    commit('setLockedUserName', state.callbacks[0].input[0].value)
                                    var userTypeOptions = errorData.headers['x-fram-optionss'];
                                    dispatch('redirectAccountLockedUser', userTypeOptions)
                                }
                            }
                            else if (errorStatus.errorCode == "ACCOUNT_COMPROMIZED") {
                                router.push({ name: 'ResetPassword', params: { lockType: 'resetSecretAnswer' } })
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_LOCK_PREVENTION") { // ACCOUNT_LOGIN_LOCK_PREVENTION_ERROR_CODE 
                                const hostUrl = window.location.href
                                if (hostUrl.includes('/account/business/odasignin')) {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_mobile_login_lock_prevention'] })
                                    errorSet('If you are having trouble logging in visit sso.verizonenterprise.com from a device that is already connected to the internet and select Forgot username or password. If you do not have a My Business login, please contact your administrator for assistance.');
                                }
                                else {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_login_lock_prevention'] })
                                    commit('setUnifiedLoginAlerts', [{ type: 'newWarning', msg: translations.en.message['am_unified_error_login_lock_prevention'] }])
                                    errorSet('One or more of the values you have entered is incorrect.To reset, try Forgot user ID or Forgot password.');
                                    commit('setUnifiedErrorCode', errorStatus.errorCode);
                                }
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_LOCK_PRE_PREVENTION") { // ACCOUNT_LOGIN_LOCK_PRE_PREVENTION_ERROR_CODE FOR THIRD ATTEMPT
                                let hostUrl = window.location.href
                                if (hostUrl.includes('/account/business/odasignin')) {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_mobile_login_lock_prevention'] })
                                    errorSet('If you are having trouble logging in visit sso.verizonenterprise.com from a device that is already connected to the internet and select Forgot username or password. If you do not have a My Business login, please contact your administrator for assistance.');
                                } else {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_login_lock_pre_prevention'] })
                                    commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_error_login_lock_pre_prevention'] }])
                                    errorSet('One or more of the values you have entered is incorrect.To reset, try Forgot user ID or Forgot password.');
                                }
                            }
                            else if (errorStatus.errorCode === "APP_GROUP_MISMATCH") { // ACCOUNT_LOGIN_APP_GROUP_USER_MISMATCH_ERROR_CODE
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorMessage: translations.en.message['am_error_app_usr_mismatch'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_error_app_usr_mismatch'] }])
                                errorSet('The user you have selected is not allowed to log into the application from this page.Please contact your administrator to verify your login url.');
                            }
                            else if (errorStatus.errorCode === "FEDERATED_USER") { // ACCOUNT_LOGIN_FEDERATED_USER_ERROR_CODE
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorMessage: translations.en.message['am_error_federated_usr'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_error_federated_usr'] }])
                                errorSet("To log into Verizon Enterprise Center, please access it via your company 's website.Your user ID and password are safely stored in our system but you don 't need to enter them to log in. Your ability to log into Verizon Enterprise Center directly has been blocked at your company 's request.")
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_FRAUD_LOCKED") { // ACCOUNT_FRAUD_LOCKED_ERROR_CODE 
                                commit('setAlertType', 'error');
                                const searchParams = new URLSearchParams(window.location.search);
                                if (iamvar_federalPortalKeyWords.some(str => searchParams.get('goto') && searchParams.get('goto').includes(str))) {
                                    var federalPoratalValue = iamvar_federalPortalKeyWords.find(str => searchParams.get('goto') && searchParams.get('goto').includes(str))
                                    if (iamvar_federalNXPortalList.some(str => federalPoratalValue && federalPoratalValue.includes(str))) {
                                        commit('setAuthError', { errorMessage: translations.en.message['am_error_nx_fraud_locked'] })
                                        commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_error_nx_fraud_locked'] }])
                                        errorSet('Locked User ID. This user id has been locked and requires Customer Service assistance to be unlocked. Contact the Verizon User Adminstration Group(UAG) for assistance: 1-888-624-7821 or NxU-registration@verizon.com')
                                    } else if (iamvar_federalNEPortalList.some(str => federalPoratalValue && federalPoratalValue.includes(str))) {
                                        commit('setAuthError', { errorMessage: translations.en.message['am_error_ne_fraud_locked'] })
                                        commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_error_ne_fraud_locked'] }])
                                        errorSet('Locked User ID. This user id has been locked and requires Customer Service assistance to be unlocked. Contact the Verizon User Adminstration Group(UAG) for assistance: 1-888-624-7730 or NxE-registration@verizon.com')
                                    } else if (iamvar_federalWIPortalList.some(str => federalPoratalValue && federalPoratalValue.includes(str))) {
                                        commit('setAuthError', { errorMessage: translations.en.message['am_error_wi_fraud_locked'] })
                                        commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_error_wi_fraud_locked'] }])
                                        errorSet('Locked User ID. This user id has been locked and requires Customer Service assistance to be unlocked. Contact the Verizon Service@once Support Team for assistance: 1-800-381-3444, option 6 or serviceatoncesupport@verizon.com')
                                    } else if (iamvar_federalSAPortalList.some(str => federalPoratalValue && federalPoratalValue.includes(str))) {
                                        commit('setAuthError', { errorMessage: translations.en.message['am_error_sa_fraud_locked'] })
                                        commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_error_sa_fraud_locked'] }])
                                        errorSet('Locked User ID. This user id has been locked and requires Customer Service assistance to be unlocked. Contact the Verizon Service@once Support Team for assistance: 1-800-638-5567, option 5 or serviceatoncesupport@verizon.com')
                                    } else {
                                        commit('setAuthError', { errorMessage: translations.en.message['am_error_fraud_locked'] })
                                        commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_error_fraud_locked'] }])
                                        errorSet('Locked User ID. This user id has been locked and requires Customer Service assistance to be unlocked.Please contact Customer Service to unlock.')
                                    }
                                } else {
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_fraud_locked'] })
                                    commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_error_fraud_locked'] }])
                                    errorSet('Locked User ID. This user id has been locked and requires Customer Service assistance to be unlocked.Please contact Customer Service to unlock.')
                                }
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_POC_DUSER_BLOCKED") { // ACCOUNT_POC_DUSER_BLOCKED_ERROR_CODE 
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorMessage: translations.en.message['poc_dormant_user_blocked'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['poc_dormant_user_blocked'] }])
                                errorSet("Since you haven't logged in for over 3 years, we’re emailing your company administrator to get approval for security purposes. Once approved, you’ll get an email notification")
                            }
                            else if (errorStatus.errorCode === "PCOAE_ACCOUNT_FRAUD_LOCKED") { // PCOAE_ACCOUNT_FRAUD_LOCKED_ERROR_CODE 
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorMessage: translations.en.message['login_flows_locked_ecpd_admin_user'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['login_flows_locked_ecpd_admin_user'] }])
                                errorSet("Your account has been locked. Please contact 1-888-483-7200 for help unlocking your account.")
                            }
                            else if (errorStatus.errorCode === "NPCOAE_ACCOUNT_FRAUD_LOCKED") { // NPCOAE_ACCOUNT_FRAUD_LOCKED_ERROR_CODE 
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorMessage: translations.en.message['login_flows_locked_ecpd_non_admin_user'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['login_flows_locked_ecpd_non_admin_user'] }])
                                errorSet("Your account has been locked. Your company administrator has been notified how to unlock the account.")
                            }
                            else if (errorStatus.errorCode === "REDIRECT_TO_IDM_REQUIRED") { // userType == VZW
                                commit('setAlertType', 'information')

                                let urlParams = window.location.search
                                let idmLoginRedirectUrl = iamvar_idmSsoLoginUrl + urlParams
                                commit('setIdmRedirectUrl', idmLoginRedirectUrl)

                                commit('setAuthError', { errorMessage: translations.en.message['redirect_to_idm_err'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'information', msg: `<b>Thank you for being a Verizon business customer.</b><br/>You have reached this page in error, You will be redirected to Verizon business login page in 10 secs or you can <a href="${idmLoginRedirectUrl}" style="color: white; text-decoration: underline;"> click here </a>` }])

                                // redirect to IDM
                                setTimeout(() => {
                                    window.location.replace(idmLoginRedirectUrl)
                                }, 10000)
                            } else if (errorStatus.errorCode === "REDIRECT_TO_COMPLETE_REGISTRATION_REQUIRED") {
                                let regData = errorStatus.registrationToken;
                                window.location.href = mixin.methods.sanitizeUrl(iamvar_mbt_base_url + "/mbt/userregistration/enroll?regData=" + regData + "#/enroll");
                            } else if (errorStatus.errorCode === "INTERNAL_SERVER_ERROR") { // INTERNAL_SERVER_ERROR_CODE 
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorMessage: translations.en.message['am_error_internal_err'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_error_internal_err'] }])
                                errorSet('An error occurred.Please contact your administrator.')
                            } else if (errorStatus.errorCode == "VBM_USER_LOGIN_REDIRECT") { // for VBM User while login we have to  submit a form
                                var f = document.createElement("form");
                                f.setAttribute('method', "post");
                                f.setAttribute('action', iamvar_vbm_login_redirect);
                                f.setAttribute('id', "welcomeBasket");
                                f.setAttribute('name', "welcomeBasket1");

                                var i1 = document.createElement("input"); //input element, text
                                i1.setAttribute('type', "hidden");
                                i1.setAttribute('name', "realm");
                                i1.setAttribute('value', "vzw");

                                var i2 = document.createElement("input"); //input element, text
                                i2.setAttribute('type', "hidden");
                                i2.setAttribute('name', "module");
                                i2.setAttribute('value', "ResidentialLoginModule");

                                var i3 = document.createElement("input"); //input element, text
                                i3.setAttribute('type', "hidden");
                                i3.setAttribute('name', "gx_charset");
                                i3.setAttribute('value', "UTF-8");

                                var i4 = document.createElement("input"); //input element, text
                                i4.setAttribute('type', "hidden");
                                i4.setAttribute('name', "userNameOnly");
                                i4.setAttribute('value', "true");

                                var i5 = document.createElement("input"); //input element, text
                                i5.setAttribute('type', "hidden");
                                i5.setAttribute('name', "ignoreReferer");
                                i4.setAttribute('value', "true");

                                var i6 = document.createElement("input"); //input element, text
                                i6.setAttribute('type', "hidden");
                                i6.setAttribute('name', "IDToken1");
                                i6.setAttribute('value', username);

                                var i7 = document.createElement("input"); //input element, text
                                i7.setAttribute('type', "hidden");
                                i7.setAttribute('name', "IDToken2");
                                i7.setAttribute('value', password);

                                var i8 = document.createElement("input"); //input element, text
                                i8.setAttribute('type', "hidden");
                                i8.setAttribute('name', "fromVZT");
                                i8.setAttribute('value', "Y");

                                var i9 = document.createElement("input"); //input element, text
                                i9.setAttribute('type', "hidden");
                                i9.setAttribute('name', "clientId");
                                i9.setAttribute('value', "vecportal");

                                var i10 = document.createElement("input"); //input element, text
                                i10.setAttribute('type', "hidden");
                                i10.setAttribute('name', "customerType");
                                i10.setAttribute('value', "BU");

                                var i11 = document.createElement("input"); //input element, text
                                i11.setAttribute('type', "hidden");
                                i11.setAttribute('name', "goto");
                                i11.setAttribute('value', iamvar_vbm_formsso);

                                var i12 = document.createElement("input"); //input element, text
                                i12.setAttribute('type', "hidden");
                                i12.setAttribute('name', "differentUserUrl");
                                i12.setAttribute('value', iamvar_vbm_redirection);

                                f.appendChild(i1);
                                f.appendChild(i2);
                                f.appendChild(i3);
                                f.appendChild(i4);
                                f.appendChild(i5);
                                f.appendChild(i6);
                                f.appendChild(i7);
                                f.appendChild(i8);
                                f.appendChild(i9);
                                f.appendChild(i10);
                                f.appendChild(i11);
                                f.appendChild(i12);
                                document.body.appendChild(f);
                                //console.log("f...",f,document.getElementById("welcomeBasket"))
                                document.getElementById("welcomeBasket").submit();
                                // } else if(errorStatus.errorCode == "INVALID_VBG_CREDENTIAL"){
                                //     // opened interstitial page
                                //     commit('setInterstitialPage',true)
                                //     commit('setUnifiedErrorCode',errorStatus.errorCode)
                            } else if (errorStatus.errorCode == "SMB_LOGIN_REDIRECT") {
                                dispatch('smbFormSubmit', { username, password, rememberUser: payload.rememberUser })
                                commit('setUnifiedErrorCode', errorStatus.errorCode)
                            } else if (errorStatus.errorCode == "INVALID_SMB_CREDENTIAL") {
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_error_invalid_creds'] }])
                                commit('setUnifiedErrorCode', errorStatus.errorCode)
                                errorSet('One or more of the values you have entered is incorrect.Please verify your login information and try again.')
                            } else if (errorStatus.errorCode == "VALID_MYBIZ_SMB_CREDENTIAL" || errorStatus.errorCode == "VALID_VEC_SMB_CREDENTIAL") {
                                commit('setInterstitialPage', true)
                                commit('setUnifiedErrorCode', errorStatus.errorCode)
                                const { eventName, eventPayload } = unifiedLoginEvents.INVALID_MYBIZ_SMB_CREDENTIAL

                                // accounts present in adobe-analytics.json
                                eventPayload.accounts["1"] = errorStatus.errorCode == "VALID_MYBIZ_SMB_CREDENTIAL" ? "My Business Wireless" : "Verizon Enterprise Center";

                                eventTracking(eventName, eventPayload)
                            } else if (errorStatus.errorCode == "INVALID_MYBIZ_SMB_CREDENTIAL" || errorStatus.errorCode == "INVALID_VEC_SMB_CREDENTIAL") {
                                commit('setInterstitialPage', true)
                                commit('setUnifiedErrorCode', errorStatus.errorCode)
                                const { eventName, eventPayload } = unifiedLoginEvents.INVALID_MYBIZ_SMB_CREDENTIAL;
                                eventPayload.accounts["1"] = errorStatus.errorCode == "INVALID_MYBIZ_SMB_CREDENTIAL" ? "My Business Wireless" : "Verizon Enterprise Center";


                                eventTracking(eventName, eventPayload)
                            } else if (errorStatus.errorCode == "VALID_MYBIZ_VEC_SMB_CREDENTIAL" || errorStatus.errorCode == "INVALID_MYBIZ_VEC_SMB_CREDENTIAL") {
                                commit('setInterstitialPage', true)
                                commit('setUnifiedErrorCode', errorStatus.errorCode)
                                const { eventName, eventPayload } = unifiedLoginEvents.INVALID_MYBIZ_VEC_SMB_CREDENTIAL;

                                eventTracking(eventName, eventPayload)
                            } else if (errorStatus.errorCode === "B360_ENROLLED_PENDING_REGISTRATION") {
                                let Error = translations.en.message['am_error_B360_enrolled']
                                let Error1 = translations.en.message['am_unified_error_B360_enrolled']
                                commit('setAlertType', 'error')
                                commit('setAuthError', {
                                    errorMessage: Error,
                                    errorCode: "B360_ENROLLED_PENDING_REGISTRATION"
                                })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: Error1 }])
                                errorSet("We're still setting up your My Business Account.<br/>We'll send you a confirmation email when it's completed. We appreciate your patience.");

                            } else if (errorStatus.errorCode === "PROSPECT_ENROLLED_PENDING_REGISTRATION") {
                                let Error = translations.en.message['am_error_prospect_enrolled']
                                let Error1 = translations.en.message['am_unified_error_prospect_enrolled']
                                commit('setAlertType', 'error')
                                commit('setAuthError', {
                                    errorMessage: Error,
                                    errorCode: "PROSPECT_ENROLLED_PENDING_REGISTRATION"
                                })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: Error1 }])
                                errorSet("Just one more step to go.<br/>Please activate a line before logging in to My Business. Thanks.");

                            } else if (errorStatus.errorCode === "ACCOUNT_ALREADY_UNLOCKED") {
                                commit('setAlertType', 'information')
                                commit('setAuthError', { errorCode: "ACCOUNT_ALREADY_UNLOCKED", errorMessage: translations.en.message['am_unified_already_unlocked'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'information', msg: translations.en.message['am_unified_already_unlocked'] }])
                                errorSet("You’ve already unlocked your account.Log in to access your account.");
                                router.push({ path: '/login/unifiedlogin' })
                            } else if (errorStatus.errorCode === "USERID_MISMATCH") {
                                commit('setUnlockMyAccountAlerts', [{ type: 'error', msg: translations.en.message['am_unified_invalid_userId'] }])
                                commit('setUnifiedLoginAlerts', [{ type: 'error', msg: translations.en.message['am_unified_invalid_userId'] }])
                                errorSet("The User ID you entered doesn't match this account.Please use the User ID associated with the account you're unlocking.");
                            } else if (errorStatus.errorCode === "ACCOUNT_UNLOCK_ALREADY_REQUESTED") {
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorCode: "ACCOUNT_UNLOCK_ALREADY_REQUESTED", errorMessage: translations.en.message['am_unified_account_unlock_already_requested'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_account_unlock_already_requested'] }])
                                errorSet("You already requested to unlock your account. Check your email to unlock your account.");
                            } else if (errorStatus.errorCode === "TOKEN_EXPIRED") {
                                dispatch('updateSetTokenExpired', true)
                            } else if (errorStatus.errorCode === "CQA_ACCOUNT_LOCKED" && iamvar_vcgvbgMigration) {
                                if (state.gotoURL) {
                                    router.push({ name: 'ResetPassword', params: { lockType: 'resetSecretAnswer' }, query: { goto: state.gotoURL } })
                                } else {
                                    router.push({ name: 'ResetPassword', params: { lockType: 'resetSecretAnswer' } })
                                }
                            } else if (errorStatus.errorCode == iamvar_vcgMultipleAccount && iamvar_vcgvbgMigration ) {
                                commit('setInterstitialPage', errorStatus.errorCode)
                                commit('setUnifiedErrorCode', errorStatus.errorCode)
                                const { eventName, eventPayload } = unifiedLoginEvents.iamvar_vcgMultipleAccount;

                                eventTracking(eventName, eventPayload)
                            } else {
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorMessage: translations.en.message['bckend_unknown_err'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: '<b>An error occurred.</b>' }])
                                errorSet('An error occurred.')
                            }
                            // automatically remove timer after 10 secs.
                            if (state.alertType != 'information') {
                                setTimeout(function () {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', false)
                                }, 10000)
                            }

                        } else {
                            // generic network error
                            commit('setAlertType', 'error')
                            commit('setAuthError', { errorMessage: translations.en.message['bckend_unknown_err'] })
                            commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: '<b>An error occurred.</b>' }])
                            errorSet('An error occurred.')
                            // log unhandled error
                            let requestObject = { reqUrl: reqUrl, reqBody: state.sanitizedAuthenticationPayload, reqHeaders: state.options }
                            let explanation = "This call should return the userID/password validation"

                            let unhandledErrObj = { request: requestObject, response: error, explanation: explanation }

                            dispatch('sendUnhandledErrorToBackend', unhandledErrObj)
                        }
                    }
                    // logger.error('Error in authenticating the user')
                    throw error
                } else {
                    // generic network error
                    commit('setAlertType', 'error')
                    commit('setAuthError', { errorMessage: translations.en.message['bckend_unknown_err'] })
                    commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: '<b>An error occurred.</b>' }])
                    errorSet('An error occurred.')

                    // log error
                    let requestObject = { reqUrl: reqUrl, reqBody: state.sanitizedAuthenticationPayload, reqHeaders: state.options }
                    let explanation = "This call should return the userID/password validation"

                    let unhandledErrObj = { request: requestObject, response: error, explanation: explanation }

                    dispatch('sendUnhandledErrorToBackend', unhandledErrObj)
                }
            })
        },
        authenticatePasswordUser({ commit, dispatch, state }, payload) {
            var reqUrl = 'json/realms/vzwmb/authenticate?authIndexType=service&authIndexValue=VBGUserService&goto=' + state.gotoUrl
            let option = state.options
            if(payload.encrpytedRegTok) {
                commit('setVcgSubmitLoader', true)
                option.headers['X-VZ-AUTH-REG'] = 'Y'
            } else {
                commit('setLoader', true)
                var username = payload.data.callbacks[0].input[0].value
                var password = payload.data.callbacks[1].input[0].value
                dispatch('sanitizePasswordForErrorLogging', payload.data)
                // If user select MyBiz/VEC on unifiedportal, making "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
                if (payload.additionalAuth) {
                    option.headers['X-VZ-AUTH-HYBRID'] = payload.additionalAuth
                }
                if (payload.captchaAuth) {
                    option.headers['X-VZ-ILOGIN'] = option.captchaAuth
                }
                payload.data.path = "vzpllgn"
                if (password == '') {
                    option.headers['X-VZ-FLOWTYPE'] = 'complex'
                    iamvar_pwdLessFlowType = 'complex'
                } else {
                    option.headers['X-VZ-FLOWTYPE'] = 'basic'
                    iamvar_pwdLessFlowType = 'basic'
                }
                /*let newPayload = {
                    'data': payload.data,
                    'details': "pwdlesslogin"
                }*/
                // encoding username and password to base64 
                // let payloadEnc = JSON.parse(JSON.stringify(payload.data));
                // payloadEnc.callbacks[0].input[0].value = btoa(payloadEnc.callbacks[0].input[0].value);
                // payloadEnc.callbacks[1].input[0].value = btoa(payloadEnc.callbacks[1].input[0].value);
            }
            return amrest.post(reqUrl, payload.data, option).then(function (response) {
                var authResponse = response.data
                // Set remember me cookie only if the user has checked the remember me check box
                if (payload.rememberUser) {
                    dispatch('encryptUsername', username)
                }
                if (payload.rememberMethod == true) {
                    dispatch('encryptDefaultMethod', payload.usernameOption)
                } else {
                    if (state.optionMethod == 'opt3') {
                        for (var i = 0; i < iamvar_vbgcRemCookieDomain.length; i++) {
                            document.cookie = iamvar_makeDefaultMethodName + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=' + iamvar_vbgcRemCookieDomain[i] + ';path=' + iamvar_vbgcRemCookiePath + ';secure=' + iamvar_vbgcRemCookieSecure + ';comment=deleting from ui'
                        }
                    }
                }
                var deviceFingerprint = getDeviceFingerprintInfoWithoutGeolocation()
                authResponse.callbacks[0].input[0].value = deviceFingerprint.value
                dispatch('sendDeviceFingerprint', authResponse)

            }).catch((error) => {
                commit('setLoader', false)
                commit('setVcgSubmitLoader', false)
                var errorData = error.response

                if (errorData.status == 417) {
                    if (errorData.data) {
                        // document.write(errorData.data);
                        if (docHTML) {
                            docHTML[0].innerHTML = errorData.data;
                        }
                    }
                    else {
                        commit('setAlertType', 'error')
                        commit('setAuthError', { errorMessage: translations.en.message['bckend_unknown_err'] })
                        commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: '<b>An error occurred.</b>' }])
                        errorSet('An error occurred.');
                    }
                }

                if (errorData) {
                    if (errorData.headers[iamvar_authErrorHeaderName]) {
                        // logger.warn('User entered incorrect credentials')
                        var errorStatus = JSON.parse(errorData.headers[iamvar_authErrorHeaderName])
                        var lobType = errorData && errorData.headers['x-vz-lob'];
                        if((errorData.headers[iamvar_Vcg_Throttled_On])) {
                            dispatch('updateVcgThrotteledOn', true)
                        }
                        dispatch('updateLineOfBusiness', errorData.headers['x-fram-optionss'])
                        if (errorStatus.authStatus == false) {
                            if (errorStatus.errorCode === "INVALID_CREDENTIALS") {
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorMessage: translations.en.message['am_error_invalid_creds'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: "You have entered incorrect values. Please verify your login information and try again." }])
                                errorSet('One or more of the values you have entered is incorrect.Please verify your login information and try again.');
                                iamvar_pwdLessFlowType = '';
                                if(lobType && lobType.toLowerCase() == 'vps') {
                                    router.push({ path: '/login/vpsunifiedlogin', query: { goto: state.gotoUrl }});
                                } else {
                                    router.push({ path: '/login/unifiedlogin', query: { goto: state.gotoUrl } });
                                }
                            }
                            else if(isVPSFlowEnabled && errorStatus.errorCode === "INVALID_VPS_USER") {
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorMessage: translations.en.message['am_error_invalid_creds'] })
                                commit('setUnifiedLoginAlerts',[{type:'newError',msg: "You have entered incorrect values. Please verify your login information and try again."}])
                                errorSet('One or more of the values you have entered is incorrect.Please verify your login information and try again.');
                                iamvar_pwdLessFlowType = '';
                                router.push({ path: '/login/vpsunifiedlogin', query: { goto: state.gotoUrl }});
                            }
                            else if(isVPSFlowEnabled && errorStatus.errorCode === "ACCOUNT_SUSPENDED") {
                                router.push({ path: '/login/vpsusersuspended', query: { goto: state.gotoUrl }});
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_PRE_PREVENTION") {
                                //for 3rd invalid attempt in unified exp
                                let hostUrl = window.location.href
                                if (hostUrl.includes('/account/business/odasignin')) {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_mobile_login_lock_prevention'] })
                                    errorSet('If you are having trouble logging in visit sso.verizonenterprise.com from a device that is already connected to the internet and select Forgot username or password. If you do not have a My Business login, please contact your administrator for assistance.');
                                } else {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_invalid_creds'] })
                                    commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: "You have entered incorrect values. Please verify your login information and try again." }])
                                    errorSet('One or more of the values you have entered is incorrect.To reset, try Forgot user ID or Forgot password.');
                                }
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_PREVENTION") {
                                //for 4th invalid attempt in unified exp
                                const hostUrl = window.location.href
                                if (hostUrl.includes('/account/business/odasignin')) {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_mobile_login_lock_prevention'] })
                                    errorSet('If you are having trouble logging in visit sso.verizonenterprise.com from a device that is already connected to the internet and select Forgot username or password. If you do not have a My Business login, please contact your administrator for assistance.');
                                } else {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_login_lock_prevention'] })
                                    commit('setUnifiedLoginAlerts', [{ type: 'newWarning', msg: "You have entered incorrect values. Please verify your login information and try again." }])
                                    errorSet('One or more of the values you have entered is incorrect.To reset, try Forgot user ID or Forgot password.');
                                    commit('setUnifiedErrorCode', errorStatus.errorCode);
                                }
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_INACTIVE_AUTH_STEPS_REQUIRED") {
                                commit('setAuthError', { errorMessage: "The User ID is unable to perform the necessary steps at this time. Please contact one of the following for further assistance:<ul><li> • Company's Administrator</li><li> • Verizon Customer Service at 1-800-922-0204</li><li> • WITS/ Service@once users only: [800 381 3444|tel:8003813444] option 6 </li><li> • US and Canada Wireline Customers only: 800.569.8799 (M-F 9AM-6PM ET) Live Chat (Sun 8pm- Sat 8pm ET) </li><li> • EMEA, APAC, LATAM Wireline Customers only: 00.800.4321.5432 (M-F 9AM-5PM GMT) vec-support@verizon.com </li>" })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: "The User ID is unable to perform the necessary steps at this time. Please contact Company's Administrator" }])
                                errorSet("The User ID is unable to perform the necessary steps at this time. Please contact one of the following for further assistance:<ul><li> • Company's Administrator</li><li> • Verizon Customer Service at 1-800-922-0204</li><li> • WITS/ Service@once users only: [800 381 3444|tel:8003813444] option 6 </li><li> • US and Canada Wireline Customers only: 800.569.8799 (M-F 9AM-6PM ET) Live Chat (Sun 8pm- Sat 8pm ET) </li><li> • EMEA, APAC, LATAM Wireline Customers only: 00.800.4321.5432 (M-F 9AM-5PM GMT) vec-support@verizon.com </li>")
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_LOCKED") {
                                //for 5th invalid attempt in unified exp
                                const hostUrl = window.location.href
                                if (hostUrl.includes('/account/business/odasignin')) {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_mobile_login_locked'] })
                                    errorSet('Your account is locked. Visit sso.verizonenterprise.com from a device that is already connected to the internet or contact your administrator for assistance.');
                                } else {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', false)
                                    commit('setUnifiedLoginAlerts', [])
                                    errorSet('Your account is locked.To reset, try Forgot password or try our support page.');
                                    commit('setUnifiedErrorCode', errorStatus.errorCode);
                                    commit('setLockedUserName', state.callbacks[0].input[0].value)
                                    var userTypeOptions = errorData.headers['x-fram-optionss'];
                                    dispatch('redirectAccountLockedUser', userTypeOptions)
                                }
                            }
                            else if (errorStatus.errorCode === "INVALID_CREDENTIALS_PWD_EXPIRED") { // INVALID_CREDENTIALS_PWD_EXPIRED_ERROR_CODE
                                let hostUrl = window.location.href
                                if (hostUrl.includes('/account/business/odasignin')) {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_mobile_login_locked'] })
                                    errorSet('Your account is locked. Visit sso.verizonenterprise.com from a device that is already connected to the internet or contact your administrator for assistance.');
                                } else {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', false)
                                    commit('setUnifiedLoginAlerts', [])
                                    errorSet('Inactive or Expired User ID/Password.Please select the Forgot Password link below to reactivate your User ID and reset your password.');
                                    commit('setLockedUserName', state.callbacks[0].input[0].value)
                                    var userTypeOptions = errorData.headers['x-fram-optionss'];
                                    dispatch('redirectAccountLockedUser', userTypeOptions)

                                }
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_NEWLY_LOGIN_LOCKED") // for ACCOUNT_NEWLY_LOGIN_LOCKED user should redirect to forgot password
                            {
                                let url = window.location.href
                                commit('setLockedUserName', state.callbacks[0].input[0].value)
                                var userTypeOptions = errorData.headers['x-fram-optionss'];
                                dispatch('redirectAccountLockedUser', userTypeOptions)
                            }
                            else if (errorStatus.errorCode === "INVALID_CREDENTIALS_PWD_RESET") { // INVALID_CREDENTIALS_PWD_RESET_ERROR_CODE
                                commit('setAlertType', 'error')
                                commit('setAuthError', false)
                                commit('setUnifiedLoginAlerts', [{}])
                                errorSet('You are required to change your password.Please click the Forgot Password link to reset your password.');
                                commit('setLockedUserName', state.callbacks[0].input[0].value)
                                var userTypeOptions = errorData.headers['x-fram-optionss'];
                                dispatch('redirectAccountLockedUser', userTypeOptions)
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_LOGIN_LOCKED" || errorStatus.errorCode === "OTP_COUNTER_AND_PROFILE_INVALID_ATTEMPTS_COUNTE_MISMATCH") { // ACCOUNT_LOGIN_LOCKED_ERROR_CODE
                                let hostUrl = window.location.href
                                if (hostUrl.includes('/account/business/odasignin')) {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_mobile_login_locked'] })
                                    errorSet('Your account is locked. Visit sso.verizonenterprise.com from a device that is already connected to the internet or contact your administrator for assistance.');
                                } else {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', false)
                                    commit('setUnifiedLoginAlerts', [])
                                    errorSet('Your account is locked.To reset, try Forgot password or try our support page.');
                                    commit('setLockedUserName', state.callbacks[0].input[0].value)
                                    var userTypeOptions = errorData.headers['x-fram-optionss'];
                                    dispatch('redirectAccountLockedUser', userTypeOptions)
                                }
                            }
                            else if (errorStatus.errorCode == "ACCOUNT_COMPROMIZED") {
                                router.push({ name: 'ResetPassword', params: { lockType: 'resetSecretAnswer' } })
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_LOCK_PREVENTION") { // ACCOUNT_LOGIN_LOCK_PREVENTION_ERROR_CODE 
                                const hostUrl = window.location.href
                                if (hostUrl.includes('/account/business/odasignin')) {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_mobile_login_lock_prevention'] })
                                    errorSet('If you are having trouble logging in visit sso.verizonenterprise.com from a device that is already connected to the internet and select Forgot username or password. If you do not have a My Business login, please contact your administrator for assistance.');
                                }
                                else {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_login_lock_prevention'] })
                                    commit('setUnifiedLoginAlerts', [{ type: 'newWarning', msg: translations.en.message['am_unified_error_login_lock_prevention'] }])
                                    errorSet('One or more of the values you have entered is incorrect.To reset, try Forgot user ID or Forgot password.');
                                    commit('setUnifiedErrorCode', errorStatus.errorCode);
                                    iamvar_pwdLessFlowType = '';
                                    if(lobType && lobType.toLowerCase() == 'vps') {
                                        router.push({ path: '/login/vpsunifiedlogin'});
                                    } else {
                                        router.push({ path: '/login/unifiedlogin'});
                                    }
                            	}
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_LOCK_PRE_PREVENTION") { // ACCOUNT_LOGIN_LOCK_PRE_PREVENTION_ERROR_CODE FOR THIRD ATTEMPT
                                let hostUrl = window.location.href
                                if (hostUrl.includes('/account/business/odasignin')) {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_mobile_login_lock_prevention'] })
                                    errorSet('If you are having trouble logging in visit sso.verizonenterprise.com from a device that is already connected to the internet and select Forgot username or password. If you do not have a My Business login, please contact your administrator for assistance.');
                                } else {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_login_lock_pre_prevention'] })
                                    commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_error_login_lock_pre_prevention'] }])
                                    errorSet('One or more of the values you have entered is incorrect.To reset, try Forgot user ID or Forgot password.');
                                }
                            }
                            else if (errorStatus.errorCode === "APP_GROUP_MISMATCH") { // ACCOUNT_LOGIN_APP_GROUP_USER_MISMATCH_ERROR_CODE
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorMessage: translations.en.message['am_error_app_usr_mismatch'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_error_app_usr_mismatch'] }])
                                errorSet('The user you have selected is not allowed to log into the application from this page.Please contact your administrator to verify your login url.');
                            }
                            else if (errorStatus.errorCode === "FEDERATED_USER") { // ACCOUNT_LOGIN_FEDERATED_USER_ERROR_CODE
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorMessage: translations.en.message['am_error_federated_usr'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_error_federated_usr'] }])
                                errorSet("To log into Verizon Enterprise Center, please access it via your company 's website.Your user ID and password are safely stored in our system but you don 't need to enter them to log in. Your ability to log into Verizon Enterprise Center directly has been blocked at your company 's request.")
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_FRAUD_LOCKED") { // ACCOUNT_FRAUD_LOCKED_ERROR_CODE 
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorMessage: translations.en.message['am_error_fraud_locked'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_error_fraud_locked'] }])
                                errorSet('Locked User ID. This user id has been locked and requires Customer Service assistance to be unlocked.Please contact Customer Service to unlock.')
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_POC_DUSER_BLOCKED") { // ACCOUNT_POC_DUSER_BLOCKED_ERROR_CODE 
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorMessage: translations.en.message['poc_dormant_user_blocked'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['poc_dormant_user_blocked'] }])
                                errorSet("Since you haven't logged in for over 3 years, we’re emailing your company administrator to get approval for security purposes. Once approved, you’ll get an email notification")
                            }
                            else if (errorStatus.errorCode === "PCOAE_ACCOUNT_FRAUD_LOCKED") { // PCOAE_ACCOUNT_FRAUD_LOCKED_ERROR_CODE 
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorMessage: translations.en.message['login_flows_locked_ecpd_admin_user'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['login_flows_locked_ecpd_admin_user'] }])
                                errorSet("Your account has been locked. Please contact 1-888-483-7200 for help unlocking your account.")
                            }
                            else if (errorStatus.errorCode === "NPCOAE_ACCOUNT_FRAUD_LOCKED") { // NPCOAE_ACCOUNT_FRAUD_LOCKED_ERROR_CODE 
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorMessage: translations.en.message['login_flows_locked_ecpd_non_admin_user'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['login_flows_locked_ecpd_non_admin_user'] }])
                                errorSet("Your account has been locked. Your company administrator has been notified how to unlock the account.")
                            }
                            else if (errorStatus.errorCode === "REDIRECT_TO_IDM_REQUIRED") { // userType == VZW
                                commit('setAlertType', 'information')

                                let urlParams = window.location.search
                                let idmLoginRedirectUrl = iamvar_idmSsoLoginUrl + urlParams
                                commit('setIdmRedirectUrl', idmLoginRedirectUrl)

                                commit('setAuthError', { errorMessage: translations.en.message['redirect_to_idm_err'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'information', msg: `<b>Thank you for being a Verizon business customer.</b><br/>You have reached this page in error, You will be redirected to Verizon business login page in 10 secs or you can <a href="${idmLoginRedirectUrl}" style="color: white; text-decoration: underline;"> click here </a>` }])

                                // redirect to IDM
                                setTimeout(() => {
                                    window.location.replace(idmLoginRedirectUrl)
                                }, 10000)
                            } else if (errorStatus.errorCode === "REDIRECT_TO_COMPLETE_REGISTRATION_REQUIRED") {
                                let regData = errorStatus.registrationToken;
                                window.location.href = mixin.methods.sanitizeUrl(iamvar_mbt_base_url + "/mbt/userregistration/enroll?regData=" + regData + "#/enroll");
                            } else if (errorStatus.errorCode === "INTERNAL_SERVER_ERROR") { // INTERNAL_SERVER_ERROR_CODE 
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorMessage: translations.en.message['am_error_internal_err'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_error_internal_err'] }])
                                errorSet('An error occurred.Please contact your administrator.')
                            } else if (errorStatus.errorCode == "VBM_USER_LOGIN_REDIRECT") { // for VBM User while login we have to  submit a form
                                var f = document.createElement("form");
                                f.setAttribute('method', "post");
                                f.setAttribute('action', iamvar_vbm_login_redirect);
                                f.setAttribute('id', "welcomeBasket");
                                f.setAttribute('name', "welcomeBasket1");

                                var i1 = document.createElement("input"); //input element, text
                                i1.setAttribute('type', "hidden");
                                i1.setAttribute('name', "realm");
                                i1.setAttribute('value', "vzw");

                                var i2 = document.createElement("input"); //input element, text
                                i2.setAttribute('type', "hidden");
                                i2.setAttribute('name', "module");
                                i2.setAttribute('value', "ResidentialLoginModule");

                                var i3 = document.createElement("input"); //input element, text
                                i3.setAttribute('type', "hidden");
                                i3.setAttribute('name', "gx_charset");
                                i3.setAttribute('value', "UTF-8");

                                var i4 = document.createElement("input"); //input element, text
                                i4.setAttribute('type', "hidden");
                                i4.setAttribute('name', "userNameOnly");
                                i4.setAttribute('value', "true");

                                var i5 = document.createElement("input"); //input element, text
                                i5.setAttribute('type', "hidden");
                                i5.setAttribute('name', "ignoreReferer");
                                i4.setAttribute('value', "true");

                                var i6 = document.createElement("input"); //input element, text
                                i6.setAttribute('type', "hidden");
                                i6.setAttribute('name', "IDToken1");
                                i6.setAttribute('value', username);

                                var i7 = document.createElement("input"); //input element, text
                                i7.setAttribute('type', "hidden");
                                i7.setAttribute('name', "IDToken2");
                                i7.setAttribute('value', password);

                                var i8 = document.createElement("input"); //input element, text
                                i8.setAttribute('type', "hidden");
                                i8.setAttribute('name', "fromVZT");
                                i8.setAttribute('value', "Y");

                                var i9 = document.createElement("input"); //input element, text
                                i9.setAttribute('type', "hidden");
                                i9.setAttribute('name', "clientId");
                                i9.setAttribute('value', "vecportal");

                                var i10 = document.createElement("input"); //input element, text
                                i10.setAttribute('type', "hidden");
                                i10.setAttribute('name', "customerType");
                                i10.setAttribute('value', "BU");

                                var i11 = document.createElement("input"); //input element, text
                                i11.setAttribute('type', "hidden");
                                i11.setAttribute('name', "goto");
                                i11.setAttribute('value', iamvar_vbm_formsso);

                                var i12 = document.createElement("input"); //input element, text
                                i12.setAttribute('type', "hidden");
                                i12.setAttribute('name', "differentUserUrl");
                                i12.setAttribute('value', iamvar_vbm_redirection);

                                f.appendChild(i1);
                                f.appendChild(i2);
                                f.appendChild(i3);
                                f.appendChild(i4);
                                f.appendChild(i5);
                                f.appendChild(i6);
                                f.appendChild(i7);
                                f.appendChild(i8);
                                f.appendChild(i9);
                                f.appendChild(i10);
                                f.appendChild(i11);
                                f.appendChild(i12);
                                document.body.appendChild(f);
                                //console.log("f...",f,document.getElementById("welcomeBasket"))
                                document.getElementById("welcomeBasket").submit();
                                // } else if(errorStatus.errorCode == "INVALID_VBG_CREDENTIAL"){
                                //     // opened interstitial page
                                //     commit('setInterstitialPage',true)
                                //     commit('setUnifiedErrorCode',errorStatus.errorCode)
                            } else if (errorStatus.errorCode == "SMB_LOGIN_REDIRECT") {
                                dispatch('smbFormSubmit', { username, password, rememberUser: payload.rememberUser })
                                commit('setUnifiedErrorCode', errorStatus.errorCode)
                            } else if (errorStatus.errorCode == "INVALID_SMB_CREDENTIAL") {
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_error_invalid_creds'] }])
                                commit('setUnifiedErrorCode', errorStatus.errorCode)
                                errorSet('One or more of the values you have entered is incorrect.Please verify your login information and try again.')
                            } else if (errorStatus.errorCode == "VALID_MYBIZ_SMB_CREDENTIAL" || errorStatus.errorCode == "VALID_VEC_SMB_CREDENTIAL") {
                                commit('setInterstitialPage', true)
                                commit('setUnifiedErrorCode', errorStatus.errorCode)
                                const { eventName, eventPayload } = unifiedLoginEvents.INVALID_MYBIZ_SMB_CREDENTIAL

                                // accounts present in adobe-analytics.json
                                eventPayload.accounts["1"] = errorStatus.errorCode == "VALID_MYBIZ_SMB_CREDENTIAL" ? "My Business Wireless" : "Verizon Enterprise Center";

                                eventTracking(eventName, eventPayload)
                            } else if (errorStatus.errorCode == "INVALID_MYBIZ_SMB_CREDENTIAL" || errorStatus.errorCode == "INVALID_VEC_SMB_CREDENTIAL") {
                                commit('setInterstitialPage', true)
                                commit('setUnifiedErrorCode', errorStatus.errorCode)
                                const { eventName, eventPayload } = unifiedLoginEvents.INVALID_MYBIZ_SMB_CREDENTIAL;
                                eventPayload.accounts["1"] = errorStatus.errorCode == "INVALID_MYBIZ_SMB_CREDENTIAL" ? "My Business Wireless" : "Verizon Enterprise Center";


                                eventTracking(eventName, eventPayload)
                            } else if (errorStatus.errorCode == "VALID_MYBIZ_VEC_SMB_CREDENTIAL" || errorStatus.errorCode == "INVALID_MYBIZ_VEC_SMB_CREDENTIAL") {
                                commit('setInterstitialPage', true)
                                commit('setUnifiedErrorCode', errorStatus.errorCode)
                                const { eventName, eventPayload } = unifiedLoginEvents.INVALID_MYBIZ_VEC_SMB_CREDENTIAL;

                                eventTracking(eventName, eventPayload)
                            } else if (errorStatus.errorCode === "B360_ENROLLED_PENDING_REGISTRATION") {
                                let Error = translations.en.message['am_error_B360_enrolled']
                                let Error1 = translations.en.message['am_unified_error_B360_enrolled']
                                commit('setAlertType', 'error')
                                commit('setAuthError', {
                                    errorMessage: Error,
                                    errorCode: "B360_ENROLLED_PENDING_REGISTRATION"
                                })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: Error1 }])
                                errorSet("We're still setting up your My Business Account.<br/>We'll send you a confirmation email when it's completed. We appreciate your patience.");

                            } else if (errorStatus.errorCode === "PROSPECT_ENROLLED_PENDING_REGISTRATION") {
                                let Error = translations.en.message['am_error_prospect_enrolled']
                                let Error1 = translations.en.message['am_unified_error_prospect_enrolled']
                                commit('setAlertType', 'error')
                                commit('setAuthError', {
                                    errorMessage: Error,
                                    errorCode: "PROSPECT_ENROLLED_PENDING_REGISTRATION"
                                })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: Error1 }])
                                errorSet("Just one more step to go.<br/>Please activate a line before logging in to My Business. Thanks.");

                            } else if (errorStatus.errorCode === "CQA_ACCOUNT_LOCKED" && iamvar_vcgvbgMigration) {
                                if (state.gotoURL) {
                                    router.push({ name: 'ResetPassword', params: { lockType: 'resetSecretAnswer' }, query: { goto: state.gotoURL } })
                                } else {
                                    router.push({ name: 'ResetPassword', params: { lockType: 'resetSecretAnswer' } })
                                }
                            } else {
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorMessage: translations.en.message['bckend_unknown_err'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: '<b>An error occurred.</b>' }])
                                errorSet('An error occurred.')
                            }

                            setTimeout(function () {
                                commit('setAlertType', 'error')
                                commit('setAuthError', false)
                            }, 10000)

                        } else {
                            // generic network error
                            commit('setAlertType', 'error')
                            commit('setAuthError', { errorMessage: translations.en.message['bckend_unknown_err'] })
                            commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: '<b>An error occurred.</b>' }])
                            errorSet('An error occurred.')
                            // log unhandled error
                            let requestObject = { reqUrl: reqUrl, reqBody: state.sanitizedAuthenticationPayload, reqHeaders: state.options }
                            let explanation = "This call should return the userID/password validation"

                            let unhandledErrObj = { request: requestObject, response: error, explanation: explanation }

                            dispatch('sendUnhandledErrorToBackend', unhandledErrObj)
                        }
                    }
                    // logger.error('Error in authenticating the user')
                    if (payload.data.callbacks[1].input[0].value == '') {
                        if (errorData.status == 400 || errorData.status == 401) {
                            var date = new Date()
                            date.setTime(date.getTime() + (iamvar_vbgcCookieExpiryInMins * 60 * 1000))
                            var myDate = date.toUTCString()
                            var ott = errorData.headers[iamvar_vbgcCookieHeaderName]
                            var userType = errorData.headers['x-vz-usertype']
                            commit('setUserType', userType)
                            setOTTInClientLibForCreatingSession(ott)
                            commit('setVBGCCookie', ott)
                            dispatch('cvsstepup/updateVBGCCookieValue', ott, { root: true })
                            // for(var i = 0; i< iamvar_vbgcCookieDomain.length; i++) {
                            //     document.cookie = encodeURIComponent(iamvar_vbgcCookieName) +'=' + encodeURIComponent(ott) + ';'+ 'expires'+'=' +myDate + ';'+'domain'+'='+ iamvar_vbgcCookieDomain[i] + ';'+ 'path'+'=' + iamvar_vbgcCookiePath+ ';'+'secure'+'=' + iamvar_vbgcCookieSecure

                            // }
                            dispatch('cvsstepup/startPageTimer', {}, { root: true })
                            commit('setLoader', true)
                            dispatch('checkHeaderForredirect',errorData)
                        }
                    }
                    throw error
                } else {
                    // generic network error
                    commit('setAlertType', 'error')
                    commit('setAuthError', { errorMessage: translations.en.message['bckend_unknown_err'] })
                    commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: '<b>An error occurred.</b>' }])
                    errorSet('An error occurred.')

                    // log error
                    let requestObject = { reqUrl: reqUrl, reqBody: state.sanitizedAuthenticationPayload, reqHeaders: state.options }
                    let explanation = "This call should return the userID/password validation"

                    let unhandledErrObj = { request: requestObject, response: error, explanation: explanation }

                    dispatch('sendUnhandledErrorToBackend', unhandledErrObj)
                }
                commit('setVcgSubmitLoader', false)
            })
        },
        authenticatePwdUser({ commit, dispatch, state }, payload) {
            commit('setLoader', true)
            //var username = payload.data.callbacks[0].input[0].value
            //var password = payload.data.callbacks[1].input[0].value
            var reqUrl = 'json/realms/root/realms/vzwmb/authenticate?authIndexType=service&client_id=pwll&authIndexValue=VBGUserValidateService&goto=' + state.gotoUrl

            //dispatch('sanitizePasswordForErrorLogging', payload.data)
            // If user select MyBiz/VEC on unifiedportal, making "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
            let option =state.options
            if(isVPSFlowEnabled && payload.isVPSFlow == true) {
                option.headers['X-VZ-CLIENTID'] = 'vps';
            }
            // let payloadEnc = JSON.parse(JSON.stringify(payload.data));
            // payloadEnc.callbacks[0].input[0].value = btoa(payloadEnc.callbacks[0].input[0].value);
            return amrest.post(reqUrl, payload.data, option).then(function (response) {
                commit('setLoader', false)
                var authResponse = response.data
                // Set remember me cookie only if the user has checked the remember me check box
                if (payload.rememberUser) {
                    dispatch('encryptUsername', payload.data.callbacks[0].input[0].value)
                }
                var deviceFingerprint = getDeviceFingerprintInfoWithoutGeolocation()
                let devicePrint = {};
                devicePrint.identifier = JSON.parse(deviceFingerprint.value)
                authResponse.callbacks[0].input[0].value = JSON.stringify(devicePrint)
                //authResponse.callbacks[1].input[0].value = deviceFingerprint.value
                dispatch('sendPwdDeviceFingerprint', authResponse)
            }).catch((error) => {
                commit('setLoader', false)
                var errorData = error.response
                var eventPayload = {};

                if (errorData.status == 417) {
                    if (errorData.data) {
                        // document.write(errorData.data);
                        if (docHTML) {
                            docHTML[0].innerHTML = errorData.data;
                        }
                        commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: errorData.data }])
                    }
                    else {
                        commit('setAlertType', 'error')
                        commit('setAuthError', { errorMessage: translations.en.message['bckend_unknown_err'] })
                        commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: '<b>An error occurred.</b>' }])
                        errorSet('An error occurred.');
                    }
                }

                else {
                    // generic network error
                    commit('setAlertType', 'error')
                    commit('setAuthError', { errorMessage: translations.en.message['bckend_unknown_err'] })
                    commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: '<b>An error occurred.</b>' }])
                    errorSet('An error occurred.')

                }
            })
        },
        authenticateVbmsAccountlinkUser({ commit, dispatch, state }, payload) {
            commit('setVcgSubmitLoader', true)
            var reqUrl = 'json/realms/vzwmb/authenticate?authIndexType=service&authIndexValue=VCGTokenGeneratorService'
            let option = state.options
            return amrest.post(reqUrl, payload.data, option).then(function (response) {
                // this call should always give 401 
                commit('setAlertType', 'error')
                commit('setAuthError', { errorMessage: translations.en.message['bckend_unknown_err'] })
                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: '<b>An error occurred.</b>' }])
                errorSet('An error occurred.');
                commit('setVcgSubmitLoader', false)
            }).catch((error) => {
                var errorData = error.response
                if (errorData.status == 401 || errorData.status == 400) {
                    if(errorData.headers[iamvar_vcgMigrationLoginToken] && iamvar_vcgvbgMigration) {
                        submitVcgMigrationForm(errorData.headers[iamvar_vcgMigrationLoginToken],'vbg-ciam-al')
                    }
                } else {
                    commit('setAlertType', 'error')
                    commit('setAuthError', { errorMessage: translations.en.message['bckend_unknown_err'] })
                    commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: '<b>An error occurred.</b>' }])
                    errorSet('An error occurred.');
                    commit('setVcgSubmitLoader', false)
                }
            })
        },
        sendDeviceFingerprint({ commit, state, dispatch }, payload) {
            // logger.http('Submitting user device fingerprint to AM')
            commit('setLoader', true)
            return amrest.post('json/realms/vzwmb/authenticate?authIndexType=service&authIndexValue=VBGUserService&goto=' + state.gotoUrl, payload, state.options).then(function (response) {
                dispatch('sendSaveFingerPrintResponse', response)
                var userType = response.headers['x-vz-usertype']
                commit('setUserType', userType)
                if (response.headers['mergestatus'] == "true") {
                    //call an api then on success of that api I need to show popup
                    const { eventName, eventPayload } = combinenowEvents.mergeIDModalView;
                    commit('setLoader', false)
                    dispatch('UpdatePopupValue', 'combinenowPopupVal')
                    eventTracking(eventName, eventPayload);
                } else {
                    constructSession(response, state.gotoUrl);
                }
            }).catch((error) => {
                var errorData = error.response
                if (errorData.status == 417) {
                    if (errorData.data) {
                        // document.write(errorData.data);
                        if (docHTML) {
                            docHTML[0].innerHTML = errorData.data;
                        }
                    }
                    else {
                        commit('setAlertType', 'error')
                        commit('setAuthError', { errorMessage: translations.en.message['bckend_unknown_err'] })
                        commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: '<b>An error occurred.</b>' }])
                        errorSet('An error occurred.');
                    }
                }
                if (errorData.status == 400 || errorData.status == 401) {
                    var date = new Date()
                    date.setTime(date.getTime() + (iamvar_vbgcCookieExpiryInMins * 60 * 1000))
                    var myDate = date.toUTCString()
                    var ott = errorData.headers[iamvar_vbgcCookieHeaderName]
                    var userType = errorData.headers['x-vz-usertype']
                    dispatch('updateLineOfBusiness', errorData.headers['x-fram-optionss'])
                    commit('setUserType', userType)
                    setOTTInClientLibForCreatingSession(ott)
                    commit('setVBGCCookie', ott)
                    dispatch('cvsstepup/updateVBGCCookieValue', ott, { root: true })
                    // for(var i = 0; i< iamvar_vbgcCookieDomain.length; i++) {
                    //     document.cookie = encodeURIComponent(iamvar_vbgcCookieName) +'=' + encodeURIComponent(ott) + ';'+ 'expires'+'=' +myDate + ';'+'domain'+'='+ iamvar_vbgcCookieDomain[i] + ';'+ 'path'+'=' + iamvar_vbgcCookiePath+ ';'+'secure'+'=' + iamvar_vbgcCookieSecure

                    // }
                    commit('setLoader', true)
                    dispatch('checkHeaderForredirect',errorData)
                } else {
                    // logger.error('Error in submitting user device fingerprint '+error)
                    commit('setLoader', false)

                    throw error
                }
                commit('setLoader', false)
            })
        },
        sendPwdDeviceFingerprint({ commit, state, dispatch }, payload) {
            // logger.http('Submitting user device fingerprint to AM')
            commit('setLoader', true)
            return amrest.post('json/realms/root/realms/vzwmb/authenticate?authIndexType=service&client_id=pwll&authIndexValue=VBGUserValidateService&goto=' + state.gotoUrl, payload, state.options).then(function (response) {
                /*dispatch('sendSaveFingerPrintResponse',response)
                var userType = response.headers['x-vz-usertype']
                commit('setUserType', userType)
                if(response.headers['mergestatus'] == "true") {
                    //call an api then on success of that api I need to show popup
                    const { eventName, eventPayload } = combinenowEvents.mergeIDModalView;
                    commit('setLoader', false)
                    dispatch('UpdatePopupValue','combinenowPopupVal')
                    eventTracking(eventName, eventPayload);
                } else {
                    constructSession(response,state.gotoUrl);
                }*/

                let lockState = response.headers['x-vz-auth_ostate']
                /*if (lockState != undefined) {
                    if (lockState == "ACCOUNT_PUSH_LOCKED") {
                        state.pwdAuthOptions[0].disable = true
                    } else if (lockState == "ACCOUNT_VET_LOCKED") {
                        state.pwdAuthOptions[1].disable = true
                    } else if (lockState == "ACCOUNT_LOGIN_LOCKED") {
                        commit('setLockedUserName', state.callbacks[0].input[0].value)
                        var userTypeOptions = errorData.headers['x-fram-optionss'];
                        dispatch('redirectAccountLockedUser', userTypeOptions)
                    } else if (lockState == "ACCOUNT_LOGIN_ABANDONMENT") {
                        commit('setLockedUserName', state.callbacks[0].input[0].value)
                        var userTypeOptions = errorData.headers['x-fram-optionss'];
                        dispatch('redirectAccountLockedUser', userTypeOptions)
                    } 
                }*/
                if (lockState != undefined) {
                    if ((lockState == "ACCOUNT_PUSH_LOCKED") && (state.optionMethod == 'opt1')) {
                        for (var i = 0; i < iamvar_vbgcRemCookieDomain.length; i++) {
                            document.cookie = iamvar_makeDefaultMethodName + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=' + iamvar_vbgcRemCookieDomain[i] + ';path=' + iamvar_vbgcRemCookiePath + ';secure=' + iamvar_vbgcRemCookieSecure + ';comment=deleting from ui'
                        }
                        state.pwdAuthOptions[0].disable = true
                        //commit('setRedirectToOptionsPage', true)
                    } else if ((lockState == "ACCOUNT_VET_LOCKED") && (state.optionMethod == 'opt2')) {
                        for (var i = 0; i < iamvar_vbgcRemCookieDomain.length; i++) {
                            document.cookie = iamvar_makeDefaultMethodName + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=' + iamvar_vbgcRemCookieDomain[i] + ';path=' + iamvar_vbgcRemCookiePath + ';secure=' + iamvar_vbgcRemCookieSecure + ';comment=deleting from ui'
                        }
                        state.pwdAuthOptions[1].disable = true
                        //commit('setRedirectToOptionsPage', true)
                    } else if ((lockState == "ACCOUNT_LOGIN_LOCKED" || lockState == "OTP_COUNTER_AND_PROFILE_INVALID_ATTEMPTS_COUNTE_MISMATCH") && (state.optionMethod != '')) {
                        for (var i = 0; i < iamvar_vbgcRemCookieDomain.length; i++) {
                            document.cookie = iamvar_makeDefaultMethodName + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=' + iamvar_vbgcRemCookieDomain[i] + ';path=' + iamvar_vbgcRemCookiePath + ';secure=' + iamvar_vbgcRemCookieSecure + ';comment=deleting from ui'
                        }
                        commit('setLockedUserName', state.callbacks[0].input[0].value)
                        var userTypeOptions = errorData.headers['x-fram-optionss'];
                        dispatch('redirectAccountLockedUser', userTypeOptions)
                    } else if ((lockState == "ACCOUNT_LOGIN_ABANDONMENT") && (state.optionMethod != '')) {
                        for (var i = 0; i < iamvar_vbgcRemCookieDomain.length; i++) {
                            document.cookie = iamvar_makeDefaultMethodName + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=' + iamvar_vbgcRemCookieDomain[i] + ';path=' + iamvar_vbgcRemCookiePath + ';secure=' + iamvar_vbgcRemCookieSecure + ';comment=deleting from ui'
                        }
                        commit('setLockedUserName', state.callbacks[0].input[0].value)
                        var userTypeOptions = errorData.headers['x-fram-optionss'];
                        dispatch('redirectAccountLockedUser', userTypeOptions)
                    } else if (lockState == "ACCOUNT_PUSH_LOCKED") {
                        state.pwdAuthOptions[0].disable = true
                    } else if (lockState == "ACCOUNT_VET_LOCKED") {
                        state.pwdAuthOptions[1].disable = true
                    } else if (lockState == "ACCOUNT_LOGIN_LOCKED" || lockState == "OTP_COUNTER_AND_PROFILE_INVALID_ATTEMPTS_COUNTE_MISMATCH") {
                        commit('setLockedUserName', state.callbacks[0].input[0].value)
                        var userTypeOptions = errorData.headers['x-fram-optionss'];
                        dispatch('redirectAccountLockedUser', userTypeOptions)
                    } else if (lockState == "ACCOUNT_LOGIN_ABANDONMENT") {
                        commit('setLockedUserName', state.callbacks[0].input[0].value)
                        var userTypeOptions = errorData.headers['x-fram-optionss'];
                        dispatch('redirectAccountLockedUser', userTypeOptions)
                    } /*else if(state.optionMethod == 'opt1') {
                        let payload = {
                            userName: state.pwdUsername,
                        };
                        dispatch('submitPwdLessPushLogin', payload)
                    } else if(state.optionMethod == 'opt2') {
                        state.callbacks[0].input[0].value = state.pwdUsername
                        state.callbacks[1].input[0].value = ''
                        let payload = {
                            formData: state.callbacks,
                        };
                        dispatch('submitPwdAMPasswordForm', payload)
                    } else if(state.optionMethod == 'opt3') {
                        router.push({ name: 'PwdLessPassword'})
                    }*/
                }

                var authResponse = response.data
                if ((authResponse.callbacks[0].output[0].value) == "SEGI") {
                    //router.push({ name: 'PwdLessPassword'})
                    commit('setRedirectToPasswordPage', true)
                } else if ((authResponse.callbacks[0].output[0].value) == "SEGP" || (authResponse.callbacks[0].output[0].value) == "SEGP_NLBE") {
                    //option 1, 3
                    state.pwdAuthOptions[0].available = authResponse.callbacks[0].output[0].value == "SEGP"
                    //state.pwdAuthOptions[1].available = false
                    //state.pwdAuthOptions[2].available = true
                    if (state.optionMethod == 'opt1') {
                        if (state.pwdAuthOptions[0].disable != true) {
                            let payload = {
                                userName: state.pwdUsername,
                            };
                            commit('setDefaultMethod', true)
                            dispatch('submitPwdLessPushLogin', payload)
                        } else {
                            commit('setLoginAnotherWayEnable', true)
                            commit('setRedirectToOptionsPage', true)
                        }
                    } else if (state.optionMethod == 'opt2') {
                        for (var i = 0; i < iamvar_vbgcRemCookieDomain.length; i++) {
                            document.cookie = iamvar_makeDefaultMethodName + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=' + iamvar_vbgcRemCookieDomain[i] + ';path=' + iamvar_vbgcRemCookiePath + ';secure=' + iamvar_vbgcRemCookieSecure + ';comment=deleting from ui'
                        }
                        commit('setLoginAnotherWayEnable', true)
                        commit('setRedirectToOptionsPage', true)
                    } else if (state.optionMethod == 'opt3') {
                        commit('setDefaultMethod', true)
                        //router.push({ name: 'PwdLessPassword'})
                        commit('setRedirectToPasswordPage', true)
                    } else {
                        commit('setLoginAnotherWayEnable', true)
                        commit('setRedirectToOptionsPage', true)
                    }
                } else if ((authResponse.callbacks[0].output[0].value) == "SEGV") {
                    //option 2, 3
                    state.pwdAuthOptions[0].available = false
                    //state.pwdAuthOptions[1].available = true
                    //state.pwdAuthOptions[2].available = true
                    if (state.optionMethod == 'opt1') {
                        for (var i = 0; i < iamvar_vbgcRemCookieDomain.length; i++) {
                            document.cookie = iamvar_makeDefaultMethodName + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=' + iamvar_vbgcRemCookieDomain[i] + ';path=' + iamvar_vbgcRemCookiePath + ';secure=' + iamvar_vbgcRemCookieSecure + ';comment=deleting from ui'
                        }
                        commit('setLoginAnotherWayEnable', true)
                        commit('setRedirectToOptionsPage', true)
                    } else if (state.optionMethod == 'opt2') {
                        if (state.pwdAuthOptions[1].disable != true) {
                            state.callbacks[0].input[0].value = state.pwdUsername
                            state.callbacks[1].input[0].value = ''
                            let payload = {
                                formData: state.callbacks,
                            };
                            commit('setDefaultMethod', true)
                            dispatch('submitPwdAMPasswordForm', payload)
                        } else {
                            commit('setLoginAnotherWayEnable', true)
                            commit('setRedirectToOptionsPage', true)
                        }
                    } else if (state.optionMethod == 'opt3') {
                        commit('setDefaultMethod', true)
                        //router.push({ name: 'PwdLessPassword'})
                        commit('setRedirectToPasswordPage', true)
                    } else {
                        commit('setLoginAnotherWayEnable', true)
                        commit('setRedirectToOptionsPage', true)
                    }
                } else if ((authResponse.callbacks[0].output[0].value) == "SEGB" || (authResponse.callbacks[0].output[0].value) == "SEGB_NLBE") {
                    //option 1, 2, 3
                    state.pwdAuthOptions[0].available = authResponse.callbacks[0].output[0].value == "SEGB"
                    //state.pwdAuthOptions[1].available = true
                    //state.pwdAuthOptions[2].available = true
                    //state.pwdAuthOptions[0].disable = false
                    //state.pwdAuthOptions[1].disable = false
                    //state.pwdAuthOptions[2].disable = false
                    if (state.optionMethod == 'opt1') {
                        if (state.pwdAuthOptions[0].disable != true) {
                            let payload = {
                                userName: state.pwdUsername,
                            };
                            commit('setDefaultMethod', true)
                            dispatch('submitPwdLessPushLogin', payload)
                        } else {
                            commit('setLoginAnotherWayEnable', true)
                            commit('setRedirectToOptionsPage', true)
                        }
                    } else if (state.optionMethod == 'opt2') {
                        if (state.pwdAuthOptions[1].disable != true) {
                            state.callbacks[0].input[0].value = state.pwdUsername
                            state.callbacks[1].input[0].value = ''
                            let payload = {
                                formData: state.callbacks,
                            };
                            commit('setDefaultMethod', true)
                            dispatch('submitPwdAMPasswordForm', payload)
                        } else {
                            commit('setLoginAnotherWayEnable', true)
                            commit('setRedirectToOptionsPage', true)
                        }
                    } else if (state.optionMethod == 'opt3') {
                        commit('setDefaultMethod', true)
                        //router.push({ name: 'PwdLessPassword'})
                        commit('setRedirectToPasswordPage', true)
                    } else {
                        commit('setLoginAnotherWayEnable', true)
                        commit('setRedirectToOptionsPage', true)
                    }
                } else if ((authResponse.callbacks[0].output[0].value) == "SEGO") {
                    //password page
                    //var returnUrl = getHostName(window.location.href);
                    //window.location.href = "https://" + returnUrl + "/account/business/login/password";
                    state.pwdAuthOptions[0].available = false
                    state.pwdAuthOptions[0].disable = true
                    state.pwdAuthOptions[1].disable = true
                    if (state.optionMethod == 'opt3') {
                        commit('setDefaultMethod', true)
                        //router.push({ name: 'PwdLessPassword'})
                    }
                    commit('setRedirectToPasswordPage', true)
                } else if ((authResponse.callbacks[0].output[0].value) == "SEGOP") {
                    // if SDAR user and status is pend_sdar call get-transaction API
                    // sdar user status
                    const sdarUserStatus = response.headers['x-vz-auth-status'];
                    if(iamvar_polarisFlowToggle && sdarUserStatus) {
                        var ott = response.headers[iamvar_vbgcCookieHeaderName];
                        var userType = response.headers['x-vz-usertype'];
                        commit('setUserType', userType);
                        dispatch('updateLineOfBusiness', response.headers['x-fram-optionss']);
                        setOTTInClientLibForCreatingSession(ott);
                        commit('setVBGCCookie', ott);
                        dispatch('startPendSdarFlow', sdarUserStatus);
                    } else {
                        //password page
                        state.pwdAuthOptions[0].disable = true
                        state.pwdAuthOptions[1].disable = true
                        if(state.optionMethod == 'opt3') {
                            commit('setDefaultMethod', true)
                            //router.push({ name: 'PwdLessPassword'})
                        }
                        //router.push({ name: 'PwdLessPassword'})
                        commit('setRedirectToPasswordPage', true)
                    }
                    
                } else if ((authResponse.callbacks[0].output[0].value) == "ACCOUNT_UNLOCK_ALREADY_REQUESTED") {
                    commit('setAlertType', 'error')
                    commit('setAuthError', { errorCode: "ACCOUNT_UNLOCK_ALREADY_REQUESTED", errorMessage: translations.en.message['am_unified_account_unlock_already_requested'] })
                    commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_account_unlock_already_requested'] }])
                    errorSet("You already requested to unlock your account. Check your email to unlock your account.");
                }
                commit('setLoader', false)
            }).catch((error) => {
                var errorData = error.response
                if (errorData.status == 417) {
                    if (errorData.data) {
                        // document.write(errorData.data);
                        if (docHTML) {
                            docHTML[0].innerHTML = errorData.data;
                        }
                        commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: errorData.data }])
                    }
                    else {
                        commit('setAlertType', 'error')
                        commit('setAuthError', { errorMessage: translations.en.message['bckend_unknown_err'] })
                        commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: '<b>An error occurred.</b>' }])
                        errorSet('An error occurred.');
                    }
                }
                if (errorData.status == 400 || errorData.status == 401) {
                    var date = new Date()
                    date.setTime(date.getTime() + (iamvar_vbgcCookieExpiryInMins * 60 * 1000))
                    var myDate = date.toUTCString()
                    var ott = errorData.headers[iamvar_vbgcCookieHeaderName]
                    var userType = errorData.headers['x-vz-usertype']
                    commit('setUserType', userType)
                    dispatch('updateLineOfBusiness', errorData.headers['x-fram-optionss'])
                    setOTTInClientLibForCreatingSession(ott)
                    commit('setVBGCCookie', ott)
                    dispatch('cvsstepup/updateVBGCCookieValue', ott, { root: true })
                    // for(var i = 0; i< iamvar_vbgcCookieDomain.length; i++) {
                    //     document.cookie = encodeURIComponent(iamvar_vbgcCookieName) +'=' + encodeURIComponent(ott) + ';'+ 'expires'+'=' +myDate + ';'+'domain'+'='+ iamvar_vbgcCookieDomain[i] + ';'+ 'path'+'=' + iamvar_vbgcCookiePath+ ';'+'secure'+'=' + iamvar_vbgcCookieSecure

                    // }
                    dispatch('cvsstepup/startPageTimer', {}, { root: true })
                    //commit('setLoader', true)
                    dispatch('checkHeaderForredirect', errorData)
                } else {
                    // logger.error('Error in submitting user device fingerprint '+error)
                    commit('setLoader', false)

                    throw error
                }
                commit('setLoader', false)
            })
        },
        // for errors that are not handled, we need to send the request, response, and a brief explanation of what was expected to the debug API
        /** Parameters  
         * request: { reqUrl, reqBody, reqHeaders }
         * response: { error returned by call's catch }
         * explanation: string explaining the expected behavior
        */
        sendUnhandledErrorToBackend({ commit }, payload) {
            let unhandledErrorObject = {};
            let debugApiClient = axios.create({
                baseURL: iamvar_serverUrl
            });

            unhandledErrorObject.request = payload.request;
            unhandledErrorObject.response = payload.response;
            unhandledErrorObject.expected = payload.explanation;

            //debugApiClient.post('/account/debug/info?output=json', unhandledErrorObject).then(function(res) {
            //    console.log('Error log successful ', res);
            //}).catch(function(err) {
            //    console.log('Error log error ', err);
            //});
        },
        authenticateMobileUser({ commit, dispatch, state }, payload) {
            var username = payload.data.callbacks[0].input[0].value
            var password = payload.data.callbacks[1].input[0].value
            var reqUrl = 'json/realms/mobile/authenticate?authIndexType=service&authIndexValue=userservice&goto=' + state.gotoUrl

            dispatch('sanitizePasswordForErrorLogging', payload.data)
            // If user select MyBiz/VEC on unifiedportal, making "authenticate" call with additional header parameter as "VBG_LOGIN" respectively.
            let option = state.options
            if (payload.additionalAuth) {
                option.headers['X-VZ-AUTH-HYBRID'] = payload.additionalAuth
            }
            if (payload.captchaAuth) {
                option.headers['X-VZ-ILOGIN'] = option.captchaAuth
            }

            if (document.cookie.indexOf(iamvar_vzLDFP) !== -1) {
                var cookieLegacyDFP = getCookie(iamvar_vzLDFP)
            }
            if (document.cookie.indexOf(iamvar_vzDFP) !== -1) {
                var cookieDFP = getCookie(iamvar_vzDFP)
            }

            if (cookieLegacyDFP) {
                option.headers[iamvar_vzLDFP] = cookieLegacyDFP
            }
            if (cookieDFP) {
                option.headers[iamvar_vzDFP] = cookieDFP
            }

            document.cookie = 'iamvar_realm=MOBILE' + ';path=' + iamvar_vbgcRemCookiePath + ';secure=' + iamvar_vbgcRemCookieSecure + ';comment=setting in ui';
            // encoding username and password to base64 
            // let payloadEnc = JSON.parse(JSON.stringify(payload.data));
            // payloadEnc.callbacks[0].input[0].value = btoa(payloadEnc.callbacks[0].input[0].value);
            // payloadEnc.callbacks[1].input[0].value = btoa(payloadEnc.callbacks[1].input[0].value);
            return amrest.post(reqUrl, payload.data, option).then(function (response) {
                var authResponse = response.data
                // Set remember me cookie only if the user has checked the remember me check box
                if (payload.rememberUser) {
                    dispatch('encryptUsername', username)
                }
                else {
                    //delete vbgremc cookie if the remember me check box is unchecked
                    document.cookie = iamvar_vbgcRemCookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                }
                if (iamvar_sendDFP) {
                    var deviceFingerprint = getDeviceFingerprintInfoWithoutGeolocation()
                    authResponse.callbacks[0].input[0].value = deviceFingerprint.value
                    dispatch('sendMobileDeviceFingerprint', authResponse)
                } else {
                    constructSession(response, state.gotoUrl);
                    //window.location.href = response.data.successUrl;
                    //dispatch('sendMobileDeviceFingerprint', authResponse)
                }
            }).catch((error) => {
                commit('setLoader', false)
                var errorData = error.response
                var eventPayload = {};

                if (errorData.status == 417) {
                    // document.write(errorData.data);
                    if (docHTML) {
                        docHTML[0].innerHTML = errorData.data;
                    }
                }

                if (errorData) {
                    dispatch('updateLineOfBusiness', errorData.headers['x-fram-optionss'])
                    if (errorData.headers[iamvar_authErrorHeaderName]) {
                        // logger.warn('User entered incorrect credentials')
                        var errorStatus = JSON.parse(errorData.headers[iamvar_authErrorHeaderName])
                        if(errorData.headers[iamvar_Vcg_Throttled_On]) {
                            dispatch('updateVcgThrotteledOn', true)
                        }
                        if (errorStatus.authStatus == false) {

                            if (errorStatus.errorCode === "INVALID_CREDENTIALS") {
                                // INVALID_CREDENTIALS  
                                let hostUrl = window.location.href
                                if (hostUrl.includes('/account/business/odasignin')) {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_mobile_login_lock_prevention'] })
                                    errorSet('If you are having trouble logging in visit sso.verizonenterprise.com from a device that is already connected to the internet and select Forgot username or password. If you do not have a My Business login, please contact your administrator for assistance.');
                                } else {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_invalid_creds'] })
                                    commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_error_invalid_creds'] }])
                                    errorSet('One or more of the values you have entered is incorrect.Please verify your login information and try again.');
                                }
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_PRE_PREVENTION") {
                                //for 3rd invalid attempt in unified exp
                                let hostUrl = window.location.href
                                if (hostUrl.includes('/account/business/odasignin')) {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_mobile_login_lock_prevention'] })
                                    errorSet('If you are having trouble logging in visit sso.verizonenterprise.com from a device that is already connected to the internet and select Forgot username or password. If you do not have a My Business login, please contact your administrator for assistance.');
                                } else {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_invalid_creds'] })
                                    commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_error_login_lock_pre_prevention'] }])
                                    errorSet('One or more of the values you have entered is incorrect.To reset, try Forgot user ID or Forgot password.');
                                }
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_PREVENTION") {
                                //for 4th invalid attempt in unified exp
                                let hostUrl = window.location.href
                                if (hostUrl.includes('/account/business/odasignin')) {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_mobile_login_lock_prevention'] })
                                    errorSet('If you are having trouble logging in visit sso.verizonenterprise.com from a device that is already connected to the internet and select Forgot username or password. If you do not have a My Business login, please contact your administrator for assistance.');
                                } else {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_login_lock_prevention'] })
                                    commit('setUnifiedLoginAlerts', [{ type: 'newWarning', msg: translations.en.message['am_unified_error_login_lock_prevention'] }])
                                    errorSet('One or more of the values you have entered is incorrect.To reset, try Forgot user ID or Forgot password.');
                                    commit('setUnifiedErrorCode', errorStatus.errorCode);
                                }
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_INACTIVE_AUTH_STEPS_REQUIRED") {
                                commit('setAuthError', { errorMessage: "The User ID is unable to perform the necessary steps at this time. Please contact one of the following for further assistance:<ul><li> • Company's Administrator</li><li> • Verizon Customer Service at 1-800-922-0204</li><li> • WITS/ Service@once users only: [800 381 3444|tel:8003813444] option 6 </li><li> • US and Canada Wireline Customers only: 800.569.8799 (M-F 9AM-6PM ET) Live Chat (Sun 8pm- Sat 8pm ET) </li><li> • EMEA, APAC, LATAM Wireline Customers only: 00.800.4321.5432 (M-F 9AM-5PM GMT) vec-support@verizon.com </li>" })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: "<b>The User ID is unable to perform the necessary steps at this time. Please contact one of the following for further assistance:<ul><li> • Company's Administrator</li><li> • Verizon Customer Service at 1-800-922-0204</li><li> • WITS/ Service@once users only: [800 381 3444|tel:8003813444] option 6 </li><li> • US and Canada Wireline Customers only: 800.569.8799 (M-F 9AM-6PM ET) Live Chat (Sun 8pm- Sat 8pm ET) </li><li> • EMEA, APAC, LATAM Wireline Customers only: 00.800.4321.5432 (M-F 9AM-5PM GMT) vec-support@verizon.com </li></b>" }])
                                errorSet("The User ID is unable to perform the necessary steps at this time. Please contact one of the following for further assistance:<ul><li> • Company's Administrator</li><li> • Verizon Customer Service at 1-800-922-0204</li><li> • WITS/ Service@once users only: [800 381 3444|tel:8003813444] option 6 </li><li> • US and Canada Wireline Customers only: 800.569.8799 (M-F 9AM-6PM ET) Live Chat (Sun 8pm- Sat 8pm ET) </li><li> • EMEA, APAC, LATAM Wireline Customers only: 00.800.4321.5432 (M-F 9AM-5PM GMT) vec-support@verizon.com </li>")
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_LOCKED") {
                                //for 5th invalid attempt in unified exp
                                let hostUrl = window.location.href
                                if (hostUrl.includes('/account/business/odasignin')) {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_mobile_login_locked'] })
                                    errorSet('Your account is locked. Visit sso.verizonenterprise.com from a device that is already connected to the internet or contact your administrator for assistance.');
                                } else {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', false)
                                    commit('setUnifiedLoginAlerts', [])
                                    errorSet('Your account is locked.To reset, try Forgot password or try our support page.');
                                    commit('setUnifiedErrorCode', errorStatus.errorCode);
                                    commit('setLockedUserName', state.callbacks[0].input[0].value)
                                    var userTypeOptions = errorData.headers['x-fram-optionss'];
                                    dispatch('redirectAccountLockedUser', userTypeOptions)
                                }
                            }
                            else if (errorStatus.errorCode === "INVALID_CREDENTIALS_PWD_EXPIRED") { // INVALID_CREDENTIALS_PWD_EXPIRED_ERROR_CODE
                                let hostUrl = window.location.href
                                if (hostUrl.includes('/account/business/odasignin')) {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_mobile_login_locked'] })
                                    errorSet('Your account is locked. Visit sso.verizonenterprise.com from a device that is already connected to the internet or contact your administrator for assistance.');
                                } else {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', false)
                                    commit('setUnifiedLoginAlerts', [])
                                    errorSet('Inactive or Expired User ID/Password.Please select the Forgot Password link below to reactivate your User ID and reset your password.');
                                    commit('setLockedUserName', state.callbacks[0].input[0].value)
                                    var userTypeOptions = errorData.headers['x-fram-optionss'];
                                    dispatch('redirectAccountLockedUser', userTypeOptions)
                                }
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_NEWLY_LOGIN_LOCKED") // for ACCOUNT_NEWLY_LOGIN_LOCKED user should redirect to forgot password
                            {
                                let url = window.location.href
                                if (url.includes('/account/business/odasignin')) {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_mobile_login_locked'] })
                                    errorSet('Your account is locked. Visit sso.verizonenterprise.com from a device that is already connected to the internet or contact your administrator for assistance.');
                                } else {
                                    commit('setAuthError', false)
                                    commit('setUnifiedLoginAlerts', [])
                                    errorSet('Your account is locked.To reset, try Forgot password or try our support page.');
                                    commit('setUnifiedErrorCode', errorStatus.errorCode);
                                    commit('setLockedUserName', state.callbacks[0].input[0].value)
                                    var userTypeOptions = errorData.headers['x-fram-optionss'];
                                    dispatch('redirectAccountLockedUser', userTypeOptions)
                                }
                            }
                            else if (errorStatus.errorCode === "INVALID_CREDENTIALS_PWD_RESET") { // INVALID_CREDENTIALS_PWD_RESET_ERROR_CODE
                                let hostUrl = window.location.href
                                if (hostUrl.includes('/account/business/odasignin')) {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_pwd_reset_required'] })
                                    errorSet('You are required to change your password.Please click the Forgot Password link to reset your password.');
                                } else {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', false)
                                    commit('setUnifiedLoginAlerts', [])
                                    errorSet('You are required to change your password.Please click the Forgot Password link to reset your password.');
                                    commit('setLockedUserName', state.callbacks[0].input[0].value)
                                    var userTypeOptions = errorData.headers['x-fram-optionss'];
                                    dispatch('redirectAccountLockedUser', userTypeOptions)
                                }
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_LOGIN_LOCKED" || errorStatus.errorCode === "OTP_COUNTER_AND_PROFILE_INVALID_ATTEMPTS_COUNTE_MISMATCH") { // ACCOUNT_LOGIN_LOCKED_ERROR_CODE
                                let hostUrl = window.location.href
                                if (hostUrl.includes('/account/business/odasignin')) {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_mobile_login_locked'] })
                                    errorSet('Your account is locked. Visit sso.verizonenterprise.com from a device that is already connected to the internet or contact your administrator for assistance.');
                                } else {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', false)
                                    commit('setUnifiedLoginAlerts', [])
                                    errorSet('Your account is locked.To reset, try Forgot password or try our support page.');
                                    commit('setUnifiedErrorCode', errorStatus.errorCode);
                                    commit('setLockedUserName', state.callbacks[0].input[0].value)
                                    var userTypeOptions = errorData.headers['x-fram-optionss'];
                                    dispatch('redirectAccountLockedUser', userTypeOptions)
                                }
                            }
                            else if (errorStatus.errorCode == "ACCOUNT_COMPROMIZED") {
                                router.push({ name: 'ResetPassword', params: { lockType: 'resetSecretAnswer' } })
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_LOCK_PREVENTION") { // ACCOUNT_LOGIN_LOCK_PREVENTION_ERROR_CODE 
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorMessage: translations.en.message['am_error_login_lock_prevention'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newWarning', msg: translations.en.message['am_unified_error_login_lock_prevention'] }])
                                errorSet('One or more of the values you have entered is incorrect.To reset, try Forgot user ID or Forgot password.');
                                commit('setUnifiedErrorCode', errorStatus.errorCode);
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_LOCK_PRE_PREVENTION") { // ACCOUNT_LOGIN_LOCK_PRE_PREVENTION_ERROR_CODE FOR THIRD ATTEMPT
                                let hostUrl = window.location.href
                                if (hostUrl.includes('/account/business/odasignin')) {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_mobile_login_lock_prevention'] })
                                    errorSet('If you are having trouble logging in visit sso.verizonenterprise.com from a device that is already connected to the internet and select Forgot username or password. If you do not have a My Business login, please contact your administrator for assistance.');
                                } else {
                                    commit('setAlertType', 'error')
                                    commit('setAuthError', { errorMessage: translations.en.message['am_error_login_lock_pre_prevention'] })
                                    commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_error_login_lock_pre_prevention'] }])
                                    errorSet('One or more of the values you have entered is incorrect.To reset, try Forgot user ID or Forgot password.');
                                }
                            }
                            else if (errorStatus.errorCode === "APP_GROUP_MISMATCH") { // ACCOUNT_LOGIN_APP_GROUP_USER_MISMATCH_ERROR_CODE
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorMessage: translations.en.message['am_error_app_usr_mismatch'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_error_app_usr_mismatch'] }])
                                errorSet('The user you have selected is not allowed to log into the application from this page.Please contact your administrator to verify your login url.');
                            }
                            else if (errorStatus.errorCode === "FEDERATED_USER") { // ACCOUNT_LOGIN_FEDERATED_USER_ERROR_CODE
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorMessage: translations.en.message['am_error_federated_usr'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_error_federated_usr'] }])
                                errorSet("To log into Verizon Enterprise Center, please access it via your company 's website.Your user ID and password are safely stored in our system but you don 't need to enter them to log in. Your ability to log into Verizon Enterprise Center directly has been blocked at your company 's request.")
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_FRAUD_LOCKED") { // ACCOUNT_FRAUD_LOCKED_ERROR_CODE 
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorMessage: translations.en.message['am_error_fraud_locked'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_error_fraud_locked'] }])
                                errorSet('Locked User ID. This user id has been locked and requires Customer Service assistance to be unlocked.Please contact Customer Service to unlock.')
                            }
                            else if (errorStatus.errorCode === "ACCOUNT_POC_DUSER_BLOCKED") { // ACCOUNT_POC_DUSER_BLOCKED_ERROR_CODE 
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorMessage: translations.en.message['poc_dormant_user_blocked'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['poc_dormant_user_blocked'] }])
                                errorSet("Since you haven't logged in for over 3 years, we’re emailing your company administrator to get approval for security purposes. Once approved, you’ll get an email notification")
                            }
                            else if (errorStatus.errorCode === "PCOAE_ACCOUNT_FRAUD_LOCKED") { // PCOAE_ACCOUNT_FRAUD_LOCKED_ERROR_CODE 
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorMessage: translations.en.message['login_flows_locked_ecpd_admin_user'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['login_flows_locked_ecpd_admin_user'] }])
                                errorSet("Your account has been locked. Please contact 1-888-483-7200 for help unlocking your account.")
                            }
                            else if (errorStatus.errorCode === "NPCOAE_ACCOUNT_FRAUD_LOCKED") { // NPCOAE_ACCOUNT_FRAUD_LOCKED_ERROR_CODE 
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorMessage: translations.en.message['login_flows_locked_ecpd_non_admin_user'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['login_flows_locked_ecpd_non_admin_user'] }])
                                errorSet("Your account has been locked. Your company administrator has been notified how to unlock the account.")
                            }
                            else if (errorStatus.errorCode === "REDIRECT_TO_IDM_REQUIRED") { // userType == VZW
                                commit('setAlertType', 'information')

                                let urlParams = window.location.search
                                let idmLoginRedirectUrl = iamvar_idmSsoLoginUrl + urlParams
                                commit('setIdmRedirectUrl', idmLoginRedirectUrl)

                                commit('setAuthError', { errorMessage: translations.en.message['redirect_to_idm_err'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'information', msg: `<b>Thank you for being a Verizon business customer.</b><br/>You have reached this page in error, You will be redirected to Verizon business login page in 10 secs or you can <a href="${idmLoginRedirectUrl}" style="color: white; text-decoration: underline;"> click here </a>` }])

                                // redirect to IDM
                                setTimeout(() => {
                                    window.location.replace(idmLoginRedirectUrl)
                                }, 10000)
                            } else if (errorStatus.errorCode === "REDIRECT_TO_COMPLETE_REGISTRATION_REQUIRED") {
                                let regData = errorStatus.registrationToken;
                                window.location.href = mixin.methods.sanitizeUrl(iamvar_mbt_base_url + "/mbt/userregistration/enroll?regData=" + regData + "#/enroll");
                            } else if (errorStatus.errorCode === "INTERNAL_SERVER_ERROR") { // INTERNAL_SERVER_ERROR_CODE 
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorMessage: translations.en.message['am_error_internal_err'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_error_internal_err'] }])
                                errorSet('An error occurred.Please contact your administrator.')
                            } else if (errorStatus.errorCode == "VBM_USER_LOGIN_REDIRECT") { // for VBM User while login we have to  submit a form
                                var f = document.createElement("form");
                                f.setAttribute('method', "post");
                                f.setAttribute('action', iamvar_vbm_login_redirect);
                                f.setAttribute('id', "welcomeBasket");
                                f.setAttribute('name', "welcomeBasket1");

                                var i1 = document.createElement("input"); //input element, text
                                i1.setAttribute('type', "hidden");
                                i1.setAttribute('name', "realm");
                                i1.setAttribute('value', "vzw");

                                var i2 = document.createElement("input"); //input element, text
                                i2.setAttribute('type', "hidden");
                                i2.setAttribute('name', "module");
                                i2.setAttribute('value', "ResidentialLoginModule");

                                var i3 = document.createElement("input"); //input element, text
                                i3.setAttribute('type', "hidden");
                                i3.setAttribute('name', "gx_charset");
                                i3.setAttribute('value', "UTF-8");

                                var i4 = document.createElement("input"); //input element, text
                                i4.setAttribute('type', "hidden");
                                i4.setAttribute('name', "userNameOnly");
                                i4.setAttribute('value', "true");

                                var i5 = document.createElement("input"); //input element, text
                                i5.setAttribute('type', "hidden");
                                i5.setAttribute('name', "ignoreReferer");
                                i4.setAttribute('value', "true");

                                var i6 = document.createElement("input"); //input element, text
                                i6.setAttribute('type', "hidden");
                                i6.setAttribute('name', "IDToken1");
                                i6.setAttribute('value', username);

                                var i7 = document.createElement("input"); //input element, text
                                i7.setAttribute('type', "hidden");
                                i7.setAttribute('name', "IDToken2");
                                i7.setAttribute('value', password);

                                var i8 = document.createElement("input"); //input element, text
                                i8.setAttribute('type', "hidden");
                                i8.setAttribute('name', "fromVZT");
                                i8.setAttribute('value', "Y");

                                var i9 = document.createElement("input"); //input element, text
                                i9.setAttribute('type', "hidden");
                                i9.setAttribute('name', "clientId");
                                i9.setAttribute('value', "vecportal");

                                var i10 = document.createElement("input"); //input element, text
                                i10.setAttribute('type', "hidden");
                                i10.setAttribute('name', "customerType");
                                i10.setAttribute('value', "BU");

                                var i11 = document.createElement("input"); //input element, text
                                i11.setAttribute('type', "hidden");
                                i11.setAttribute('name', "goto");
                                i11.setAttribute('value', iamvar_vbm_formsso);

                                var i12 = document.createElement("input"); //input element, text
                                i12.setAttribute('type', "hidden");
                                i12.setAttribute('name', "differentUserUrl");
                                i12.setAttribute('value', iamvar_vbm_redirection);

                                f.appendChild(i1);
                                f.appendChild(i2);
                                f.appendChild(i3);
                                f.appendChild(i4);
                                f.appendChild(i5);
                                f.appendChild(i6);
                                f.appendChild(i7);
                                f.appendChild(i8);
                                f.appendChild(i9);
                                f.appendChild(i10);
                                f.appendChild(i11);
                                f.appendChild(i12);
                                document.body.appendChild(f);
                                //console.log("f...",f,document.getElementById("welcomeBasket"))
                                document.getElementById("welcomeBasket").submit();
                                // } else if(errorStatus.errorCode == "INVALID_VBG_CREDENTIAL"){
                                //     // opened interstitial page
                                //     commit('setInterstitialPage',true)
                                //     commit('setUnifiedErrorCode',errorStatus.errorCode)
                            } else if (errorStatus.errorCode == "SMB_LOGIN_REDIRECT") {
                                dispatch('smbFormSubmit', { username, password, rememberUser: payload.rememberUser })
                                commit('setUnifiedErrorCode', errorStatus.errorCode)
                            } else if (errorStatus.errorCode == "INVALID_SMB_CREDENTIAL") {
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_error_invalid_creds'] }])
                                commit('setUnifiedErrorCode', errorStatus.errorCode)
                                errorSet('One or more of the values you have entered is incorrect.Please verify your login information and try again.')
                            } else if (errorStatus.errorCode == "VALID_MYBIZ_SMB_CREDENTIAL" || errorStatus.errorCode == "VALID_VEC_SMB_CREDENTIAL") {
                                commit('setInterstitialPage', true)
                                commit('setUnifiedErrorCode', errorStatus.errorCode)
                                const { eventName, eventPayload } = unifiedLoginEvents.INVALID_MYBIZ_SMB_CREDENTIAL

                                // accounts present in adobe-analytics.json
                                eventPayload.accounts["1"] = errorStatus.errorCode == "VALID_MYBIZ_SMB_CREDENTIAL" ? "My Business Wireless" : "Verizon Enterprise Center";

                                eventTracking(eventName, eventPayload)
                            } else if (errorStatus.errorCode == "INVALID_MYBIZ_SMB_CREDENTIAL" || errorStatus.errorCode == "INVALID_VEC_SMB_CREDENTIAL") {
                                commit('setInterstitialPage', true)
                                commit('setUnifiedErrorCode', errorStatus.errorCode)
                                const { eventName, eventPayload } = unifiedLoginEvents.INVALID_MYBIZ_SMB_CREDENTIAL;
                                eventPayload.accounts["1"] = errorStatus.errorCode == "INVALID_MYBIZ_SMB_CREDENTIAL" ? "My Business Wireless" : "Verizon Enterprise Center";


                                eventTracking(eventName, eventPayload)
                            } else if (errorStatus.errorCode == "VALID_MYBIZ_VEC_SMB_CREDENTIAL" || errorStatus.errorCode == "INVALID_MYBIZ_VEC_SMB_CREDENTIAL") {
                                commit('setInterstitialPage', true)
                                commit('setUnifiedErrorCode', errorStatus.errorCode)
                                const { eventName, eventPayload } = unifiedLoginEvents.INVALID_MYBIZ_VEC_SMB_CREDENTIAL;

                                eventTracking(eventName, eventPayload)
                            } else if (errorStatus.errorCode === "ACCOUNT_ALREADY_UNLOCKED") {
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorCode: "ACCOUNT_ALREADY_UNLOCKED", errorMessage: translations.en.message['am_unified_already_unlocked'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_already_unlocked'] }])
                                errorSet("You’ve already unlocked your account.Log in to access your account.");
                                router.push({ path: '/login/unifiedlogin' })
                            } else if (errorStatus.errorCode === "USERID_MISMATCH") {
                                commit('setUnlockMyAccountAlerts', [{ type: 'error', msg: translations.en.message['am_unified_invalid_userId'] }])
                                commit('setUnifiedLoginAlerts', [{ type: 'error', msg: translations.en.message['am_unified_invalid_userId'] }])
                                errorSet("The User ID you entered doesn't match this account.Please use the User ID associated with the account you're unlocking.");
                            } else if (errorStatus.errorCode === "ACCOUNT_UNLOCK_ALREADY_REQUESTED") {
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorCode: "ACCOUNT_UNLOCK_ALREADY_REQUESTED", errorMessage: translations.en.message['am_unified_account_unlock_already_requested'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: translations.en.message['am_unified_account_unlock_already_requested'] }])
                                errorSet("You already requested to unlock your account. Check your email to unlock your account.");
                            } else if (errorStatus.errorCode === "CQA_ACCOUNT_LOCKED" && iamvar_vcgvbgMigration) {
                                if (state.gotoURL) {
                                    router.push({ name: 'ResetPassword', params: { lockType: 'resetSecretAnswer' }, query: { goto: state.gotoURL } })
                                } else {
                                    router.push({ name: 'ResetPassword', params: { lockType: 'resetSecretAnswer' } })
                                }
                            } else {
                                commit('setAlertType', 'error')
                                commit('setAuthError', { errorMessage: translations.en.message['bckend_unknown_err'] })
                                commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: '<b>An error occurred.</b>' }])
                                errorSet('An error occurred.')
                            }
                            setTimeout(function () {
                                commit('setAlertType', 'error')
                                commit('setAuthError', false)
                            }, 10000)

                        }
                    }
                    // logger.error('Error in authenticating the user')
                    //throw error


                    else if (errorData.status == 401) {
                        var date = new Date()
                        date.setTime(date.getTime() + (iamvar_vbgcCookieExpiryInMins * 60 * 1000))
                        var myDate = date.toUTCString()
                        var ott = errorData.headers[iamvar_vbgcCookieHeaderName]
                        var userType = errorData.headers['x-vz-usertype']
                        commit('setUserType', userType)
                        setOTTInClientLibForCreatingSession(ott)
                        commit('setVBGCCookie', ott)
                        dispatch('cvsstepup/updateVBGCCookieValue', ott, { root: true })
                        // for(var i = 0; i< iamvar_vbgcCookieDomain.length; i++) {
                        //     document.cookie = encodeURIComponent(iamvar_vbgcCookieName) +'=' + encodeURIComponent(ott) + ';'+ 'expires'+'=' +myDate + ';'+'domain'+'='+ iamvar_vbgcCookieDomain[i] + ';'+ 'path'+'=' + iamvar_vbgcCookiePath+ ';'+'secure'+'=' + iamvar_vbgcCookieSecure

                        // }
                        dispatch('cvsstepup/startPageTimer', {}, { root: true })
                        commit('setLoader', true)
                        dispatch('checkHeaderForredirect',errorData)
                    }
                }

                else {
                    // generic network error
                    commit('setAlertType', 'error')
                    commit('setAuthError', { errorMessage: translations.en.message['bckend_unknown_err'] })
                    commit('setUnifiedLoginAlerts', [{ type: 'newError', msg: '<b>An error occurred.</b>' }])
                    errorSet('An error occurred.')

                    // log error
                    let requestObject = { reqUrl: reqUrl, reqBody: state.sanitizedAuthenticationPayload, reqHeaders: state.options }
                    let explanation = "This call should return the userID/password validation"

                    let unhandledErrObj = { request: requestObject, response: error, explanation: explanation }

                    dispatch('sendUnhandledErrorToBackend', unhandledErrObj)
                }
                commit('setLoader', false)
                throw error;
            })
        },
        sendMobileDeviceFingerprint({ commit, state, dispatch }, payload) {
            // logger.http('Submitting user device fingerprint to AM')
            let option = state.options
            if (document.cookie.indexOf(iamvar_vzLDFP) !== -1) {
                var cookieLegacyDFP = getCookie(iamvar_vzLDFP)
            }
            if (document.cookie.indexOf(iamvar_vzDFP) !== -1) {
                var cookieDFP = getCookie(iamvar_vzDFP)
            }

            if (cookieLegacyDFP) {
                option.headers[iamvar_vzLDFP] = cookieLegacyDFP
            }
            if (cookieDFP) {
                option.headers[iamvar_vzDFP] = cookieDFP
            }

            return amrest.post('json/realms/mobile/authenticate?authIndexType=service&authIndexValue=userservice&goto=' + state.gotoUrl, payload, state.options).then(function (response) {
                dispatch('sendSaveFingerPrintResponse', response)
                if (response.headers['mergestatus'] == "true") {
                    //call an api then on success of that api I need to show popup
                    commit('setLoader', false)
                    dispatch('UpdatePopupValue', 'combinenowPopupVal')
                } else {
                    constructSession(response, state.gotoUrl);
                }
            }).catch((error) => {
                var errorData = error.response
                if (errorData.status == 417) {
                    // document.write(errorData.data);
                    if (docHTML) {
                        docHTML[0].innerHTML = errorData.data;
                    }
                }
                if (errorData.status == 400 || errorData.status == 401) {
                    var date = new Date()
                    date.setTime(date.getTime() + (iamvar_vbgcCookieExpiryInMins * 60 * 1000))
                    var myDate = date.toUTCString()
                    var ott = errorData.headers[iamvar_vbgcCookieHeaderName]
                    var userType = errorData.headers['x-vz-usertype']
                    dispatch('updateLineOfBusiness', errorData.headers['x-fram-optionss'])
                    commit('setUserType', userType)
                    setOTTInClientLibForCreatingSession(ott)
                    commit('setVBGCCookie', ott)
                    dispatch('cvsstepup/updateVBGCCookieValue', ott, { root: true })
                    // for(var i = 0; i< iamvar_vbgcCookieDomain.length; i++) {
                    //     document.cookie = encodeURIComponent(iamvar_vbgcCookieName) +'=' + encodeURIComponent(ott) + ';'+ 'expires'+'=' +myDate + ';'+'domain'+'='+ iamvar_vbgcCookieDomain[i] + ';'+ 'path'+'=' + iamvar_vbgcCookiePath+ ';'+'secure'+'=' + iamvar_vbgcCookieSecure

                    // }
                    dispatch('cvsstepup/startPageTimer', {}, { root: true })
                    commit('setLoader', true)
                    dispatch('checkHeaderForredirect',errorData)
                } else {
                    // logger.error('Error in submitting user device fingerprint '+error)
                    commit('setLoader', false)

                    // log unhandled error
                    let requestObject = { reqUrl: reqUrl, reqBody: state.sanitizedAuthenticationPayload, reqHeaders: state.options }
                    let explanation = "This call should return the if the device fingerprint is recognized or not"

                    let unhandledErrObj = { request: requestObject, response: error, explanation: explanation }

                    dispatch('sendUnhandledErrorToBackend', unhandledErrObj)

                    throw error
                }
            })
        },
        sanitizePasswordForErrorLogging({ commit }, payload) {
            // Make a copy of the payload
            let sanitizedPayload = JSON.parse(JSON.stringify(payload));
            if (payload.callbacks != null) {
                // Remove password from the payload. This is used when logging unhandled errors
                sanitizedPayload.callbacks[1].input[0].value = "******";
            }

            commit('setSanitizedAuthenticationPayload', sanitizedPayload);
        },
        async redirectToOpenam({commit, state, dispatch }, payload) {
            dispatch('cvsstepup/setPageloader', true, { root: true })
            if(iamvar_polarisFlowToggle && payload && payload.includes("PEND_SDAR")) {
                // sending usertype back to AM when creating the session
                let ott =  await createNewSession({type: 'flowtype', value: payload});
                if(ott) {
                    commit('setVBGCCookie', ott);
                    dispatch('cvsstepup/updateVBGCCookieValue', ott, { root: true });
                    router.push({name: 'ResetSecurityProfileRootComponent'})
                }
            } else {
                createNewSession()
            }
        },
        UpdatePopupValue({ commit }, payload) {
            commit('setPopupValue', payload)
        },
        sendSaveFingerPrintResponse({ commit }, payload) {
            commit('setSaveFingerPrintResponse', payload)
        },
        sendCombinenowValue({ commit }, payload) {
            commit('setCombinenowValue', payload)
        },
        skipfornowCombineNow({ commit, state, dispatch }, payload) {
            // if user coming from my profile to simplify your account page and clicks on skip for now land the user on default page dashboard
            if (state.comingFromProfilePage) {
                dispatch('getSessionInfo')
            } else {
                // if user is coming from popup combine now screen and in simplify you account page he clicks on skip for now then land the user on dashboard
                constructSession(state.saveFingerPrintResponse, state.gotoUrl)
            }
        },
        updateMergeStatus({ commit, state, dispatch }, payload) {
            let restClient = iamPostAuthPlayrest;
            let requestSettings = { headers: {} };
            let restEndpoint = '/mvp2/updateMergeDisplayCount';
            requestSettings.headers.uid = state.initialUsername;
            commit('setLoader', true)
            return restClient.post(restEndpoint, {}, requestSettings).then(function (response) {
                if (payload == 'combinenowClick') {
                    let data = response.data;
                    dispatch('sendCombinenowValue', 'combinenow');
                } else if (payload == 'closePopup') {
                    constructSession(state.saveFingerPrintResponse, state.gotoUrl)
                }
                commit('setLoader', false)
            }).catch((error) => {
                errorSet('Issue redirect the user to combinenow component.');
                dispatch('sendCombinenowValue', 'combinenow');
                commit('setLoader', false)
            })
        },
        sendSimplifyContinue({ commit, state, dispatch }, payload) {
            let restClient = iamPostAuthPlayrest;
            let requestSettings = { headers: {} };
            let restEndpoint = '/mvp2/MergeLdapUpdate';
            let requestPayload = payload;

            commit('setPreferredPortal', payload.preferablePortal);
            requestPayload.logInUserID = state.initialUsername
            requestPayload.myProfile = state.comingFromProfilePage;
            commit('setLoader', true)
            if (requestPayload.logInUserID.toLowerCase() == requestPayload.toBeMergeUserID.toLowerCase()) {
                let sameUserError = translations.en.message['am_combinenow_same_userid_error']
                commit('setCombinenowAlerts', [{ type: 'newError', msg: sameUserError.replace('$0', state.userType === 'VZB' ? 'Wireless (My Business)' : 'Wireline (Verizon Enterprise Center)') }])
                errorSet('Please enter your correct Wireline login credentials to merge your IDs');
            } else {
                return restClient.post(restEndpoint, requestPayload, requestSettings).then(function (response) {
                    //console.log(response)
                    commit('setCombinenowAlerts', [{ type: 'combineNowsuccess', msg: translations.en.message['am_combinenow_success'] }])
                    commit('setUserId', response.data.uuID)
                    commit('setResponseToken', response.headers['token'])
                    dispatch('sendCombinenowValue', 'combinenowSuccess')
                    commit('setLoader', false)
                }).catch((error) => {
                    let errorData = error.response
                    if (errorData.status == 401) {
                        if (errorData.data.error == 'INVALID_CREDENTIALS' && errorData.data.vzwInvalidLoginAttempt > 1) {
                            // if user click second time on continue for same username and again as a response we receive invalid_credential for the second time then redirect to error screen
                            commit('setCombinenowAlerts', [{ type: 'newError', msg: translations.en.message['am_combinenow_second_screen_error'] }])
                            errorSet('We weren’t able to verify your information.');
                            dispatch('sendCombinenowValue', 'combinenowError')
                        }
                        // if(state.invalidCount == 1 && state.toBeMergeUserID == payload.toBeMergeUserID && errorData.data == 'INVALID_CREDENTIALS') {
                        //     // if user click second time on continue for same username and again as a response we receive invalid_credential for the second time then redirect to error screen
                        //     commit('setCombinenowAlerts',[{type:'newError',msg: translations.en.message['am_combinenow_second_screen_error']}])
                        //     errorSet('We weren’t able to verify your information.');
                        //     dispatch('sendCombinenowValue','combinenowError')
                        // }
                        else if (errorData.data.error == 'INVALID_CREDENTIALS') {
                            // commit('setInvalidCount', 1)
                            // commit('setToBeMergeUserID', payload.toBeMergeUserID)
                            commit('setCombinenowAlerts', [{ type: 'newError', msg: translations.en.message['am_combinenow_error_invalid_creds'] }])
                            errorSet('One or more of the values you have entered is incorrect.Please verify your login information and try again.');
                        } else if (errorData.data.error == 'PROFILE_INCOMPLETE' || errorData.data.error == 'ACCOUNT_FRAUD_LOCKED' || errorData.data.error == 'ACCOUNT_OTP_LOCKED') {
                            commit('setCombinenowAlerts', [{ type: 'newError', msg: translations.en.message['am_combinenow_second_screen_error'] }])
                            errorSet('We weren’t able to verify your information.');
                            dispatch('sendCombinenowValue', 'combinenowError')
                        } else if (errorData.data.error == 'ACCOUNT_NEWLY_LOGIN_LOCKED' || errorData.data.error == 'ACCOUNT_LOGIN_LOCKED' || errorData.data.error == 'OTP_COUNTER_AND_PROFILE_INVALID_ATTEMPTS_COUNTE_MISMATCH') {
                            commit('setCombinenowAlerts', [{ type: 'newError', msg: translations.en.message['am_combinenow_account_locked'] }])
                            errorSet('Your account is locked.To reset, try Forgot password or try our support page.');
                            commit('setCombinenowErrorCode', errorData.data.error);
                        } else {
                            commit('setCombinenowAlerts', [{ type: 'newError', msg: translations.en.message['am_combinenow_second_screen_error'] }])
                            errorSet('We weren’t able to verify your information.');
                            dispatch('sendCombinenowValue', 'combinenowError')
                        }
                    } else if (errorData.status == 400) {
                        commit('setCombinenowAlerts', [{ type: 'newError', msg: translations.en.message['am_combinenow_second_screen_error'] }])
                        errorSet('We weren’t able to verify your information.');
                        dispatch('sendCombinenowValue', 'combinenowError')
                    } else {
                        commit('setCombinenowAlerts', [{ type: 'newError', msg: translations.en.message['am_combinenow_second_screen_error'] }])
                        errorSet('We weren’t able to verify your information.');
                        dispatch('sendCombinenowValue', 'combinenowError')
                    }
                    commit('setLoader', false)
                })
            }
        },
        mybizzForget({ commit }, payload) {
            commit('setMybizz', payload)
        },
        updateSetUnifiedLoginAlerts({ commit }, payload) {
            if (payload == 'regcompleted') {
                commit('setUnifiedLoginAlerts', [{ type: 'newWarning', msg: translations.en.message['am_unified_error_regCompleted'] }])
            } else if (payload == 'expiredtoken') {
                commit('setUnifiedLoginAlerts', [{ type: 'newWarning', msg: translations.en.message['am_unified_error_expiredToken'] }])
            } else {
                commit('setUnifiedLoginAlerts', [{ type: 'newWarning', msg: translations.en.message['am_unified_error_invalidToken'] }])
            }
        },
        updateUnifiedLoginAlerts({ commit }, payload) {
            commit('setUnifiedLoginAlerts', payload)
        },
        updateVsbdrForgotAlerts({ commit }, payload) {
            commit('setVsbdrForgotAlerts', payload)
        },
        updateExpireForgotAlerts({ commit }, payload) {
            const errMessagePath = payload == 'en' ? translations.en : translations.es;
            commit('setVsbdrForgotAlerts', { msg: errMessagePath.message['email_link_expired'] })
        },
        updateVsbdrLocale({ commit }, payload) {
            commit('setVsbdrLocale', payload)
        },
        updateEmptyUnifiedLoginAlerts({ commit }, payload) {
            commit('setEmptyUnifiedLoginAlerts', payload)
        },
        updateCVSSubmitLoader({ commit }, payload) {
            commit('setLoader', payload)
        },
        setAuthErrorUpdate({ commit }, payload) {
            commit('setAuthError', payload)
        },
        setAlertTypeUpdate({ commit }, payload) {
            commit('setAlertType', payload)
        },
        updatePasswordSuccess({ commit }, payload) {
            commit('setPasswordSuccess', payload)
        },
        updateForgotUsername({ state }, payload) {
            state.callbacks[0].input[0].value = payload
        },
        updateVsbdrError({ commit }, payload) {
            commit('setVsbdrAuthError', payload)
        },
        updateCitizenCookie({ state }, payload) {
            return ifgrest.post('citizen/json/realms/root/realms/va/sessions/?_action=logout', payload, state.citizenHeaders).then(function (response) {
                if (response && response.data) {
                    localStorage.setItem("lng", state.vsbdrLocale);
                }
            }).catch((error) => {
                //console.log("citizen", error)
            })
        },
        updateCitizenPassword({ state, commit }, payload) {
            const errMessagePath = state.vsbdrLocale == 'en' ? translations.en : translations.es;
            return ifgrest.post('ciam/resetpasswordservice/public/c/fp/guest/resetPassword/', payload).then(function (response) {
                if (response && response.data && response.data[0] && response.data[0].responseMessage) {
                    const lng = localStorage.getItem('defaultlng') || 'en'
                    router.push({ name: 'PasswordUpdated', query: { ciamLang: lng }});
            } else {
                commit('setVsbdrForgotAlerts',{msg:errMessagePath.message['create_pd_requirements_error_header'], subMsg:errMessagePath.message['create_pd_requirements_error_sub_header']})
            }
            }).catch((error) => {
                //console.log("password", error)
                commit('setVsbdrForgotAlerts',{msg:errMessagePath.message['create_pd_requirements_error_header'], subMsg:errMessagePath.message['create_pd_requirements_error_sub_header']})
            })
        },
        updateCitizenForgotPassword({ state, commit }, payload) {
            const errMessagePath = state.vsbdrLocale == 'en' ? translations.en : translations.es;
            return ifgrest.post('/ciam/resetpasswordservice/public/c/fp/guest/preauth/setresetpassword', payload).then(function (response) {
            if (response && response.data && response.data[0] && response.data[0].responseMessage && response.data[0].statusCode == "200 OK") {
                router.push({ name: 'resetPasswordUpdated'});
            } else if (response && response.data && response.data.response && response.data.response.errorCode == 'RP1010') {
                router.push({ name: 'ForgotPasswordLanding'});
                commit('setVsbdrForgotAlerts',{msg:errMessagePath.message['email_link_expired']})
            } else if (response && response.data && response.data.response && response.data.response.errorCode == 'RP1011') {
                commit('setVsbdrForgotAlerts',{msg:errMessagePath.message['create_pd_requirements_error_header'], subMsg:errMessagePath.message['create_pd_requirements_error_sub_header']})
            } else {
                commit('setVsbdrForgotAlerts',{msg:errMessagePath.message['email_common_error']})
            }
            }).catch((error) => {
                //console.log("password", error)
                commit('setVsbdrForgotAlerts', { msg: errMessagePath.message['email_common_error'] })
            })
        },
        updateForgotPasswordMail({ state, commit }, payload) {
            const errMessagePath = state.vsbdrLocale == 'en' ? translations.en : translations.es;
            commit('setVsbdrAuthError', false);
            return ifgrest.post('ciam/resetpasswordservice/public/c/fp/guest/preauth/initiateResetPw', payload).then(function (response) {
                if (response && response.data && response.data[0] && response.data[0].responseMessage && response.data[0].statusCode == "200 OK") {
                    const maskedEmail = setMaskedEmail(payload.secProfileEmail)
                    commit('setVsbdrMail', payload.secProfileEmail)
                    commit('setVsbdrMaskedMail', maskedEmail)
                    router.push({ name: 'VerifyProfile' });
                } else if (response && response.data && response.data.response && response.data.response.errorCode == "RP1014") {
                    commit('setVsbdrForgotAlerts', { msg: errMessagePath.message['email_not_recognized_heading'], subMsg: errMessagePath.message['email_not_recognized_sub_heading'] })
                    commit('setVsbdrAuthError', true);
                } else if (response && response.data && response.data.response && response.data.response.errorCode == "RP1013") {
                    router.push({ name: 'MaxAttempt' });
                } else {
                    commit('setVsbdrForgotAlerts', { msg: errMessagePath.message['email_common_error'] })
                }
            }).catch((error) => {
                commit('setVsbdrForgotAlerts', { msg: errMessagePath.message['email_common_error'] })
            })
        },
        updateSetTokenExpired({ commit }, payload) {
            commit('setTokenExpired', payload)
        },
        redirectAccountLockedUser({ dispatch, state }, payload) {
            // 0 LOB is for mybiz, 1 for vec, 2 for VBM,VBMS, 3 for CMB, 4 for vps, 5 for VBMC
            dispatch('updateLineOfBusiness', payload)
            if (payload == 2 || payload == 3 || payload == 5) { // (X-Fram-Optionss == 2) for SMB users
                var gotoURLFP = getParameterByName('goto');
                var query = { clientId: 'vbm' }
                if (gotoURLFP) {
                    query.goto = gotoURLFP
                }
                if (state.gotoUrl) {
                    router.push({ name: 'ResetPassword', params: { lockType: 'resetPassword' }, query: { goto: state.gotoUrl } })
                } else {
                    router.push({ name: 'ResetPassword', params: { lockType: 'resetPassword' } })
                }
                
            } else if (payload == 4) { 
                if(state.gotoUrl) {
                    router.push({ name: 'VPSResetPassword', query: {goto: state.gotoUrl}})
                } else {
                    router.push({ name: 'VPSResetPassword'})
                }
            } else {
                if (state.urlOnMount.includes(`/account/business/login/unifiedlogin`) && payload == 0) { // (X-Fram-Optionss == 0) for MyBizz users
                    dispatch('mybizzForget', true)
                }
                if (state.gotoUrl) {
                    router.push({ name: 'ResetPassword', params: { lockType: 'resetPassword' }, query: { goto: state.gotoUrl } })
                } else {
                    router.push({ name: 'ResetPassword', params: { lockType: 'resetPassword' } })
                }

            }
        },
        updateUnlockMyAccountAlerts({ commit }, payload) {
            commit('setUnlockMyAccountAlerts', payload)
        },
        updateResetSecurityProfile({ commit }, payload) {
            commit('setResetSecurityProfile', payload)
        },
        updateClearFormFields({ commit }, payload) {
            commit('setClearFormFields', payload)
        },
        updateDisplayPopupClass({commit}, payload) {
            commit('setDisplayPopupClass', payload)
        },        
        updateLineOfBusiness({ commit }, payload) {
            commit('setLineOfBusiness', payload)
        },
        updateVcgProfileView({ commit }, payload) {
            commit('setVcgProfileView', payload)
        },
        updateVcgMtn({commit}, payload){
            commit('setVcgMtn', payload)
        },
        updateVcgRedirectToCsqaStepup( {commit }, payload) {
            commit('setVcgRedirectToCsqaStepup',payload)
        },
        startPendSdarFlow({commit, dispatch, state}, payload) {
            const sdarUserStatus = payload;
            // first set the sdar user status in profile.js - sdarUserType
            dispatch('profile/updateSdarUserType', sdarUserStatus, {root:true})
            // move the user to otp page and trigger get-transaction then 
            commit('setUserAuthenticated', true)
            commit('setRedirectToStepupPage', true)
            // trigger polling calls (tokenized link) (this will be in stepup page)
            // once it's approved show popup to user continue login/complete RSP (on stepup page)
            // complete rsp page with userid prefilled
            // intercept ifgservices/vbg/iamservices/api/pre-auth/stepup-service/v1/confirm-success/ call and show user popup
        },
        updateVcgSubmitLoader({commit}, payload) {
            commit('setVcgSubmitLoader', payload)
        },
        checkHeaderForredirect({commit, dispatch, state}, payload) {
            // look for logintoken for silent auth
            if(payload.headers[iamvar_vcgMigrationLoginToken] && iamvar_vcgvbgMigration) {
                if(state.lineOfBusiness == 5) {
                    submitVcgVbmcUserMigrationForm(payload.headers[iamvar_vcgMigrationLoginToken])
                } else {
                    submitVcgMigrationForm(payload.headers[iamvar_vcgMigrationLoginToken])
                }
            }
            // look for x-vz-require-cqa
            else if (payload.headers[iamvar_csqaAlwaysOnHeaderName] && iamvar_vcgvbgMigration) {
                dispatch('updateVcgRedirectToCsqaStepup',true)
            }
            // look for x-vcg-sec-profile-reg
            else if (payload.headers[iamvar_vcgSecProfileHeaderName] && iamvar_vcgvbgMigration) {
                dispatch('profile/displayLoginIntercept', {}, { root: true })
            }
            // look forx-vz-cqa-not-set
            else if ((payload.headers[iamvar_vcgRegIncompleteHeaderName] || payload.headers[iamvar_vcgRegIncompleteNoMtnHeaderName]) && iamvar_vcgvbgMigration) {
                dispatch('profile/displayLoginIntercept', {}, { root: true })
            }
            // look for X-VBG-SEC-PROFILE-REG
            else if (payload.headers[iamvar_secProfileHeaderName]) {
                // logger.info('Redirecting the user to Security Profile page for profile completion')
                if (enhanchedResetSecurityProfile && state.resetSecurityProfile) {
                    dispatch('profile/displayLoginIntercept', {}, { root: true })
                } else {
                    commit('setRedirectToSecurityProfilePage', iamvar_serverUrl + iamvar_secProfileUrl + "?goto=" + state.encodedGotoUrl)
                    dispatch('cvsstepup/startPageTimer', {}, { root: true })
                }
            } 
            else {
                if (payload.headers[iamvar_otpAlwaysOnHeaderName]) {
                    dispatch('cvsstepup/updateOTPAlwaysOn', true, { root: true })
                }
                commit('setUserAuthenticated', true)
                // logger.info('The user needs to be challenged. Redirecting the user to CVS')
                commit('setRedirectToStepupPage', true)
            }
        },
        updateVcgThrotteledOn({commit}, payload) {
            commit('setVcgThrotteledOn', payload)
        },
        updateVpsUserIdAlreadyExist({commit}, payload) {
            commit('setVpsUserIdAlreadyExist', payload)
        }
        
    },
    getters: {
        formData: state => state.callbacks,
        pwdAuthOptions: state => state.pwdAuthOptions,
        authError: state => state.authError,
        gotoUrl: state => state.gotoUrl,
        encodedGotoUrl: state => state.encodedGotoUrl,
        submitLoader: state => state.submitLoader,
        iamvar_enableCaptcha: state => {
            return iamvar_enableCaptcha
        },
        iamvar_enableJCaptcha: state => {
            return iamvar_enableJCaptcha
        },
        iamvar_enableLoginCaptcha: state => {
            return iamvar_enableLoginCaptcha
        },
        redirectToSecProfilePage: state => state.redirectToSecProfilePage,
        redirectToStepupPage: state => state.redirectToStepupPage,
        redirectToOptionsPage: state => state.redirectToOptionsPage,
        redirectToPasswordPage: state => state.redirectToPasswordPage,
        urlOnMount: state => {
            return state.urlOnMount
        },
        vbgc: state => state.vbgc,
        loginCaptchaKey: () => {
            return iamvar_loginRecaptchaKey
        },
        getAlertType: state => state.alertType,
        idmRedirectUrl: state => state.idmRedirectUrl,
        popupValue: state => state.popupValue,
        mybizzFlag: state => state.mybizzFlag,
        getUnifiedLoginAlerts: state => state.unifiedloginAlerts,
        getEmptyUnifiedLoginAlerts: state => state.emptyUnifiedloginAlerts,
        getVsbdrForgotAlerts: state => state.vsbdrForgotAlerts,
        getVsbdrLocale: state => state.vsbdrLocale,
        getPasswordSuccess: state => state.passwordSuccess,
        getInterstitialPage: state => state.interstitialPage,
        getUserSelectedAccount: state => state.userSelectedAccount,
        getUnifiedErrorCode: state => state.unifiedErrorCode,
        rememberMeTrue: state => state.rememberMeTrue,
        makeDefaultMethodTrue: state => state.makeDefaultMethodTrue,
        combinenowValue: state => state.combinenowValue,
        initialUsername: state => state.initialUsername,
        saveFingerPrintResponse: state => state.saveFingerPrintResponse,
        getCombinenowAlerts: state => state.combinenowAlerts,
        getCombinenowErrorCode: state => state.combinenowErrorCode,
        userType: state => state.userType,
        mvbContent: state => state.mvbContent,
        uuId: state => state.uuId,
        rToken: state => state.rToken,
        preferredPortal: state => state.preferredPortal,
        comingFromProfilePage: state => state.comingFromProfilePage,
        invalidCount: state => state.invalidCount,
        toBeMergeUserID: state => state.toBeMergeUserID,
        userAgent: state => state.userAgent,
        remembermeUserName: state => state.remembermeUserName,
        pwdUsername: state => state.pwdUsername,
        loginAnotherWayEnable: state => state.loginAnotherWayEnable,
        optionMethod: state => state.optionMethod,
        pwdPassWord: state => state.pwdPassWord,
        vsbdrAuthError: state => state.vsbdrAuthError,
        vsbdrMailId: state => state.vsbdrMailId,
        vsbdrMaskedMailId: state => state.vsbdrMaskedMailId,
        getLockedUserName: state => state.lockedUserName,
        tokenExpired: state => state.tokenExpired,
        pwdInlineError: state => state.pwdInlineError,
        getUnlockMyAccountAlerts: state => state.unlockMyAccountAlerts,
        resetSecurityProfile: state => state.resetSecurityProfile,
        clearFormFields: state => state.clearFormFields,
        displayPopupClass: state => state.displayPopupClass,
        getLineOfBusiness: state => state.lineOfBusiness,
        getVcgProfileView: state => state.vcgProfileView, 
        getVcgMtn: state => state.vcgMtn,
        getVcgRedirectToCsqaStepup: state => state.vcgRedirectToCsqaStepup,
        getVcgSubmitLoader: state => state.vcgSubmitLoader,
        getVcgThrotteledOn: state => state.vcgThrotteledOn,
        getVpsUserIdAlreadyExist: state => state.isVpsUserIdAlreadyExist,
        getVpsRegPwdAlert: state => state.vpsUserRegPwdAlert
    }
}
